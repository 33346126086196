import React, { useState } from "react";
import { T } from "../../traks";

const u = undefined;

const ListItem = ({ title = u, icon = u, badge = u, remove = u, edit = u, url = u }) => {

	const [isShown, setIsShown] = useState(false);

	const Icon = () => {
		if (icon === "venue") {
			return (
				<div className="icon-bg d-flex align-items-center justify-content-center me-3">
					<i className="bi bi-layers-half"></i>
				</div>
			);
		}
		
		if (icon === "user") {
			return (
				<div className="icon-bg d-flex align-items-center justify-content-center me-3">
					<i className="bi bi-person"></i>
				</div>	
			);
		}

		if (icon === "category") {
			return (
				<div className="icon-bg d-flex align-items-center justify-content-center me-3">
					<i className="bi bi-box"></i>
				</div>	
			);
		}

		if (icon !== undefined){
			return (
				<img className='me-3 rounded-pill' alt="customIcon" width={40} height={40} src={icon} />
			)
		}
	}

	const Badge = () => {
		if (badge) {
			return (
				<div className="ms-4 k-badge rounded-pill py-1 px-3 text-sm">{badge}</div>
			);
		}
	}

	const Remove = () => {
		if (remove) {
			return (
				<div className="ps-3 d-flex align-items-center justify-between">
					<div>
						<div className="icon-close d-flex align-items-center justify-content-center">
							<i className="bi bi-x"></i>
						</div>
					</div>
				</div>
			);
	}	}

	const Edit = () => {
		if (edit) {
			return (
				<div className="d-flex align-items-center justify-between">
					<span className="k-btn k-btn-light px-3 py-1 grey">
						<img className='mb-1 me-1' alt="thumb" width={14} height={14} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_edit.svg"} />
						<T>Rediger</T>
					</span>
				</div>
			);
		}
	}

	return (
		<div className="border-1 border-top text-md list-item">
			<a href={url}>
				<div className="py-3 px-4 d-flex align-items-center justify-content-between" 
					onMouseEnter={() => setIsShown(true)}
					onMouseLeave={() => setIsShown(false)}>
					<div className="d-flex align-items-center">
						<Icon />
						<div className="d-flex align-items-center">
							<div>
								<span className="primarycolor px-1">
									{title}
								</span> 
							</div>
							<Badge />
						</div>
					</div>
					<div className="d-flex">
						{isShown &&
						(
							<div className="d-flex">
								<Edit />
								<Remove />
							</div>
						)}
					</div>
				</div>
			</a>
		</div>
	);
};

export default ListItem;