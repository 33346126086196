import React from "react";
import Kbutton from "./KButton";
import { T } from "../traks";

const EventList = ({ eventList }) => {

	const sortedData = [...eventList].sort((a, b) => a.timestamp - b.timestamp);

    return (
        <div>
			{sortedData.map((item, index) => (
				<div key={index}>
					<a className="link_nostyle" target="_blank" rel="noopener noreferrer" href={item.urlSlug}>
						<div className="p-3 p-lg-4 mb-4 rounded d-flex align-items-center shadow-soft fade-up" style={{ backgroundColor: "#fff" }}>
							{item.imageUrl &&
								<div className="d-none d-lg-block" style={{ width: 300, height: 180, overflow: "hidden", flex: 1 }}>
									<img alt="newsItemImg" className="rounded" src={item.imageUrl} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
								</div>
							}
							<div className="ms-lg-3 px-lg-4" style={{ flex: 2 }}>
								<div className="primarycolor">{item.publicationDate}</div>
								<div className="primarycolor mt-2 mb-2">
									<h4>{item.title}</h4>
								</div>
								<div className="grey">{item.description}</div>
								<div className="grey mt-2">{item.dateTime}</div>
								<div className="grey my-4">
									<div className="d-flex align-items-center">
										<div>	
											<img src="https://static.optimeet.dk/gfx/ico_maplocation-1.svg" width={"12px"} height={"18px"} alt="icon-map" style={{marginTop: -4}}/>
										</div>
										<div className="ms-2">
											<span className="text-bold">{item.location}</span>
										</div>
									</div>
								</div>
								<div className="mt-3">
									<Kbutton md sec><T>Se arrangement</T></Kbutton>
								</div>
							</div>
						</div>
					</a>
				</div>
			))}
        </div>
    );
};

export default EventList;