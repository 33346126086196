import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import MagazineGrid from "c/MagazineGrid"
import Kbutton from "c/KButton";
import { LL } from "Routes";
import { APIGet } from "API";
import { AlertModal } from "c/Reusables";
import { T } from "../traks";
import { Loading } from "c/Loading";
import FixedContactButton from "c/FixedContactButton";
import { Banners } from "c/Banners";

const EventMagazineInner = ({ magazines, banners }) => {

	return (
		<Container fluid className="bg-ulight">
			<Container className="pt-5">
				<Row className="pb-5 text-center text-md-start">
					<Col col={12} lg={9} className="p-0">
						<Banners data={banners} billBoard/>
						<div className="mt-5">
							<h1 className="pb-3 text-center text-md-start"><T>Optimeet Magasinet</T></h1>
							<p><T>Ønsker du at modtage vores gratis magasin 4 gange om året, kan du bestille det her:</T></p>
							<div className="mb-4 d-flex justify-content-center justify-content-md-start">
								<a href={LL("/bestil/")}>
									<Kbutton prim md><T>Bestil magasinet her</T></Kbutton>
								</a>
							</div>
						</div>
						<MagazineGrid data={magazines} />
					</Col>
					<Col>
						<Banners sticky data={banners} className="d-none d-lg-block" skyScraper />
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

const EventMagazine = () => {
	
	const [banners, setBanners] = useState();
	const [magazines, setMagazines] = useState(undefined)
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })
	const [showLoading, setShowLoading] = useState(false)

	useEffect(() => {
		setShowLoading(true)
		APIGet("magazines").then((res) => {
			setShowLoading(false);
			setMagazines(res.data.magazines.sort((a, b) => b.createdTs - a.createdTs));
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
			setShowLoading(false)
		});
		APIGet("banners").then((res) => {
			setBanners(res.data.banners);
		}).catch(err => {
			console.log(err)
		})
	}, [])

	if (!magazines) {
		return (<>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</>)
	}

	return (
		<div>
			<Header />
			{magazines !== undefined && magazines.length && <EventMagazineInner banners={banners} magazines={magazines}/>}
			<Footer />
			<FixedContactButton />
		</div>
	);
}

export default EventMagazine;
