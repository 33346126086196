import React, { useContext } from "react";
import { ListCertificates } from "c/admin/DashboardComponents";
import CreateButton from "c/CreateButton";
import Sidebar from "c/admin/Sidebar";
import { MeContext } from './../MeContext';
import NoAccess from "c/admin/NoAccess";
import { T } from "../traks";

const Certificates = () => {

	const { me } = useContext(MeContext);

	return (
		<div>
			{me.user.roles.includes("admin") ?
			<div className="dashboard">
				<CreateButton />
				<Sidebar index={5} />
				<div className="dashboard-wrapper" style={{ maxWidth: 1140 }}>
					<h5 className="mb-3"><T>Miljømærker</T></h5>
					<ListCertificates />
				</div>
			</div>
			: <NoAccess />}
		</div>
	);
}

export default Certificates;