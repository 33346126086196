import React, { useState, useEffect, useContext } from "react";
import DashboardContainer from "../c/admin/DashboardContainer";
import DashboardContainerHeader from "../c/admin/DashboardContainerHeader";
import ListItemLarge from "c/admin/ListItemLarge";
import "../css/dashboard.css";
import { T, TraksConsumer } from "../traks";
import { APIGet } from "API";
import { MeContext } from './../MeContext';
import NoAccess from "c/admin/NoAccess";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import { Loading } from "c/Loading";
import { AlertModal } from "c/Reusables";

const MyEvents = () => {
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin")
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		APIGet("events", {}).then(res => {
			setData(res.data.events);
			setFilteredData(res.data.events);
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		});
	}, [])

	const searchPlaceholder = <T>Search</T>;

	if (!isAdmin) {
		return <NoAccess />
	}

	if (!data.length) {
		return (
			<DashboardWrapper>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	return (
		<DashboardWrapper index={13}>
			<h5 className="mb-3"><T>Arrangementer</T></h5>
			<DashboardContainer>
				<TraksConsumer>
					<DashboardContainerHeader
						valueToSearch={"title"}
						data={data}
						searchPlaceholder={searchPlaceholder}
						setData={setFilteredData}
						search
					>
						<T>Alle arrangementer</T>
					</DashboardContainerHeader>
				</TraksConsumer>
				<span>
					{filteredData.map((event, i) => (
						<ListItemLarge
							key={"events" + i}
							title={event.title}
							image={event.imageUrl}
							visible={true}
							id={event.id}
							shortDescription={event.shortDescription}
							goTo={("/editevent/")}
						/>
					))}
				</span>
			</DashboardContainer>
		</DashboardWrapper>
	);
};

export default MyEvents;
