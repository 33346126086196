import React, { useState, useContext, useEffect } from "react";
import { APIGet, APIPost, APIDelete } from "API";
import { MeContext } from '../../MeContext';
//import NoAccess from "c/admin/NoAccess";
import { AlertModal, RenderIf } from "c/Reusables";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import { Loading } from "c/Loading";
import { CreatePartner } from "./CreatePartner";
import { useNavigate, useParams } from "react-router-dom";
import { EditPartner } from "./EditPartner";
import { LL } from "Routes";

export const PartnerPage = () => {
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin")
	const { partnerId } = useParams();
	const navigate = useNavigate();

	const [userId, setUserId] = useState(partnerId);
	const [showLoading, setShowLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })
	const [user, setUser] = useState(undefined);

	const createUser = (newUser) => {
		setShowLoading(true)
		APIPost("users", newUser).then(res => {
			setUser(res.data);
			window.history.replaceState(null, '', res.data.user.id);
			setUser(res.data.user);
			setUserId(res.data.user.id)

			setShowLoading(false)
			setAlertType({ type: "success", text: "User created" })
			setShowAlert(true);
		}).catch((error) => {
			setShowLoading(false)
			setAlertType({ type: "error", text: error.response.data })
			setShowAlert(true);
		})
	}

	const saveUser = (userSettings = null) => {
		setShowLoading(true)
		const body = userSettings ? userSettings : user
		APIPost("users/" + userId, body).then(res => {
			setAlertType({ type: "success", text: "User details saved" })
			setShowAlert(true);
			getUser();
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	}

	const getUser = () => {
		setShowLoading(true)
		APIGet("users/" + userId).then(res => {
			setShowLoading(false)
			setUser(res.data.user);
		}).catch(err => {
			setShowLoading(false)
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	}

	const deleteUser = () => {
		const resource = "users/" + userId;
		setShowLoading(true)
		APIDelete(resource, { action: "delete" }).then(res => {
			setShowLoading(false)
			navigate(LL("/partners"));
		}).catch(err => {
			setShowLoading(false)
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	}

	const resetPassword = () => {
		setShowLoading(true)
		const postObj = {
			'username': user.email,
			'link': window.location.origin + LL(""),
		};
		APIPost('forgot-password', postObj).then((response) => {
			setShowLoading(false)
			setAlertType({ type: "success", text: "Password reset email sent set" })
			setShowAlert(true);
		}).catch(error => {
			setAlertType({ type: "error", text: error.response.data })
			setShowAlert(true);
			setShowLoading(false)
		})
	}
		useEffect(() => {
		if (userId && !user) {
			getUser()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId])

	const index = me.user.id === partnerId ? 17 : 11;

/* 	if (!isAdmin) {
		return (
			<DashboardWrapper index={index}>
				<NoAccess />
			</DashboardWrapper>
		)
	} */

	if (userId && !user) {
		return (
			<DashboardWrapper index={index}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	return (
		<DashboardWrapper index={index}>
			<RenderIf condition={!user}>
				<CreatePartner
					isAdmin={isAdmin}
					createUser={createUser}
				/>
			</RenderIf>
			<RenderIf condition={user}>
				<EditPartner
					isAdmin={isAdmin}
					user={user}
					setUser={setUser}
					saveUser={saveUser}
					deleteUser={deleteUser}
					resetPassword={resetPassword}
				/>
			</RenderIf>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</DashboardWrapper >
	);
}