import React, { useEffect, useState } from "react";
import { T } from '../traks';
import SliderLandingCategory from "./SliderLandingCategory";
import { APIGet } from "../API";
import { AlertModal } from "./Reusables";

const SliderSelector = ({ category = false }) => {

 	const [landingPages, setLandingPages ] = useState(undefined);

	useEffect(() => {

		const getUrl = category ? "landingpages?pageType=categories" : "landingpages?pageType=regions";

		const getAllLocations = () => {
			APIGet(getUrl).then(res => {
				setLandingPages(res.data.landing_pages);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			});
		};
		getAllLocations();
        // eslint-disable-next-line
    }, [])
	

	const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });

	return (
		<>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<div className="container-fluid mt-5 pt-md-1 px-0">
				<div className="container-sm text-start text-md-center">
					<h2 className="mt-5">{category? <T>Populære kategorier med møde og konferencesteder</T> : <T>Populære lokationer til dine møder og konferencer</T>}</h2>
					<span className="centerComponent">
						<p style={{maxWidth: 610}} className="grey text-xl text-weight-reg mt-2 lead"><T>Find det perfekte møde- og konferencested</T></p>
					</span>
				</div>
				{landingPages &&
					<div>
						<div className="d-flex pt-3 pb-3 px-0 ps-md-0 mx-auto category-slider scrolling-wrapper-flexbox scroll-smooth" style={{ maxWidth: 1620 }}>
							{landingPages.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) => (
								<SliderLandingCategory
									location={!category}
									key={index}
									title={item.title}
									shortDescription={item.pageContent.shortDescription}
									img={item.pageContent.imagesUrls[0]}
									urlSlug={item.urlSlug}
								/>
							))}
						</div>
					</div>
				}
			</div>
		</>
	);
}

export default SliderSelector;