import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { MeContext } from '../../MeContext';
import { T } from "../../traks";
import { APIGet } from 'API.js';
import { LL } from 'Routes';

const Sidebar = (props) => {

	const { me, setMe } = useContext(MeContext);
	const navigate = useNavigate();
	const logout = () => {
 		APIGet("logout")
			.then((reply) => {
				localStorage.removeItem('optimeet_ipse_session_id');
				setMe(null);
				navigate("/en");
			})
			.catch((error) => {
				console.log(error);
			}
		)
	};

	return (
		<div>
			{me !== undefined && me.user &&
				<Container fluid className="px-0 d-flex">
					<div className="sidebar text-white py-4">
						<div className="w-100 d-flex justify-content-center pb-3">
							<a href={LL("/")} rel="noreferrer">
								<img alt="logo" width={157} height={40} src={"https://static.optimeet.dk/gfx/klogo.svg"} />
							</a>
						</div>
							<ul>
								<li className="sidebar-label mb-1 px-4 mt-4"><T>Oversigt</T></li>
								<li className={`sidebar-item d-flex align-items-center position-relative px-4 ${props.index === 1 ? 'sidebar-item-selected' : ''}`}>
									<a href={LL("/dashboard/")}>
										<i className="bi bi-gear pe-2"></i>
										<T>Dashboard</T>
									</a>
								</li>
							</ul>
							<ul>
								<li className="sidebar-label mb-1 px-4 mt-4"><T>Mødesteder</T></li>
								<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 2 ? 'sidebar-item-selected' : ''}`}>
									<a href={LL("/myvenues/")}>
										<i className="bi bi-layers-half pe-2"></i>
										<T>Mødesteder</T>
									</a>
								</li>
								{me.user.roles.includes("admin") &&
									<>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 3 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/categories/")}>
												<i className="bi-box pe-2"></i>
												<T>Venue kategorier</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 4 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/facilities/")}>
												<i className="bi-list-check pe-2"></i>
												<T>Faciliteter</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 5 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/certificates/")}>
												<i className="bi-patch-check pe-2"></i>
												<T>Miljømærker</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 6 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/regions/")}>
												<i className="bi-map pe-2"></i>
												<T>Regioner</T>
											</a>
										</li>
									</>
								}
								<>
									<li className="sidebar-label mb-1 px-4 mt-4"><T>Suppliers</T></li>
									<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 7 ? 'sidebar-item-selected' : ''}`}>
										<a href={LL("/mysuppliers/")}>
											<i className="bi bi-truck pe-2"></i>
											<T>Suppliers</T>
										</a>
									</li>
								</>
								{me.user.roles.includes("admin") &&
									<>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 8 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/suppliercategories/")}>
												<i className="bi-box pe-2"></i>
												<T>Supplier kategorier</T>
											</a>
										</li>
									</>
								}
								{me.user.roles.includes("admin") &&
								<>
									<li className="sidebar-label mb-1 px-4 mt-4"><T>Landingssider</T></li>
									<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 9 ? 'sidebar-item-selected' : ''}`}>
										<a href={LL("/editlandingcategories/")}>
											<i className="bi bi-collection pe-2"></i>
											<T>Kategorier</T>
										</a>
									</li>
									<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 10 ? 'sidebar-item-selected' : ''}`}>
										<a href={LL("/editlandingregions/")}>
											<i className="bi-map pe-2"></i>
											<T>Regioner</T>
										</a>
									</li>
								</>
								}
								{me.user.roles.includes("admin") &&
									<div>
										<li className="sidebar-label mb-1 px-4 mt-4"><T>Admin</T></li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 11 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/partners/")}>
												<i className="bi-person-square pe-2"></i>
												<T>Users</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 12 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/mynews/")}>
												<i className="bi-card-list pe-2"></i>
												<T>Indlæg</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 13 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/myevents/")}>
												<i className="bi-calendar4-event pe-2"></i>
												<T>Arrangementer</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 14 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/mymagazines/")}>
												<i className="bi-book pe-2"></i>
												<T>Magasiner</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 15 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/mybanners/")}>
												<i className="bi-layout-text-window pe-2"></i>
												<T>Banners</T>
											</a>
										</li>
										<li className={`sidebar-item d-flex align-items-center px-4 ${props.index === 16 ? 'sidebar-item-selected' : ''}`}>
											<a href={LL("/hotelofmonthadmin/")}>
												<i className="bi-house-up pe-2"></i>
												<T>Månedens hotel</T>
											</a>
										</li>
									</div>
								}
							</ul>
							<ul>
								<li className="sidebar-label mb-1 px-4 mt-4"><T>Konto</T></li>
								<li className={`sidebar-item d-flex align-items-center position-relative px-4 ${props.index === 17 ? 'sidebar-item-selected' : ''}`}>
									<a href={LL("/partner/" + me.user.id)}>
										<i className="bi bi-person pe-2"></i>
										<T>Min Profil</T>
									</a>
								</li>
								<li onClick={logout} className="sidebar-item d-flex align-items-center cursor_pointer px-4">
									<i className="bi bi-box-arrow-right pe-2"></i>
									<T>Log ud</T>
								</li>
							</ul>
					</div>
				</Container>
			}
		</div>
	);
}

export default Sidebar;