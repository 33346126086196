import React, { useState, useEffect, useContext } from "react";
import { APIGet } from "API";
import { Container } from "reactstrap";
import StatCard from "./InfoCard";
import { T } from "../../traks";
import axios from "axios";
import { MeContext } from "MeContext";
import { Loading } from "c/Loading";
import { AlertModal, RenderIf } from "c/Reusables";

const StatBox = () => {
	const [venueLength, setVenueLength] = useState("-");
	const [supplierLength, setSupplierLength] = useState("-");
	const [visitorLength, /* setVisitorLength */] = useState("n/a");
	const [partnerLength, setPartnerLength] = useState("-");
	const { me } = useContext(MeContext)
	const isAdmin = me.user.roles.includes("admin")
	const [showLoading, setShowLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })

	useEffect(() => {
		const filterUrl = window.location.origin.includes("https://optimeet.dk") ? "https://static.optimeet.dk/stats/stats.json" : "https://static.optimeet.dk/stats/test-stats.json"
		if (isAdmin) {
			axios.get(filterUrl).then(res => {
				setVenueLength(res.data.number_of_venues);
				setSupplierLength(res.data.number_of_suppliers);
				APIGet("users").then(res => {
					setPartnerLength(res.data.users.length);
				}).catch(err => {
					console.log(err);
				})
			}).catch(err => console.log(err))
		} else {
			const userId = me.user.id
			APIGet("users/" + userId + "/venues").then(res => {
				setShowLoading(false)
				setVenueLength(res.data.length);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data })
				setShowAlert(true);
				setShowLoading(false)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Container fluid className="p-0 d-flex">
			<StatCard theme={1} count={venueLength} icon={1}><T>Mødesteder</T></StatCard>
			<StatCard theme={1} count={supplierLength} icon={1}><T>Suppliers</T></StatCard>
			<StatCard theme={2} count={visitorLength} icon={2}><T>Visninger</T></StatCard>
			<RenderIf condition={isAdmin}>
				<StatCard theme={3} count={partnerLength} icon={3}><T>Partnere</T></StatCard>
			</RenderIf>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</Container>
	);
}

export default StatBox;
