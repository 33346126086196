import React from "react"
import "./styles.css"
import ReactDOM from "react-dom"
import { RenderIf } from "c/Reusables"

export const Loading = ({ showLoading, size = "100px", ...props}) => {

	const SpinnerContainer = ({children, ...props}) => {
		if(props.inFront){
			const portalContainer = document.getElementById("root");
			return (
				ReactDOM.createPortal(
					<>
						<div className={`spinner-portal${props.overlay ? " overlay": " non_overlay"}`} >
							{children}
						</div>
					</>, portalContainer)
			)
		}else{
			return(
				<div className={props.className}>
					{children}
				</div>
			)
		}
	}

	return (
		<RenderIf condition={showLoading} >
			<SpinnerContainer {...props}>
				<img alt="loading" src="https://static.sibilus.eu/gfx/loading-icon-0.svg" style={{width: size, height: size, filter: "invert(9%) sepia(12%) saturate(5630%) hue-rotate(246deg) contrast(20%)"}} className={`spinner${props.spinnerClassName ? " " +props.spinnerClassName : ""}`} />
			</SpinnerContainer>
		</RenderIf>
	);
}
