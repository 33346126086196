
import React, { useState, useContext } from "react";
import { MeContext } from '../../MeContext';
import { PartnerInformation, DashboardWrapper, SetPassword } from "c/admin/DashboardComponents";
import NoAccess from "c/admin/NoAccess";
import { T } from "../../traks";
import { RenderIf } from "c/Reusables";
import Kbutton from "c/KButton";

export const CreatePartner = ({ createUser, isAdmin }) => {
	const { me } = useContext(MeContext);
	const [user, setUser] = useState({
		screenName: "",
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		password: "",
		roles: ["venueEditor", "companyAdmin"], //XX later to be defined
	});

	const updateUser = (e) => {
		setUser({ ...user, ...e })
	}

	if (!me) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	const isCreateBtnDisabled = user["screenName"] && user["firstName"] && user["lastName"] && user["email"] && user["password"]

	return (<>
		<h5 className="mb-3"><T>Partner profil</T></h5>
		<div>
			<div className="d-flex">
				<PartnerInformation
					isAdmin={isAdmin}
					createUserState={true}
					user={user}
					setUser={updateUser}
				/>
				<div className="d-flex flex-column">
					<SetPassword
						createUserState={true}
						data={user}
						updateUser={updateUser}
					/>
				</div>
			</div>
			<div className="mt-3 pe-2 w-100">
				<div>
					<div>
						<RenderIf condition={isCreateBtnDisabled}>
							<Kbutton
								onClick={() => createUser(user)}
								custom="px-5 py-2"
								prim md
							>
								<T>Create user</T>
							</Kbutton>
						</RenderIf>
						<RenderIf condition={!isCreateBtnDisabled}>
							<Kbutton md>
								<T>Fill all values to create a user</T>
							</Kbutton>
						</RenderIf>
					</div>
				</div>
			</div>
		</div>
	</>);
}
