import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import { MeContext } from "MeContext";
import React, { useContext, useEffect, useState } from "react";
import NoAccess from "c/admin/NoAccess";
import { APIGet, APIPost, APIDelete } from "API";
import { EditGroup } from "c/admin/DashboardComponents";
import { AlertModal } from "c/Reusables";
import { T } from "../traks";
import { Loading } from "c/Loading";
import { LL } from "Routes";

const EditCertificate = ({ urlParams }) => {
	const navigate = useNavigate();
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin");
	urlParams = useParams();

	const certId = urlParams.certId;
	const [cert, setCert] = useState(undefined);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });
	const [showLoading, setShowLoading] = useState(false);

	const uploadImage = (files) => {
		setShowLoading(true);
		const filesInfo = files.map(({ file, ...f }) => f);
		APIPost("news/" + certId + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const file = files[0];
			const preSignedUrl = res.data.upload_urls[0];
			const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
			return axios.put(preSignedUrl, file.file, { headers }).then(res => {
				const newFileName = preSignedUrl.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk");
				saveCertificate({ imageUrl: newFileName });
				setCert({ ...cert, imageUrl: newFileName });
				setShowLoading(false);
				return newFileName;
			}).catch(err => console.log(err));
		}).catch(err => console.log(err));
	};

	useEffect(() => {
		setShowLoading(true);
		if (urlParams.certId) {
			APIGet("certificates/" + certId).then(res => {
				setShowLoading(false);
				if (res.data) {
					if (!res.data)
						navigate(LL("/certificates"));
				}
				setCert(res.data.certificate);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		} else {
			APIPost("certificates", { "name": "new certificate", "key": "new-certificate" }).then(res => {
				setCert(res.data.new_certificate);
				window.history.replaceState(null, '', res.data.new_certificate.id);
				setShowLoading(false);
				window.location.reload(false);
			}).catch((err) => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveCertificate = (newCertificate = null) => {
		setShowLoading(true);
		let body = !newCertificate ? cert : newCertificate;
		APIPost("certificates/" + certId, body).then(res => {
			setShowLoading(false);
			setAlertType({ type: "success", text: "Certificate saved" });
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	const deleteCertificate = () => {
		setShowLoading(true);
		const resource = "certificates/" + certId;
		APIDelete(resource).then(res => {
			setShowLoading(false);
			navigate(LL("/certificates/"));
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	if (!isAdmin) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!cert) {
		return (
			<DashboardWrapper index={5}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	return (
		<DashboardWrapper index={5}>
			<h5 className="mb-3"><T>Rediger magasin</T></h5>
			<div>
				<Notification><T>Du redigerer</T>:&nbsp;{cert["title"]}</Notification>
				<EditGroup
					uploadImage={uploadImage}
					values={cert}
					setValues={setCert}
					save={saveCertificate}
					deleteItem={deleteCertificate}
					classname="w-75"
				/>
			</div>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</DashboardWrapper>
	);
}

export default EditCertificate;