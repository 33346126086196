import React, { useState } from "react";

const Accordion = ({ children }) => {

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div
            style={{ backgroundColor: "#fff", border: "1px solid #eaeaea", borderRadius: 10 }}
            className={`${open ? "open" : ""}`}
        >
            <div className={`p-4 ${open ? "open" : ""}`}
                onClick={handleClick}>
                <div className="d-flex justify-content-between">
                    <div>
                        <div>Advanced</div>
                    </div>
                    <div>
                        <img
                            src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_chevron_down-0.svg"
                            alt="icon-chevron"
                            className={`chevron-icon ${open ? "flip" : ""}`}
                        />
                    </div>
                </div>
            </div>
            <div className={`accordion-content ${open ? "showAccordion" : ""}`}>
                {children}
            </div>
        </div>
    );
};

export default Accordion;