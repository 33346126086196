import React, { useContext, useEffect, useState } from "react";
import { APIPost } from "API";
import { MeContext } from '../MeContext';
import NoAccess from "c/admin/NoAccess";
import Kbutton from "c/KButton";
import { AlertModal, RenderIf } from "c/Reusables";
import { Loading } from "c/Loading";
import { T } from "../traks";
import ChoicesSelect from "c/admin/ChoicesSelect";
import { DashboardWrapper } from "../c/admin/DashboardComponents";

const HotelOfMonthAdmin = () => {

	const { me } = useContext(MeContext);
	const [venues, setVenues] = useState(false);
	const [existingHotelsOfMonth, setExistingHotelsOfMonth] = useState([]);
	const [newSelectedHotels, setNewSelectedHotels] = useState([]);

	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	useEffect(() => {
		if (me.user.roles.includes("admin")) {
			const venueOfMonth = {query: {venueOfTheMonth: true}};
			APIPost("search", venueOfMonth).then(res => {
				setExistingHotelsOfMonth(res.data.result);
			}).catch(err => console.log(err));
			const allVenues = { query: { show_hidden: false }, };
			APIPost("search", allVenues).then(res => {
				setShowLoading(false);
				setVenues(res.data.result);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data })
				setShowAlert(true);
				setShowLoading(false)
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const saveHotelsOfMonth = () => {
		const parsedSelected = newSelectedHotels.map((hotel) => hotel.id);
		const venuesOfTheMonth = {
			"venuesOfTheMonth": parsedSelected
		};
		setShowLoading(true);
		APIPost("venues/ofthemonth", venuesOfTheMonth).then(res => {
			setShowLoading(false);
			setAlertType({ type: "success", text: "Hotels of the month saved" });
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	};

	if (!venues) {
		return (
			<DashboardWrapper index={16}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	};

	if (!me.user.roles.includes("admin")) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	};

	return (
		<DashboardWrapper index={16}>
			<div>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
				<div>
					<h5 className="mb-3"><T>Månedens hotel</T></h5>
					<RenderIf condition={venues.length}>
						<div style={{ backgroundColor: 'white', borderRadius: 10 }}>
							<ChoicesSelect venue sendSelected={(e) => setNewSelectedHotels( e )} data={venues} venueData={existingHotelsOfMonth} subtitle={<T>Vælg venues</T>} searchPlaceholder={("Søg på venues")} showSearchBar />
						</div>
					</RenderIf>
					<Kbutton onClick={() => saveHotelsOfMonth()} custom="px-5 py-2 mt-3" prim md><T>Gem</T></Kbutton>
				</div>
				<Loading
					showLoading={showLoading}
					inFront
				/>
			</div>
		</DashboardWrapper>
	);
};

export default HotelOfMonthAdmin;