import React from "react";
import { TraksConsumer } from "../traks";
import Header from "../c/Header";
import ListingComponent from "../c/ListingComponent";
import FixedContactButton from "c/FixedContactButton";

const Home = () => {

	return (
		<div>
			<Header />
			<TraksConsumer>
				<ListingComponent />
			</TraksConsumer>
			<FixedContactButton />
		</div>
	);
}

export default Home;