import React from "react";
import { Container } from "reactstrap";
import { T } from "../traks";
import Kbutton from './KButton';

const KursuslexAd = () => {

	const openLink = () => {
		const newWindow = window.open("https://www.kursuslex.dk/", '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	return (
		<Container className="py-0 py-xl-2 pt-5 pt-xl-0 mt-5 mb-4">
			<div className="pt-0 pt-xl-4 pb-0 pb-xl-4">
				<Container className="p-0 bg_white">
					<div className="py-0 py-xl-4 d-flex flex-column flex-lg-row align-items-center">
						<div className="pt-0 me-0 me-lg-5 me-xl-5 pe-xxl-5">
							<div>
								<div>
									<h2><T>Find gratis inspiration til dig, som arbejder med møder og events</T></h2>
									<div className="d-flex mt-2">
										<p className="text-md text-weight-reg grey text-lineheight-24 mt-3"><T>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere.
										Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</T></p>
									</div>
								</div>
							</div>
						</div>
						<img className="px-2 px-lg-0 inspire-img" width="100%" height="100%" style={{maxWidth: 600}} alt="header" src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/inspire_710x631px.webp"
							srcSet="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/inspire_1420x1262px.webp 2x"/>
					</div>
					<div className="pt-5 pb-5 mb-3 d-none">
						<Kbutton onClick={openLink} custom=" py-3" prim md><T>Besøg hjemmeside</T></Kbutton>
					</div>
				</Container>
			</div>
		</Container>
	);
}

export default KursuslexAd;