import React from "react";
import Kbutton from "./KButton";
import { Pagination, NewsTStoReadable } from "c/Reusables";
import { T } from "../traks";
import { LL } from "Routes";


const NewsList = ({ newsList, setPage, page, totalPages }) => {

	return (
		<div>
			{newsList.map((item, index) =>
				<div key={index}>
					<a
						className="link_nostyle"
						href={LL("/nyhedsmagasin/" + item.urlSlug)}
					>
						<div className="p-3 p-lg-4 mb-4 rounded d-flex align-items-center shadow-soft fade-up" style={{ backgroundColor: "#fff" }}>
							<div className="d-none d-lg-block" style={{ width: 300, height: 180, overflow: "hidden", flex: 1 }}>
								<img alt="newsItemImg" className="rounded" src={item.imageUrl} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
							</div>
							<div className="ms-lg-3 px-lg-4" style={{ flex: 2 }}>
								<div className="d-xs-block d-lg-none" style={{ width: 300, height: 180, overflow: "hidden", flex: 1 }}>
									<img alt="newsItemImg" className="rounded" src={item.imageUrl} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
								</div>
								<div className="primarycolor"><NewsTStoReadable createdTs={item.createdTs}/> </div>
								<div className="primarycolor mt-2 mb-2	"><h4>{item.title}</h4></div>
								<div className="grey" style={{maxHeight: 62, overflow: "hidden"}}>
									<span dangerouslySetInnerHTML={{ __html: item.shortDescription }} />
								</div>
								<div className="mt-3">
									<Kbutton md sec><T>Læs mere</T></Kbutton>
								</div>
							</div>
						</div>
					</a>
				</div>
			)}
			<Pagination setPage={setPage} page={page} totalPages={totalPages} />
		</div>
	)
}

export default NewsList;
