import React, { useEffect, useState } from "react";
import { APIPost } from "API";
import { Col, Row, NavLink } from "reactstrap";
import { DotLoader } from "./Reusables";
import Kbutton from './KButton';
import { VenueCard } from './Cards';
import { T } from '../traks';


const MeetingLocations = (props) => {

    const [data, setData] = useState('');

    useEffect(() => {
		const body = {query: {limit: 8, page: 0}};
		if (!props.regionLocCode) {
			APIPost("search", body).then(res => {
				setData(res.data.result);
			}).catch(err => console.log(err));
		}
		// eslint-disable-next-line
	}, [props.regionLocCode]);

	return (
		<div className="mx-auto px-0 px-md-4" style={{maxWidth: 1500}}>
			<div className="container-fluid backgroundBoxBlue pt-3 mt-5 mx-auto pb-3">
				{data && data.length ?
					<div className="container-fluid">
						{props.stats && <h2 className="text-center mobile-text mt-5"><T>Mere end</T>&nbsp;260+&nbsp;<T>mødesteder</T></h2>}
						{!props.regionName && <p className="text-center text-white text-xl text-weight-reg grey mt-2 mb-4 d-none d-md-block font-primary"><T>Gå på opdagelse i vores møde- og konferencesteder</T></p>}
						{props.regionName && <p className="text-center text-white text-xl fw-bold grey mt-2 mb-2 d-none d-md-block font-primary"><T>More locations in</T> {props.regionName}</p>}
						<div style={{maxWidth: 1290, margin: "0 auto"}} className="d-none d-md-block py-3 py-md-4 locations_container">
							<LocationCardsComponent data={data.slice(0, 8)}/>
						</div>
						<NavLink href={"/konferencesteder/"} className='text-center mobile-text py-3 pb-5'>
							<Kbutton lg white md custom="bg-transparent d-none d-md-block mx-auto mt-3 hoverfx"><T>Alle mødesteder</T></Kbutton>
							<Kbutton lg md custom="bg-transparent d-block d-md-none mx-auto hoverfx"><T>Alle mødesteder</T></Kbutton>
						</NavLink>
					</div>
				:
					<div className='text-center py-5 my-5'><DotLoader/></div>
				}
			</div>
		</div>
	);
}

const LocationCardsComponent = (props) => {
	return (
        <div>
            <div className="container-fluid">
                <Row>
                    {props.data.map((v, i) => (
                        <Col key={i} xs={12} sm={12} md={6} lg={4} xl={3}>
                            <div className="d-flex justify-content-center py-2">
                                <>
                                    <VenueCard
                                        data={v} key={"location" + i}
                                    />
                                </>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
	);
}

export default MeetingLocations;