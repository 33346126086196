import React, { useState } from "react";
import { Container, Input } from "reactstrap";
import InquiryModal from "./modals/InquiryModal";
import Kbutton from "./KButton";
import { T, TraksConsumer } from "../traks";

const LocationSelector = (props) => {

	const [date, setDate] = useState('');
	const [location, setLocation] = useState('');
	const [type, setType] = useState('');
	const [participants, setParticipants] = useState('');

	const filters = {
		"location": location,
		"startDate": date,
		"type": type,
		"participants": participants,
	};

	const modalTitle = type ? "Uforpligtende forespørgsel på " + type : null;

	return (
		<Container fluid className="px-3 px-md-5 py-4 border bg-white position-relative d-flex flex-column flex-md-row location-selector" style={{ maxWidth: 1070 }}>
			<div className="w-100 me-4 location-selector-item mt-3 mt-md-0 border-grey hoverfxlight">
				<div className="text-bold" style={{fontSize: 19}}><T>Hvad?</T></div>
				<Input
					placeholder={props.render_static(<T>Hvilken type arrangement?</T>)}
					onChange={(e) => setType(e.target.value)}
					className="text-weight-reg"
					name="location"
					id="location"
					form="selector"
					value={type}
				/>
			</div>
			<div className="w-100 me-4 location-selector-item border-grey hoverfxlight">
				<div className="text-bold" style={{fontSize: 19}}><T>Hvor?</T></div>
				<Input
					placeholder={props.render_static(<T>Skriv by eller område</T>)}
					onChange={(e) => setLocation(e.target.value)}
					className="text-weight-reg"
					name="location"
					id="location"
					form="selector"
					value={location}
				/>
			</div>
			<div className="w-100 me-lg-4 location-selector-item mt-3 mt-md-0 border-grey hoverfxlight">
				<div className="text-bold" style={{fontSize: 19}}><T>Hvornår?</T></div>
				<Input
					placeholder={props.render_static(<T>Skriv dato for event</T>)}
					onChange={(e) => setDate(e.target.value)}
					className="text-weight-reg"
					name="date"
					id="date"
					form="selector"
					value={date}
				/>
			</div>
			<div className="w-100 mt-3 me-5 location-selector-item mt-md-0 hoverfxlight">
				<div className="text-bold" style={{fontSize: 19}}><T>Antal deltagere?</T></div>
				<Input
					placeholder={props.render_static(<T>Angiv antal gæster</T>)}
					onChange={(e) => setParticipants(e.target.value)}
					className="text-weight-reg"
					name="participants"
					id="participants"
					form="selector"
					value={participants}
				/>
			</div>
			<TraksConsumer>
				<InquiryModal filters={filters} modalTitle={modalTitle}>
					<div className="d-none d-md-block ms-lg-1 cursor_pointer">
						<div className="arrowButton centerItem">
							<img width={18} height={12} src="https://static.optimeet.dk/gfx/ico_thin-arrow-0.svg" alt='request' />
						</div>
					</div>
					<div className="d-block d-md-none d-flex justify-content-center pt-3">
						<Kbutton lg title={props.render_static(<T>Indhent tilbud</T>)} custom="mt-2  w-100 py-2 align-middle justify-content-center" prim md />
					</div>
				</InquiryModal>
			</TraksConsumer>
		</Container>
	);
};

export default LocationSelector;