import React, { useContext } from "react";
import DropDownButton from "./admin/DropdownButton";
import { MeContext } from './../MeContext';
import { RenderIf } from "./Reusables";

const CreateButton = () => {

	const { me } = useContext(MeContext);
	const icon = <img width={16} height={20} src="https://static.optimeet.dk/gfx/ico_add-circle-outline.svg" alt="create" className="me-2" />
	
	return (
		<RenderIf condition={me && me["user"] && me.user.roles.includes("admin")}>
			<div className="add-button position-absolute">
				<DropDownButton icon={icon} title="Opret" data={buttonData}/>
			</div>
		</RenderIf>
	);
}

const buttonData = [ 
	{
		title: "Mødested",
		icon: "https://static.optimeet.dk/gfx/ico_small_venues.svg",
		link: "editvenue/"
	},
	 {
		title: "Venue kategori",
		icon: "https://static.optimeet.dk/gfx/ico_small_categories.svg",
		link: "editcategory/"
	}, 
	{
		title: "Facilitet",
		icon: "https://static.optimeet.dk/gfx/ico_small_facilities.svg",
		link: "editfacility/"
	}, 
	{
		title: "Miljømærke",
		icon: "https://static.optimeet.dk/gfx/ico_small_certificates.svg",
		link: "editcertificate/"
	},  
	{
		title: "Region",
		icon: "https://static.optimeet.dk/gfx/ico_small_regions.svg",
		link: "editregion/"
	},  
	{
		title: "Supplier",
		icon: "https://static.optimeet.dk/gfx/ico_small_user.svg",
		link: "editsupplier/"
	},  
	{
		title: "Supplier kategori",
		icon: "https://static.optimeet.dk/gfx/ico_small_categories.svg",
		link: "editsuppliercategory/"
	},  
	{
		title: "Bruger",
		icon: "https://static.optimeet.dk/gfx/ico_small_user.svg",
		link: "partner/"
	}, 
	{
		title: "Indlæg",
		icon: "https://static.optimeet.dk/gfx/icon_small_post.svg",
		link: "editnews/"
	}, 
	{
		title: "Arrangement",
		icon: "https://static.optimeet.dk/gfx/icon_small_event.svg",
		link: "editevent/"
	}, 
	{
		title: "Magasin",
		icon: "https://static.optimeet.dk/gfx/icon_small_magazine.svg",
		link: "editmagazine/"
	}, 
	{
		title: "Banner",
		icon: "https://static.optimeet.dk/gfx/icon_small_post.svg",
		link: "editbanner/"
	}, 
	{
		title: "Landing region",
		icon: "https://static.optimeet.dk/gfx/ico_small_regions.svg",
		link: "editlandingregion/"
	}, 
	{
		title: "Landing kategori",
		icon: "https://static.optimeet.dk/gfx/ico_small_categories.svg",
		link: "editlandingcategory/"
	}, 
];

export default CreateButton;