import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { DashboardWrapper, EditBannersContent } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import { MeContext } from "MeContext";
import NoAccess from "c/admin/NoAccess";
import { APIGet, APIPost, APIDelete } from "API";
import { AlertModal } from "c/Reusables";
import { Loading } from "c/Loading";
import { T } from "../traks";
import { LL } from "Routes";

const EditBanner = ({ urlParams }) => {

	urlParams = useParams();
	const navigate = useNavigate();
	const bannerId = urlParams.bannerId;
    const [banner, setBanner] = useState({});

    const { me } = useContext(MeContext);
    const isAdmin = me && me.user.roles.includes("admin");
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });

	useEffect(() => {
		setShowLoading(true);
		if (urlParams.bannerId) {
			APIGet("banners/" + bannerId).then(res => {
				setShowLoading(false);
				if (res.data) {
					if (!res.data)
						navigate(LL("/mybanners"));
				}
				setBanner(res.data.banner);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		} else {
			APIPost("banners").then(res => {
				setBanner(res.data.new_banner);
				window.history.replaceState(null, '', res.data.new_banner.id);
				setShowLoading(false);
				window.location.reload(false);
			}).catch((err) => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const uploadImage = (files) => {
        setShowLoading(true);
        const filesInfo = files.map(({ file, ...f }) => f);
        APIPost("banners/files", { files: filesInfo, action: "get_upload_url"}).then(res => {
            const file = files[0];
            const preSignedUrl = res.data.upload_urls[0];
            const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
            return axios.put(preSignedUrl, file.file, { headers }).then(res => {
                const newFileName = preSignedUrl.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk");
                setBanner({ ...banner, "image_url": newFileName });
                setShowLoading(false);
                setAlertType({ type: "success", text: "Banner uploaded" });
                setShowAlert(true);
                return newFileName;
            }).catch(err => console.log(err))
        }).catch(err => console.log(err))
    };

    const saveBanner = (newBanner = null) => {
        setShowLoading(true)
        let body = !newBanner ? banner : newBanner;
        APIPost("banners/" + bannerId, body).then(res => {
            setShowLoading(false);
            setAlertType({ type: "success", text: "Banner saved" });
            setShowAlert(true);
        }).catch(err => {
            setShowLoading(false);
            setAlertType({ type: "error", text: err.response.data });
            setShowAlert(true);
        })
    };

	const deleteBanner = () => {
		setShowLoading(true);
		const resource = "banners/" + bannerId;
		APIDelete(resource).then(res => {
			setShowLoading(false);
			navigate(LL("/mybanners/"));
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

    if (!isAdmin) {
        return (
            <DashboardWrapper index={15}>
                <NoAccess />
            </DashboardWrapper>
        )
    };

    return (
        <DashboardWrapper index={15}>
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
            <Loading
                showLoading={showLoading}
                inFront
            />
            <h5 className="mb-3"><T>Rediger banners</T></h5>
            <div>
                <Notification>Du redigerer: {banner.title}</Notification>
                <EditBannersContent
                    uploadImage={uploadImage}
                    banner={banner}
                    setBanner={setBanner}
                    saveBanner={saveBanner}
                    deleteItem={deleteBanner}
                />
            </div>
        </DashboardWrapper>
    );
};

export default EditBanner