import { createContext } from 'react';

const DataContext = createContext({
	Id: null,
	data: null,
	setData: (newData) => {},
	partners: null,
	setPartners: (partners) => {},
	certificates: null,
	facilities: null,
	locations: null,
	categories: null,
});

export {
	DataContext,
};