import React from "react";
import { T } from "../../traks";

const NoAccess = () => {
	return (
		<div className="py-4">
            <T>You do not have access to his page. Make sure you are logged in and have the correct admin role</T>
    	</div>
    );
};

export default NoAccess;