import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { ListCardList, VenueCard, MapCard, PromotedCard } from '../Cards';
import Kbutton from "c/KButton";
import { T } from "../../traks";

const PreviewCards = ( {data} ) => {

	const [modalOpen, setModalOpen] = useState(false);
	const toggle = () => {
		setModalOpen(!modalOpen);
	};

	const closeIcon = <img className="btn p-1 rounded-circle" width={20} height={20} style={{ position: "absolute", right: "-20px", top: "-20px", backgroundColor: "white" }} onClick={() => toggle()} src="https://static.optimeet.dk/gfx/ico_close.svg" alt='icoClose' />

	return (
		<div className="w-100 d-flex justify-content-center align-items-center">
			<div className="gallery_container" onClick={() => toggle()}>
				<Kbutton
					disabled={data["imagesUrls"] === undefined || data["imagesUrls"].length === 0 || data["description"] === undefined}
					custom="py-2 k-btn-greybg"
					sm
				>
					<T>Forhåndsvisning af kort</T>
				</Kbutton>
			</div>
			<Modal style={{ marginTop: "400px", minWidth: "50%" }} fade={true} isOpen={modalOpen} className="gallery_wrapper">
				<ModalBody className="p-0 border-0 mx-auto gallery_modal" style={{ maxWidth: 1100 }}> {closeIcon}
					<div style={{ borderRadius: 20, backgroundColor: "#F6F4EF" }} className="mobile_buttons_wrapper">
						<div className="p-5 w-100">
							<div style={{ maxWidth: "1000px" }}>
								<ListCardList
									data={data} key={"preview"}
								/>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div className="pt-3" style={{ maxWidth: "300px" }}>
									<VenueCard
										data={data} key={"preview"}
									/>
								</div>
								<div className="pt-3 ms-5 pt-5 mt-5" >
									<MapCard
										data={data} key={"preview"}
									/>
								</div>
							</div>
							<div className="pt-3 ms-5 pt-5" style={{ maxWidth: "410px" }}>
									<PromotedCard
										data={data} key={"preview"}
									/>
								</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default PreviewCards;