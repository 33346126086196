import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';

function LDropDown(props) {

	const placeholder = props.placeholder
	const defaultVal = props.defaultVal;
	const [selectedValue, setSelectedValue] = useState(placeholder);
	const itemClick = (name, id) => {
		setSelectedValue(name);
		props.setVal(id);
	}
	const sendAll = (e) => {
		setSelectedValue(e.name);
		props.setVal(e);
	}

	useEffect(() => {
		setSelectedValue(placeholder);
	}, [defaultVal, placeholder]);

	const dropdownItemInner = (k, i) => {
		if (k.Id === 0) {
			return null;
		}
		return (
			<DropdownItem
				className='py-2 me-2'
				key={i}
				onClick={() => props.sendAll ? sendAll(k) : itemClick(k.name, k.id)}
			>
				<span className='' style={{ whiteSpace: 'normal' }}>{k.name}</span>
			</DropdownItem>
		);
	}

	const resetItem = (props) => {
		return (
			<DropdownItem
				key="reset"
				className='py-2 me-2'
				onClick={() => itemClick(props.resettable, "")}
				>
				<span>{props.resettable}</span>
			</DropdownItem>
		);
	}

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} direction={props.direction}>
			<DropdownToggle className={"p-0 hoverfxlight dropdownlist-inner text-start w-100 font-primary " + props.className}>
				<div className="d-flex flex-row align-items-center" style={{height: props.height ? props.height : 40}}>
					<div className='text-md text-weight-semi ms-3 w-100'>{props.children}{props.icon}{selectedValue}</div>
					<img alt="caret" width="10" height="10" src={dropdownOpen ? "https://static.optimeet.dk/gfx/ico_dropdown_toggle_closed.svg" : "https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_dropdown_toggle_open.svg"} className="pt-1 mb-1 ms-1 me-3" />
				</div>
			</DropdownToggle>
			<DropdownMenu className='w-100 p-0 m-0'>
				<div className='my-2'>
					{props.resettable && <span>{resetItem(props)}</span>}
					{props.data.map((k, i) => dropdownItemInner(k, i))}
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

LDropDown.propTypes = {
	direction: PropTypes.string,
};

export default LDropDown;