import { APIGet } from "../../API";

const  fetchImgsWithRetry = (correctLength, address, type, setData, setShowLoading) => {
	const checkImgs = () => {
		setTimeout(() => {
			APIGet(address)
				.then(res => {
					if (res.data[type].imagesUrls.length === correctLength) {
						setData(prevState => ({
							...prevState, imagesUrls: res.data[type].imagesUrls
						}));
						setShowLoading(false);
					} else {
						checkImgs();
					}
				})
				.catch(err => {
					console.log(err);
				});
		}, 1000 * correctLength);
	}
	checkImgs();
};

export default fetchImgsWithRetry;