import React, { useEffect, useState } from "react";
import ModalOpenerListener from "../c/modals/ModalOpenerListener";
import Header from "../c/Header";
import Footer from "../c/Footer";
import Finder from "../c/Finder";
import SliderSelector from '../c/SliderSelector';
import SelectedLocations from '../c/SelectedLocations';
import HotelOfMonth from '../c/HotelOfMonth';
import FindAfterLocation from '../c/FindAfterLocation';
import MeetingLocations from '../c/MeetingLocations';
import GetHelp from '../c/GetHelp';
import KursuslexAd from '../c/KursuslexAd';
import FixedContactButton from "c/FixedContactButton";

import "react-datepicker/dist/react-datepicker.css";
import "../css/dateselector.css";
import "../css/cards.css";
import axios from "axios";

const Home = () => {

	const [stats, setStats] = useState(undefined);

	useEffect(() => {
		const filterUrl = window.location.origin.includes("https://optimeet.dk") ? "https://static.optimeet.dk/stats/stats.json" : "https://static.optimeet.dk/stats/test-stats.json"
		sessionStorage.removeItem('OptimeetSearchFilters');
		sessionStorage.removeItem('OptimeetSupplierFilters');
		if (!stats)
			axios.get(filterUrl).then(res => {
				setStats(res.data);
			}).catch(err => console.log(err))
	}, [stats])

	return (
		<div>
			<ModalOpenerListener />
			<Header transparent />
			<Finder customText={"Book lokaler til dine møder, konferencer og events"} />
			<SliderSelector />
			<SelectedLocations />
			<HotelOfMonth />
			<FindAfterLocation />
			<MeetingLocations stats={stats} />
			<GetHelp />
			<KursuslexAd />
			<Footer />
			<FixedContactButton />
		</div>
	);
}

export default Home;
