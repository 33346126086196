import React, { useState, useContext, useEffect } from 'react';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Dropdown, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { MeContext } from './../MeContext';
import { APIGet, APIPost } from 'API.js';
import Kbutton from './KButton';
import LoginModal from "./modals/LoginModal";
import { T } from "../traks";
import InquiryModal from "./modals/InquiryModal";
import { LL } from 'Routes';
import { VenueImg } from './Reusables';

const Header = (props) => {

	const { me, setMe } = useContext(MeContext);
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const iconHeadphone = <img width={16} height={20} src="https://static.optimeet.dk/gfx/ico_headset-0.svg" alt='iconHeadphones' />;
	const iconHeadphoneLight = <img width={16} height={20} src="https://static.optimeet.dk/gfx/ico_headset-light-0.svg" alt='iconHeadphones' />;
	const iconLogin = <img width={18} height={22} src="https://static.optimeet.dk/gfx/ico_person-outline-0.svg" alt='iconLogin' />;
	const iconLoginLight = <img width={18} height={22} src="https://static.optimeet.dk/gfx/ico_person-outline-light-2.svg" alt='iconLogin' />;

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [dropdownDisabled, setDropdownDisabled] = useState(false);

	const [searchOpen, setSearchOpen] = useState(false);
	const toggleSearchOpen = () => {
		setSearchOpen(!searchOpen);
	}

	const isTransparent = props.transparent ? "transparent-header" : "border-bottom";
	const customKButton = props.transparent ? " text-white bg-transparent transparent-desktop" : "";
	const headerClasses = ["container-fluid header"];
	const combinedClasses = `${isTransparent} ${headerClasses}`;
	const ShowLogo = () => (props.transparent ? "https://static.optimeet.dk/gfx/klogo.svg" : "https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico-logo-color-0.svg");
	const MobileLogo = () => ("https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico-logo-color-0.svg");

	const [logoutText, setLogoutText] = useState(<T>Logout</T>);

	const logout = () => {
		setLogoutText("Logging out...");
 		APIGet("logout")
			.then((reply) => {
				localStorage.removeItem('optimeet_ipse_session_id');
				setMe(null);
				setLogoutText(<T>Logout</T>);
				hideDropdown();
			})
			.catch((error) => {
				setLogoutText("Error logging out");
			}
		)
	}

	const hideDropdown = () => {
		setDropdownDisabled(false);
		setDropdownOpen(false);
	}
	const disableDropdown = () => {
		setDropdownDisabled(true);
	}

	const openDashboard = () => {
		navigate(LL('/dashboard/'), { replace: true });
	}

	const hoverListingPage = () => {
		sessionStorage.removeItem('OptimeetSearchFilters');
	}

	const hoverSupplierPage = () => {
		sessionStorage.removeItem('OptimeetSupplierFilters');
	}

	return (
		<div className={combinedClasses}>
			<Container fluid className="px-0 py-0 py-lg-2" style={{ maxWidth: 1290 }}>
				<Navbar expand={"lg"}>
					<NavbarBrand href={LL("/")}>
						{!isOpen &&
							<img alt="klogo" className='hoverfx' width={161} height={50} src={ShowLogo()} />
						}
						{isOpen &&
							<img alt="klogo" className='hoverfx' width={161} height={50} src={MobileLogo()} />
						}
					</NavbarBrand>
					<NavbarToggler onClick={toggle} />
					<Collapse isOpen={isOpen} navbar>
						<Nav className="mx-xs-0 ms-lg-5 me-auto mt-3 mt-lg-0" navbar>
							<NavItem>
								<NavLink onMouseEnter={hoverListingPage} className="headertext me-lg-3 py-3 py-lg-0 hoverfx" href={LL("/konferencesteder/")}><T>Konferencesteder</T></NavLink>
							</NavItem>
							<NavItem>
								<NavLink onMouseEnter={hoverSupplierPage} className="headertext me-lg-3 py-3 py-lg-0 hoverfx" href={LL("/leverandoerer/")}><T>Leverandører</T></NavLink>
							</NavItem>
							<NavItem>
								<NavLink className="headertext me-lg-3 py-3 py-lg-0 hoverfx" href={LL("/arrangementer/")}><T>Arrangementer</T></NavLink>
							</NavItem>
							<NavItem>
								<NavLink className="headertext me-lg-3 py-3 py-lg-0 hoverfx" href={LL("/nyhedsmagasin/")}><T>Nyheder</T></NavLink>
							</NavItem>
							<NavItem>
								<NavLink className="headertext me-lg-3 py-3 py-lg-0 hoverfx" href={LL("/moedeeventmagasin/")}><T>Inspiration</T></NavLink>
							</NavItem>
							<NavItem onClick={toggleSearchOpen}>
								{!searchOpen && <NavLink className="headertext me-lg-3 py-3 py-lg-0 hoverfx"><i className="bi bi-search me-2" /><T>Søg</T></NavLink>}
								{searchOpen && <SearchComponent />}
							</NavItem>
						</Nav>
						<Nav className="d-md-flex align-items-md-left align-items-lg-center menu-buttons mt-2 mt-lg-0" navbar>
							<NavItem>
								<div className='py-3 py-lg-0 pe-3 hoverfx'>
									<InquiryModal modalTitle={<T>Få hjælp til din booking</T>}>
										<span className="nav-icon-headphones">
											{props.transparent && !isOpen ?
												<Kbutton icon={iconHeadphoneLight} custom={customKButton} grey md><span className="d-none d-xl-inline"><T>Få bookinghjælp</T></span></Kbutton>
												:
												<Kbutton icon={iconHeadphone} custom={customKButton} grey md><span className="d-none d-xl-inline"><T>Få bookinghjælp</T></span></Kbutton>
											}
										</span>
									</InquiryModal>
								</div>
							</NavItem>
							<NavItem>
								<Dropdown className='d-flex k-dropdown-button' disabled={dropdownDisabled} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} >
									<DropdownToggle style={{backgroundColor:"transparent", padding:"0", border:"0"}}>
										{props.transparent && !isOpen ? 
											<div className="icon-only-btn hoverfx icon-only-btn-transparent">{iconLoginLight}</div>
											:
											<div className="icon-only-btn hoverfx">{iconLogin}</div>
										}
									</DropdownToggle>
									<DropdownMenu className='shadow ps-2 py-2'>
									{me !== null && me.user ?
										<span>
											<span onClick={openDashboard} className='btn'><T>Dashboard</T></span>
											<span className='btn' onClick={logout}>{logoutText}</span>
										</span>
										:
										<span>
											<LoginModal closeModal={(hideDropdown)} disableDropdown={(disableDropdown)}>
												<div stage="Login" className='btn'><T>Log in</T></div>
											</LoginModal>
											<LoginModal closeModal={hideDropdown} disableDropdown={(disableDropdown)}>
												<div stage="BecomePartner" className='btn py-2'><T>Bliv partner</T></div>
											</LoginModal>
										</span>
									}
									</DropdownMenu>
								</Dropdown>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar>
			</Container>
		</div>
	)
};

const SearchComponent = () => {

	const [locationsData, setLocationsData] = useState(undefined);
	const [searchText, setSearchText] = useState('');
	const [hasSearched, setHasSearched] = useState(false);

	const [query, setQuery] = useState({
        "query_string": searchText,
        "page": 0,
        "limit": 10,
    });

    useEffect(() => {
        const searchDelay = setTimeout(() => {
            if (searchText.length > 2 && searchText === originalSearch.current) {
                setQuery({ ...query, query_string: searchText, page: 0 });
            }
        }, 700);
        return () => clearTimeout(searchDelay);
        // eslint-disable-next-line
    }, [searchText]);
	
	useEffect(() => {
		query.query_string && doQuery();
        // eslint-disable-next-line
    }, [query]);

	function doQuery() {
        const body = { query, includeBanners: false };
        APIPost("search", body).then(res => {
            setLocationsData(res.data.result);
			setHasSearched(true);
        }).catch(err => {});
    }

	const originalSearch = React.useRef("");
	const handleSearchTextChange = (e) => {
		originalSearch.current = e.target.value.replace(/[^a-zA-Z0-9æøåÆØÅ\s]/g, '');
		setSearchText(e.target.value);
	};

	return (
		<NavItem>
			<div style={{ bottom: "4px" }} className='d-xl-flex absolutexl'>
				<Input
					autoFocus={true}
					className="mb-xl-1 w-75 text-weight-reg search_bg_transparent"
					placeholder="Søg på mødested"
					onChange={handleSearchTextChange}
					name="search"
					id="search"
					value={searchText}
				/>
			</div>
			<>
				{searchText.length > 2 && locationsData && locationsData.length ?
					<div style={{ backgroundColor: "white", zIndex: 9 }} className='border-radius-12 border absolutexl headersearchtop'>
						<div className='py-2'>
							{locationsData.map((item, index) => (
								<>
									{item.visibleOnWebsite &&
										<a className="remove-link-style" href={LL("/kursuscenter/" + item.urlSlug)}>
											<div className='d-flex pt-1 text-black text-bold text-nowrap font-primary me-1 headersearchcard' key={index}>
												<VenueImg card className="border-radius-8 mx-2 col-1" width={30} height={30} img={item.imagesUrls[0]} alt="venueimg" />
												<p className='me-5 pt-1 col-7'>{item.title.length > 33 ? `${item.title.substring(0, 31)}...` : item.title}</p>
												<p className='pt-1 me-2 d-none d-lg-block'>{item.city.length > 13 ? `${item.city.substring(0, 11)}...` : item.city}</p>
											</div>
										</a>
									}
								</>
							))}
						</div>
					</div>
					:
					<>
						{hasSearched && !!searchText.length > 2 && <span className='d-flex ms-3 py-2 text-black text-bold text-nowrap font-primary me-3'><T>No search results</T></span>}
					</>
				}
			</>
		</NavItem>
	)
};

export default Header;