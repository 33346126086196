import React, { useEffect, useState } from "react";

const u = undefined;

const ChoicesSelect = ({data = u, venueData = u, subtitle = u, searchPlaceholder = u, showSearchBar = u, sendSelected, users = undefined, venue = undefined}) => {

	const [items] = useState(data);
	const [selectedItems, setSelectedItems] = useState(venueData);
	const [availableItems, setAvailableItems] = useState(items && selectedItems ? items.filter((item) => !selectedItems.some((otherItem) => otherItem.id === item.id)): items);
	const [showItems, setShowItems] = useState("");
	const [query, setQuery] = useState("");

	const HandleClickAdd = (item) => {
		setAvailableItems(availableItems.filter((e) => e !== item));
		setSelectedItems([...selectedItems, item]);
		sendSelected([...selectedItems, item]);
		setShowItems("");
		setQuery("");
	};

	const HandleClickRemove = (item) => {
		setSelectedItems(selectedItems.filter((e) => e !== item));
		setAvailableItems([...availableItems, item]);
		sendSelected(selectedItems.filter((e) => e !== item));
	};

	const filteredItems = users ? availableItems.filter(item => item.screenName.toLowerCase().includes(query)) : venue ? availableItems.filter(item => item.title.toLowerCase().includes(query)) : availableItems.filter(item => item.name.toLowerCase().includes(query));

	useEffect(() => {
		venue&&setSelectedItems(venueData)
		// eslint-disable-next-line
	}, [venueData])

	return (
		<div className="border border-1 rounded">
			<div className="w-100 d-flex">
				<div
					className="w-100 text-sm bold p-3 input-light-blue choices-header">
					{subtitle}
				</div>
			</div>
			<div className="d-flex">
				<div className={"w-100 p-3 items-unselected" } style={{ borderRight: "1px solid #eaeaea" }}>
					{showSearchBar &&
						<input
							id="search-input"
							className={"w-100 rounded border border-1 py-2 ps-3 mb-2 text-sm"}
							type="text" 
							placeholder={searchPlaceholder}
							value={query}
							onChange={(e) => {
								setQuery(String(e.target.value.toLowerCase()));
							}}
						/>
					}
					<div className={showItems + " container-scrollbar"} style={{maxHeight: 345, overflowY: "auto"}}>
						{filteredItems.map((item, i) => (
							<div key={i} className="py-1 item">
								<button
									className="px-2 py-1 border-0 rounded-pill text-sm input-light-blue fade-in"
									value={item}
									onClick={() => HandleClickAdd(users ? item : item)}
								>
								{users ? item.screenName : venue ? item.title : item.name}
								</button>
							</div>
						))}
					</div>
				</div>
				<div className="w-100 p-3 items-selected container-scrollbar" style={{maxHeight: 345, overflowY: "auto"}}>
					{selectedItems && selectedItems.map((item, i) => (
						<div key={i} className="py-1 item">
							<button
								className="px-2 py-1 border-0 rounded-pill text-sm input-light-blue fade-in d-flex align-items-center"
								value={item}
								onClick={() => HandleClickRemove(users ? item : item)}
							>
								<span className="checkmark">
									<div className="checkmark_stem"></div>
									<div className="checkmark_kick"></div>
								</span>
								{users ? item.screenName : venue ? item.title : item.name}
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ChoicesSelect;