import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MeContext } from './../MeContext';
import { APIGet, APIPost, APIDelete } from "API";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import CreateButton from "c/CreateButton";
import Sidebar from "c/admin/Sidebar";
import { EditGroup } from "c/admin/DashboardComponents";
import { AlertModal } from "c/Reusables";
import NoAccess from "c/admin/NoAccess";
import { T } from "../traks";
import { Loading } from "c/Loading";
import { LL } from "Routes";

const EditFacility = ({ urlParams }) => {

	const { me } = useContext(MeContext);
	const navigate = useNavigate();
	const isAdmin = me && me.user.roles.includes("admin");

	urlParams = useParams();
	
	const facId = urlParams.facilityId;
	const [fac, setFac] = useState(undefined);

	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	useEffect(() => {
		setShowLoading(true);
		if (urlParams.facilityId) {
			APIGet("facilities/" + facId).then(res => {
				setShowLoading(false);
				if (res.data) {
					if (!res.data)
						navigate(LL("/facilities"));
				}
				setFac(res.data.facility);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		} else {
			APIPost("facilities", { "name": "new facility", "key": "new-facility" }).then(res => {
				setFac(res.data.new_facility);
				window.history.replaceState(null, '', res.data.new_facility.id);
				setShowLoading(false);
				window.location.reload(false);
			}).catch((err) => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveFacility = (newFacility = null) => {
		setShowLoading(true);
		let body = !newFacility ? fac : newFacility;
		APIPost("facilities/" + facId, body).then(res => {
			setShowLoading(false);
			setAlertType({ type: "success", text: "Facility saved" });
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	const deleteFacility = () => {
		setShowLoading(true);
		const resource = "facilities/" + facId;
		APIDelete(resource).then(res => {
			setShowLoading(false);
			navigate(LL("/facilities/"));
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	if (!isAdmin) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!fac) {
		return (
			<DashboardWrapper index={4}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}
	
	return (
		<div className="dashboard mb-5">
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<CreateButton />
			<Sidebar index={4} />
			<div className="dashboard-wrapper">
				<h5 className="mb-3"><T>Rediger kategori</T></h5>
				<Notification><T>Du redigerer</T>:&nbsp;{fac.name}</Notification>
				<div className="d-flex w-100">
					<EditGroup
						values={fac}
						setValues={setFac}
						save={saveFacility}
						deleteItem={deleteFacility}
						classname="w-75"
					/>
				</div>
			</div>
			<Loading
				showLoading={showLoading}
				inFront
			/>
		</div>
	);
}

export default EditFacility;