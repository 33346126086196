import React from "react";

const Notification = (props) => {

  	return (
		<>
			<div className={"alert alert-primary"}>
				{props.children}
			</div>
		</>
	);
}

export default Notification;