import React from "react";
import DashboardContainer from "../DashboardContainer";
import { T } from "../../../traks";
import { Input } from "reactstrap";
import RichTextEditor from "../Richtext";
import DashboardContainerHeader from "../DashboardContainerHeader";

const LandingTexts = ({ pageContent, setPageContent, title, setTitle }) => {

  	return (
        <DashboardContainer>
            <DashboardContainerHeader thumb={<i style={{ fontSize: 20 }} className="bi bi-blockquote-right"/>} border><T>Beskrivelse</T></DashboardContainerHeader>
            <div className="w-100 me-3 px-4 pt-4" style={{ flex: 5 }}>
                <div className="mb-2">
                    <span><T>Titel</T></span>
                </div>
                <Input
                    className="py-3 text-weight-reg input-light-blue"
                    value={title && title ? title : ""}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="w-100 me-3 px-4 pt-4" style={{ flex: 5 }}>
                <div className="mb-2">
                    <span><T>Kort beskrivelse</T></span>
                </div>
                <Input
                    className="py-3 text-weight-reg input-light-blue"
                    value={pageContent.shortDescription && pageContent.shortDescription ? pageContent.shortDescription : ""}
                    onChange={(e) => setPageContent(prevState => ({
                        ...prevState,
                        shortDescription: e.target.value
                    }))}
                />
            </div>
            <div className="px-4 pb-4 pt-4">
                <div className="mb-3">
                    <span><T>Beskrivelse</T></span>
                </div>
                <RichTextEditor fieldValue={pageContent.description} update={(e) => setPageContent(prevState => ({
                    ...prevState,
                    description: e
                    }))} height={200} 
                />
            </div>
        </DashboardContainer>
  	);
};

export default LandingTexts;