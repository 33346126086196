import React, { useState } from "react";
import { RenderIf } from "./Reusables";

const FAQ = ({ faq = undefined, title = undefined }) => {

	return (
        <>
            <RenderIf condition={faq && faq.length && title}>
                <div className="container mt-lg-5 pt-5">
                    <h3 className="mb-5">{title}</h3>
                    <QAAccordion items={faq && faq} />
                </div>
            </RenderIf>
        </>
	);
};

const QAAccordion = ({ items }) => {

    const [openIndex, setOpenIndex] = useState(null);
    const handleItemClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
  
    return (
        <div style={{ maxWidth:"900px" }}>
            {items.map((item, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    <div
                        onClick={() => handleItemClick(index)}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: index === openIndex ? '#EEE' : '#FFF',
                        }}
                    >
                        <span className="text-bold">{item.question}</span>
                    </div>
                    {index === openIndex && <div className="pt-2">{item.answer}</div>}
                    <div className="py-3">
                        <div style={{ height:"1px", backgroundColor:"#A7925B" }} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQ;