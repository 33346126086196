import React from "react";
import { RedigerBtn } from "c/Reusables";
import { T } from "../../traks";

const ListItemLarge = ({ title, image=null, visible, id, goTo, noImg, bannerType }) => {

	const IsVisible = () => {
		return (
			<div>
				{visible ? <span className="primarycolor-light"><T>Synligt</T></span> : <span className="red_text"><T>Skjult</T></span>}
			</div> 
		);
	}

	return (
		<div className="border-1 border-top text-md list-item">
			<a className="link_nostyle" href={(goTo + id)}>
				<div className="py-3 px-4 d-flex">
					<div className="col-0 me-4">
						{!noImg &&
							<div>
								{image ? <img style={{width: "54px", height: "35px", borderRadius: "7px"}} src={image} alt="imagethumb" /> : <div className="w-100 text-center list-item-venue-img pt-2 me-2" style={{backgroundColor: "#F4EFE4", maxWidth: "54px", height: "35px", borderRadius: "7px"}}>No img</div>}
							</div>
						}
					</div>
					<div className="col-2 mt-1">
						<span>{title}</span> 
					</div>
					<div className="col-1 mt-1">
						<span><IsVisible /></span>
					</div>
					<div className="col-7 mt-1">
						<span className="grey">{bannerType === "billboard" ? '160x600' : "970x250"}</span>
					</div>
					<RedigerBtn />
				</div>
			</a>
		</div>
	);
};

export default ListItemLarge;
