import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import NewsPost from "../c/NewsPost";
import { APIGet } from "../API"
import NoContent from "../c/admin/PageNotFound";
import { AlertModal } from "../c/Reusables";
import { Loading } from "c/Loading";
import FixedContactButton from "c/FixedContactButton";
import { useParams } from "react-router-dom";
import { Banners } from "c/Banners";

const PostInner = ({ newsPage, banners }) => {
    return (
			<Container fluid>
				<Container className="pt-5">
					<Row style={{ paddingBottom: "100px" }}>
						<Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={9}>
							<div className="mb-4">
								<Banners data={banners} billBoard />
							</div>
							<NewsPost content={newsPage} />
						</Col>
						<Col>
							<Banners sticky data={banners} className="d-none d-lg-block" skyScraper/>
						</Col>
					</Row>
				</Container>
			</Container>
    );
}

const NewsPage = () => {
		const [banners, setBanners] = useState();
    const urlParams = useParams();
    const urlSlug = urlParams.safeName;
    const [newsPage, setNewsPage] = useState(undefined)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState({ type: "", text: "" })
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        setShowLoading(true)
        APIGet("news/" + urlSlug).then((res) => {
            setShowLoading(false)
            setNewsPage(res.data.news)
        }).catch(err => {
            setAlertType({ type: "error", text: err.response.data })
            setShowAlert(true);
            setShowLoading(false)
				})
				APIGet("banners").then((res) => {
				setBanners(res.data.banners);
			}).catch(err => {
				console.log(err)
		})
        // eslint-disable-next-line
    }, [])

    if (!urlSlug) {
        return <NoContent />;
    }


    if (!newsPage) {
        return (<>
            <Loading
                showLoading={showLoading}
                inFront
            />
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
        </>)
    }

    return (
        <div>
            <Header />
				<PostInner newsPage={newsPage} banners={banners} />
            <Footer />
            <FixedContactButton />
        </div>
    );
}

export default NewsPage;
