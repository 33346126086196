import React, { useEffect, useState } from "react";
import { APIPost } from "API";
import { Col, Row, NavLink } from "reactstrap";
import Kbutton from './KButton';
import { PromotedCard } from './Cards';
import { T } from '../traks';
import { LL } from "Routes";

const SelectedLocations = ({ regionLocCode, regionName }) => {

	const [promotedLocationsData, setPromotedLocationsData] = useState(undefined);
	const dataText = promotedLocationsData ? <T>No venues found</T> : <T>Loading venues</T>;

	useEffect(() => {
		const queryLocations = regionLocCode;
		const body = {query: {limit: 3, page: 0, locationIds: [queryLocations]}}; //XX 'selected region locations' endpoint?
		if (queryLocations) {
			APIPost("search", body).then(res => {
				setPromotedLocationsData(res.data.result);
			}).catch(err => console.log(err));
		}
		// eslint-disable-next-line
	}, [regionLocCode]);

	return (
		<div className="container mt-5">
			<h2 className="justify-content-start justify-content-lg-center d-flex mb-4">Selected locations in {regionName}</h2>
			<div>
				{promotedLocationsData && promotedLocationsData.length && promotedLocationsData !== undefined ?
				<div className="container promoted-cards-wrapper px-0">
					<Row className="scrolling-wrapper-flexbox scroll-smooth font-primary">
						{promotedLocationsData.map((v, i) => (
							<Col key={i} xs={12} sm={6} md={6} lg={3} xl={3} className="d-flex mb-0 mb-sm-4 mb-lg-0 justify-content-center promoted-card-container">
								<>
									<PromotedCard
										data={v} key={"promoted" + i}
									/>
								</>
							</Col>
						))}
					</Row>
				</div>
				:
				<div className="py-5 my-5 text-center">{dataText}</div>}
			</div>
			<div className="text-md-center text-sm-left pt-5 d-flex justify-content-center w-100">
				<NavLink href={LL("/konferencesteder/")} className="full-mobile"><Kbutton lg grey custom=" btn text-black w-100" md><T>Alle mødesteder</T></Kbutton></NavLink>
			</div>
		</div>
	);
};

export default SelectedLocations;