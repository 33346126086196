import React, { useState, useEffect, useContext } from "react";
import DashboardContainer from "../c/admin/DashboardContainer";
import DashboardContainerHeader from "../c/admin/DashboardContainerHeader";
import ListItemLarge from "../c/admin/ListItemLarge";
import "../css/dashboard.css";
import { T, TraksConsumer } from "../traks";
import { APIGet, APIPost } from "API";
import { MeContext } from './../MeContext';
import NoAccess from "c/admin/NoAccess";
import { Loading } from "c/Loading";
import { AlertModal, CustomFilter } from "c/Reusables";
import { DashboardWrapper } from "c/admin/DashboardComponents";

const MyVenues = () => {
	const { me } = useContext(MeContext);
	const isLogged = me;
	const isAdmin = me.user.roles.includes("admin")
	const [data, setData] = useState(undefined);
	const [queriedData, setQueriedData] = useState(data);
	const [showLoading, setShowLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })

	useEffect(() => {
		if (isAdmin) {
			const body = { query: { /* listing_keys_only: true, */ show_hidden: true }, };
			APIPost("search", body).then(res => {
				setShowLoading(false)
				setData(res.data.result);
				setQueriedData(res.data.result);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data })
				setShowAlert(true);
				setShowLoading(false)
			});
		} else {
			const userId = me.user.id
			APIGet("users/" + userId + "/venues").then(res => {
				setShowLoading(false)
				setData(res.data);
				setQueriedData(res.data);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data })
				setShowAlert(true);
				setShowLoading(false)
			})
		}
		// eslint-disable-next-line
	}, [])

	const setSearchData = (query) => {
		setQueriedData(query);
	};

	const NoVenuesAssigned = () => {
		return (
			<div className="py-4">
				<T>No venues assigned to you</T>
			</div>
		)
	}

	const searchPlaceholder = <T>Search</T>;

	if (!isLogged) {
		return (
			<DashboardWrapper index={2}>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (data === undefined) {
		return (
			<DashboardWrapper index={2}>
				<Loading
					showLoading={showLoading}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	if (data !== undefined && !data.length) {
		return (
			<DashboardWrapper index={2}>
				<NoVenuesAssigned />
			</DashboardWrapper>
		)
	}

	const filterOptions = [
		{
			name: "A-Z",
			type: 0,
			dataName: "title",
		},
		{
			name: "Edited",
			type: 1,
			dataName: "updatedTs",
		},
		{
			name: "Created",
			type: 1,
			dataName: "createdTs",
		},
		{
			name: "Visible",
			type: 1,
			dataName: "visibleOnWebsite",
		},
		{
			name: "Is premium",
			type: 1,
			dataName: "priority",
		},
	];

	const FilterComponent = 
		<>
			<CustomFilter
				data={data}
				filterOptions={filterOptions}
				setData={setSearchData}
			/>
		</>
	;

	return (
		<DashboardWrapper index={2}>
			<h5 className="mb-3">
				<T>Mine mødesteder</T>
			</h5>
			<DashboardContainer>
				<TraksConsumer>
					<DashboardContainerHeader
						filter={FilterComponent}
						valueToSearch={"title"}
						data={data}
						searchPlaceholder={searchPlaceholder}
						setData={setSearchData}
						search><T>Aktive mødesteder</T>
					</DashboardContainerHeader>
				</TraksConsumer>
				{data &&
					<span>
						{queriedData.map((venue, i) => (
							<ListItemLarge
								key={"venue" + i}
								goTo={("/editvenue/")}
								title={venue.title}
								visible={venue.visibleOnWebsite}
								address={venue.address_1 + ", " + venue.address_2}
								image={venue.imagesUrls && venue.imagesUrls[0] ? venue.imagesUrls[0] : ''}
								id={venue.id}
								timestamp={venue.updatedTs}
								priority={venue.priority}
							/>
						))}
					</span>
				}
			</DashboardContainer>
		</DashboardWrapper>
	);
};

export default MyVenues;