import React from "react";
import Header from "c/Header";
import Footer from "c/Footer";
import BookingNewsletters from "c/BookingNewsletters";
import { Container } from "reactstrap";
import { T, TraksConsumer } from "../traks";
import FixedContactButton from "c/FixedContactButton";

const BookingConfirmation = () => {
	
	return (
		<>
			<Header />
			<Container className="mt-0 mt-lg-5 pt-lg-2 text-center">
				<img className="mb-2" alt="checkmark" src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_checkmark_dark_64x64-0.svg" />
				<h1><T>Tak for din forespørgsel</T></h1>
				<p><T>Du vil få svar hurtigst muligt</T></p>
			</Container>
			<TraksConsumer>
				<BookingNewsletters />
			</TraksConsumer>
			<Footer />
			<FixedContactButton />
		</>
	);
}

export default BookingConfirmation;