import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import NewsList from "c/NewsList";
import { APIGet } from "API";
import { Banners } from "c/Banners";
import { AlertModal } from "c/Reusables";
import { LL } from "Routes";
import { T } from "../traks";
import { Loading } from "c/Loading";
import FixedContactButton from "c/FixedContactButton";

const NewsInner = ({ news, setPage, page, totalPages, banners }) => {

	return (
		<Container fluid className="bg-ulight">
			<Container className="pt-5">
				<Row className="pb-5">
					<Col col={12} lg={9}>
						<Banners data={banners} billBoard/>
						<div className="mt-4">
							<h1 className="pb-3"><T>Optimeet News</T></h1>
							<div className="py-2 pb-4">
								Sidste nyt fra møde- og eventbranchen samt faglig inspiration og tips til planlægning af dine møder, konferencer og events m.m. Ønsker du nyhederne direkte i din indbakke - tilmeld dig Optimeet News <a href={LL("/tilmelding")}>her</a>.
							</div>
						</div>
						<NewsList newsList={news} setPage={setPage} page={page} totalPages={totalPages} />
					</Col>
					<Col>
						<Banners sticky data={banners} className="d-none d-lg-block" skyScraper/>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

const News = () => {
	const [news, setNews] = useState(undefined)
	const [banners, setBanners] = useState();
	const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
	const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1)
	const [pages, setPages] = useState(1)
	const navigate = useNavigate();
	const [showLoading, setShowLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })

	useEffect(() => {
		navigate(LL("/nyhedsmagasin") + "?page=" + page, { replace: true });
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setShowLoading(true)
		APIGet("news", { page }).then((res) => {
			setNews(res.data.news)
			setPages(res.data.pages)
			setShowLoading(false)
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
			setShowLoading(false)
		})
			APIGet("banners").then((res) => {
			setBanners(res.data.banners);
		}).catch(err => {
			console.log(err)
		})
	}, [page])

	const handlePageChange = (newPage) => {
		const value = newPage === "next"
			? page + 1
			: newPage === "previous"
				? page - 1
				: newPage
		setPage(value);
		setSearchParams({ page: value.toString() })
	};

	if (!news) {
		return (<>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</>)
	}
	return (
		<div>
			<Header />
			<NewsInner
				news={news}
				page={page}
				totalPages={pages}
				setPage={handlePageChange}
				banners={banners}/>
			<Footer />
			<FixedContactButton />
		</div>
	);
}

export default News;
