import { language_set } from "languages";

export const setLanguage = () => {
  	const try_lang = window.location.pathname
				.split('/')
				.slice(1)[0]
				.toLowerCase();
	if(language_set[try_lang]){
		return try_lang
	}else{
		// @ts-ignore
		return window.serve_language || "da"
	}
}
