import React from "react";
import { NewsTStoReadable } from "c/Reusables";

const NewsPost = ({ content }) => {

	return (
		<div>
			<div>
				<h1>{content.title}</h1>
			</div>
			<NewsTStoReadable createdTs={content.createdTs}/>
			<div className="d-flex align-items-center rounded my-3" style={{ maxHeight: 400, overflow: "hidden" }}>
				<img alt="postImg" className="w-100" src={content.imageUrl} />
			</div>
			<div className="pt-2 text-lineheight-24">
				<span dangerouslySetInnerHTML={{ __html: content.content }} />
			</div>
		</div>
	)
}

export default NewsPost;
