import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Input, Row, Col } from "reactstrap";
import { T } from "../../traks";
import { APIPost } from "API";
import Kbutton from '../KButton';
import { isValidEmail } from '../Reusables';

const InquiryModal = (props) => {

	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		props.fields && setInquiryForm({ ...inquiryForm, telephone: props.fields && props.fields.telephone, email: props.fields && props.fields.email });
		// eslint-disable-next-line
	}, [props.fields])

	const [inquiryForm, setInquiryForm] = useState({
		'name': '',
		'telephone': '',
		'email': '',
		'company': '',
		'comment': '',
	});

	const [showInvalidName, setShowInvalidName] = useState(false);
    const [isInvalidNumber, setIsShowInvalidNumber] = useState(false);
	const [showValidEmail, setShowInvalidEmail] = useState(false);
	const [buttonText, setButtonText] = useState(<T>Send forespørgsel</T>);

	const submit = () => {
		if (!inquiryForm.name.length) {
			setShowInvalidName(true);
		}
		if (!inquiryForm.telephone.length) {
			setIsShowInvalidNumber(true);
		}
		if (!isValidEmail(inquiryForm.email)) {
			setShowInvalidEmail(true);
		}
		if (isValidEmail(inquiryForm.email)) {
			setShowInvalidEmail(false);
		}
		if (inquiryForm.telephone.length && inquiryForm.name.length && isValidEmail(inquiryForm.email)){
			if (props.filters && props.filters.venueId) inquiryForm["venueId"] = props.filters.venueId;
			if (props.filters && props.filters.supplierId) inquiryForm["supplierId"] = props.filters.supplierId;
			if (props.filters && props.filters.participants) inquiryForm["participants"] = props.filters.participants;
			if (props.filters && props.filters.type) inquiryForm["type"] = props.filters.type;
			if (props.filters && props.filters.startDate) inquiryForm["startDate"] = props.filters.startDate;
			if (props.filters && props.filters.endDate) inquiryForm["endDate"] = props.filters.endDate;
			if (props.filters && props.filters.location) inquiryForm["location"] = props.filters.location;
			if (props.filters && props.filters.product) inquiryForm["product"] = props.filters.product;
			if (props.filters && props.filters.price) inquiryForm["price"] = props.filters.price;
			/* Modaltitle is important. Used for the title of the deal in Pipedrive */
			if (props.modalTitle && props.modalTitle.length) inquiryForm["modalTitle"] = props.modalTitle;
			APIPost("request", inquiryForm).then(res => {
				setButtonText(<T>Message sent</T>);
				const searchDelay = setTimeout(() => {
					window.location.href = "/bookingconfirmed/";
				}, 700);
				return () => clearTimeout(searchDelay);
			}).catch(err => console.log(err));
		}
	};

	const toggle = (e) => {
		if (!props.ignoreClick) {
			setModalOpen(!modalOpen);
			return;
		} else if (e.target.alt !== props.ignoreClick) {
			setModalOpen(!modalOpen);
			return;
		}
	};

	const closeIcon = <img
		className="btn p-1 rounded-circle"
		style={{ position: "absolute", right: 15, top: 15, backgroundColor: "white" }}
		onClick={(e) => toggle(e)}
		src="https://static.optimeet.dk/gfx/ico_close.svg" alt='icon to close'
	/>

	let modalTitle = <T>Uforpligtende forespørgsel på Mødested</T>;
	if(props.modalTitle) modalTitle = props.modalTitle;

	let modalDesc = <T>Vi er altid klar til at rådgive og hjælpe dig, enten skriftligt eller via telefonen.  Udfyld formularen og vi kontakter dig hurtigst muligt. Ellers er du altid velkommen til at ringe på tlf: 33 97 43 43</T>;
	if(props.modalDesc) modalDesc = props.modalDesc;

	return (
		<>
			<span className="p-0 m-0" onClick={(e) => toggle(e)}>{props.children}</span>
			<Modal autoFocus={false} fade={true} style={{ maxWidth: 690 }} isOpen={modalOpen} centered>
				<ModalBody style={{ padding: 0 }} className="pb-3"> {closeIcon}
					<div className="w-100">
						<div className="px-3">
							<div className="px-3 pt-5">
								<h4>{modalTitle}</h4>
								<div className="text-md text-lineheight-24 text-weight-reg grey pt-2">
									{modalDesc}
								</div>
								<form id='inquiry' onSubmit={(e) => e.preventDefault()}>
									<Row className="pt-0 pt-sm-4">
										<Col xs="12" sm="6" className="mt-4 mt-sm-0">
										<div className="lightgrey text-sm"><T>DIT NAVN *</T></div>
											<Input
												autoFocus={true}
												type="kform"
												className="py-3 text-weight-reg"
												onChange={(e) => setInquiryForm({ ...inquiryForm, name: e.target.value })}
												name="name"
												id="name"
												form="inquiry"
												value={inquiryForm.name}
											/>
											{showInvalidName && !inquiryForm.name.length && <div className="red_text"><i><T>Please insert name</T></i></div>}
										</Col>
										<Col xs="12" sm="6" className="mt-4 mt-sm-0">
										<div className="lightgrey text-sm"><T>TELEFON NR. *</T></div>
											<Input
												type="kform"
												className="py-3 text-weight-reg"
												placeholder={"+45"}
												onChange={(e) => setInquiryForm({ ...inquiryForm, telephone: e.target.value })}
												name="telefon"
												id="telefon"
												form="inquiry"
												value={inquiryForm.telephone}
											/>
											{isInvalidNumber && !inquiryForm.telephone.length && <div className="red_text"><i><T>Please insert phone number</T></i></div>}
										</Col>
									</Row>
									<Row className="pt-0 pt-sm-4">
										<Col xs="12" sm="6" className="mt-4 mt-sm-0">
											<div className="lightgrey text-sm"><T>EMAIL *</T></div>
											<Input
												type="kform"
												className="py-3 text-weight-reg"
												onChange={(e) => setInquiryForm({ ...inquiryForm, email: e.target.value })}
												name="email"
												id="email"
												form="inquiry"
												value={inquiryForm.email}
											/>
										{showValidEmail && <div className="red_text"><i><T>Please insert email</T></i></div>}
										</Col>
										<Col xs="12" sm="6" className="mt-4 mt-sm-0">
											<div className="lightgrey text-sm"><T>FIRMA/ORGANISATION</T></div>
											<Input
												type="kform"
												className="py-3 text-weight-reg"
												onChange={(e) => setInquiryForm({ ...inquiryForm, company: e.target.value })}
												name="company"
												id="company"
												form="inquiry"
												value={inquiryForm.company}
											/>
										</Col>
									</Row>
									<Row className="pt-0 pt-sm-4">
										<Col className="mt-4 mt-sm-0">
											<div>
												<div className="lightgrey text-sm"><T>SPØRGSMÅL ELLER KOMMENTAR</T></div>
												<textarea style={{ height: 140 }}
													type="kform"
													placeholder={props.supplier ? "Fortæl os gerne mere om dit event." : "Fortæl os gerne mere om dit event og ønsker til forplejning etc."}
													className="p-2 text-weight-reg grey w-100 form-control"
													onChange={(e) => setInquiryForm({ ...inquiryForm, comment: e.target.value })}
													name="comment"
													id="comment"
													form="inquiry"
												/>
											</div>
										</Col>
									</Row>
									<div className="d-flex align-items-center w-100 flex-row-reverse mt-3">
										<div>
											<Kbutton custom=" btn my-3 my-lg-0" prim md onClick={() => submit()}>{buttonText}</Kbutton>
										</div>
										<div className="me-2">
											<Kbutton custom=" btn" grey md onClick={() => toggle()}><T>Luk vindue</T></Kbutton>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}

export default InquiryModal;