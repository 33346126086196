import React from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import { T } from "../traks";

import "../css/privacy.css";

const CookiesPageInner = () => {
	return (
		<Container>
			<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="976c6a8b-b3ea-4195-820e-9ae49713dd9b" data-blockingmode="auto" type="text/javascript"></script>
			<Row>
				<Col className="privacy_page">
					<h1 className='py-4'><T>Cookies</T></h1>
						<p><span>Denne hjemmeside bruger cookies. Vi bruger cookies til at tilpasse vores indhold og annoncer, til at vise dig funktioner til sociale medier og til at analysere vores trafik. Vi deler også oplysninger om din brug af vores hjemmeside med vores partnere inden for sociale medier, annonceringspartnere og analysepartnere. Vores partnere kan kombinere disse data med andre oplysninger, du har givet dem, eller som de har indsamlet fra din brug af deres tjenester. </span></p>
						<p><span>Cookies er små tekstfiler, som kan bruges af websteder til at gøre en brugers oplevelse mere effektiv. </span></p>
						<p><span>Loven fastslår, at vi kan gemme cookies på din enhed, hvis de er strengt nødvendige for at sikre leveringen af den tjeneste, du udtrykkeligt har anmodet om at bruge. For alle andre typer cookies skal vi indhente dit samtykke.</span></p>
						<p><span>Dette websted bruger forskellige typer af cookies. Nogle cookies sættes af tredjeparts tjenester, der vises på vores sider.</span></p>
						<p><span>Du kan til enhver tid ændre eller tilbagetrække dit samtykke fra Cookiedeklarationen på vores hjemmeside.</span></p>
						<p><span>Få mere at vide om, hvem vi er, hvordan du kan kontakte os, og hvordan vi behandler persondata i vores Privatlivspolitik.</span></p>
						<p><span>Angiv venligst dit samtykke-ID og -dato, når du kontakter os angående dit samtykke.</span></p>
						<div className="ms-5">
						<li><span>Dit samtykke gælder for følgende domæner: www.booking.optimeet.dk / www.optimeet.dk</span></li>
						<li><span>Din aktuelle tilstand: Tillad alle.</span></li>
						<li><span>Dit samtykke-ID: wcEiIr2qUK1pE/kJZEv+FUg/Sc6/SEEd7rjubfIkTtOo8SsBZGQ5ig==</span></li>
						<li><span>Dato for samtykke: mandag den 28. november 2022 kl. 16.59.04 CET</span></li>
						</div>
						<div className="my-4"/>
						<h2>Cookies på www.konference.dk</h2>
						<p>Cookies er nødvendige for at få hjemmesiden til at fungere, men de giver også info om hvordan du bruger vores hjemmeside, så vi kan forbedre den både for dig og for andre.<br />
						Cookies på denne hjemmeside bruges primært til trafikmåling og optimering af sidens indhold.</p>
						<p>Hvis du klikker videre på siden, accepterer du vores brug af cookies.</p>
						<h3>Om cookies</h3>
						<p>Cookies er små datafiler med information som gemmes på din computer og sendes frem og tilbage mellem din browser og et website.<br />
						Nogle cookies er nødvendige for, at websites fungerer, mens andre bruges til at analysere din færden på et website.</p>
						<p>Nogle cookies slettes når du lukker din browser, nogle gemmes i en tidsbestemt periode og andre gemmes permanent. En cookie er ikke et program og indeholder ikke virus.<br />
						Det er lovpligtigt for alle danske hjemmesider at informere brugerne om cookies.</p>
						<p>Nedenfor kan du læse om de cookies vi bruger på konference.dk.</p>
						<h3>Google Analytics-cookies</h3>
						<p>Vi bruger Google Analytics til at indsamle viden om, hvordan vores brugere anvender vores website. Google Analytics har en cookie, som anonymt registrerer din IP-adresse samt information om antal og tidspunkt for dine besøg på vores website. Google Analytics registrerer også, hvilken side du besøgte før, f.eks. om du kom ind på siden via en søgemaskine. Alt dette gør vi for at optimere vores website og målrette vores markedsføring.<br />
						Opdateret d.&nbsp;19-08-2013</p>
						<h3>Markedsførings-cookies</h3>
						<p>Disse cookies bruges til at identificere dig via et tilfældigt genereret ID, når du går ind på en side, som du tidligere har besøgt, og når du bevæger dig rundt på siden. Det sker for, at du hurtigere kan navigere rundt mellem siderne. Cookies bruges også til at indsamle statistik om din færden på vores website for at give dig den information, som er mest relevant for dig, og forbedre vores markedsføring.<br />
						Opdateret d. 13-05-2013</p>
						<h3>Identifikations-cookies</h3>
						<p>Disse cookies bruges til at identificere dig via et tilfældigt genereret ID, når du går ind på websitet eller logger dig på en af vores tjenester. Vi bruger disse cookies til at huske, at du er logget ind, så du ikke bliver logget ud, når du bevæger dig rundt på siden.<br />
						Opdateret d. 13-05-2013</p>
						<h3>Fortryd accept af cookies</h3>
						<p>Hvis du ønsker at tilbagetrække din tilladelse til, at vi anvender cookies, skal du slette alle cookies relateret til websitet i din browser og fremover undlade at bruge siden.</p>
						<h3><b>Kontakt</b></h3>
						<p><span>Har du spørgsmål, kommentarer eller klager til optimeet.dk ApS’ behandling af personoplysninger kan du henvende dig til:</span></p>
						<p><span>optimeet.dk</span><br />
						<span>Flakhaven 1, 1.</span><br />
						<span>5000 Odense C</span><br />
						<span>Att: Customer Relationship Management</span></p>
						<p className="pb-5"><span>Eventuelle ændringer af persondatapolitikken meddeles ved offentliggørelse af nye vilkår og betingelser på optimeet.dk</span></p>
				</Col>
			</Row>
		</Container>
	);
}

const CookiesPage = () => {
	return (
		<div>
			<Header custom=" text-dark"/>
			<CookiesPageInner />
			<Footer />
		</div>
	);
}

export default CookiesPage;
