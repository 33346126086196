import React, { lazy } from 'react';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = lazy( () => import('react-quill') ); 

const RichTextEditor = ({fieldValue, update, height}) => {

	/* Customize Toolbar */
	const modules = {
	toolbar: [
			[{ 'header': [2, false] }],
			['bold', 'italic', 'underline'],
			[{'list': 'ordered'}, {'list': 'bullet'}],
			['link']
		],
	}

	/* Set allowed formats */
	const formats = [
		'header',
		'bold', 'italic', 'underline',
		'list', 'bullet', 'indent', 'link'
	];


	return <ReactQuill
		style={{
			height: height,
			display: "inline-block",
			width: "100%"
		}}
		className="text-weight-reg"
		theme="snow"
		value={fieldValue}
		onChange={(e) => update(e)}
		modules={modules}
		formats={formats}
	/>;
}

export default RichTextEditor;
