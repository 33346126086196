import React, { useContext, useEffect, useState} from "react";
import { Pagination, RenderIf } from "./Reusables";
import { Banners } from "./Banners";
import { ListCardList, VenueCard } from "./Cards";
import { Col, Container, Navbar, Row } from "reactstrap";
import { T } from "../traks";
import { FilteringContext } from "../FilteringContext";
import Map from "./Map";
import Kbutton from "./KButton";

export const MobileFilterHeader = (props) => {

    const data = props.data;
    const [mobileFilterMode, setMobileFilterMode] = useState(false);
    const [mobileMapMode, setMobileMapMode] = useState(false);

    const toggleFilters = () => {
        setMobileFilterMode(!mobileFilterMode);
        setHeight(mobileFilterMode ? "0px" : "100%");
    }

    const toggleMobileList = () => {
        setMobileFilterMode(false);
        setMobileMapMode(false);
        props.switchMode(false);
        setHeight("0px");
    }
    const toggleMobileMap = () => {
        setMobileFilterMode(false);
        setMobileMapMode(true);
        props.switchMode(true);
    }
    const [height, setHeight] = useState("0px");

    const iconMobileFilter = <img style={{ marginBottom: "3px", marginLeft: "0px" }} width={13} height={12} src="https://static.optimeet.dk/gfx/ico_filter-0.svg" alt='iconFilter' />
    const iconMobileMap = <img width={14} height={14} src="https://static.optimeet.dk/gfx/ico_map-0.svg" alt='iconMap' />
    const iconMobileList = <img style={{ marginBottom: "2px" }} width={12} height={10} src="https://static.optimeet.dk/gfx/ico_view_list-0.svg" alt='iconList' />
    const iconFilterClose = <img width={12} height={10} src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_close.svg" alt='iconList' />

    return (
        <Container fluid className="border-bottom d-xs-block d-xl-none px-0">
            {mobileFilterMode ?
                <div>
                    <Navbar className="d-flex justify-content-evenly text-center w-100 py-2">
                        <span className="col-5">
                            <button onClick={toggleMobileList} className="btn">
                                {iconFilterClose} <span><T>Søg</T></span>
                            </button>
                        </span>
                        <span className="vertline" />
                        <span className="col-5">
                            <button onClick={toggleMobileMap} className="btn">
                                {iconMobileMap} <span className="mx-2"><T>Kort</T></span>
                            </button>
                        </span>
                    </Navbar>
                </div>
                :
                <div>
                    {mobileMapMode ?
                        <div>
                            <Map suppliers={props.suppliers} data={data} customCss={"listingmap"} />
                            <span className="d-flex justify-content-between mobile_map_buttons mt-3">
                                <button onClick={toggleMobileList} className="btn k-btn-map ms-3 border-0 w-10">
                                    {iconMobileList} <span><T>List</T></span>
                                </button>
                                <button onClick={toggleFilters} className="btn k-btn-map border-0 w-10 me-3">
                                    {iconMobileFilter} <span><T>Filtre</T></span>
                                </button>
                            </span>
                        </div>
                        :
                        <div>
                            <span className="d-flex justify-content-evenly text-center w-100 py-2">
                                <span>
                                    <button onClick={toggleFilters} className="btn">
                                        {iconMobileFilter} <span><T>Filtre</T></span>
                                    </button>
                                </span>
                                <span className="vertline" />
                                <span>
                                    <button onClick={toggleMobileMap} className="btn">
                                        {iconMobileMap} <span className="mx-2"><T>Kort</T></span>
                                    </button>
                                </span>
                            </span>
                            {data && data !== undefined && <Cards suppliers={props.suppliers} banners={props.bannersData} data={data} resetFilters={props.resetFilters} locationsLength={props.locationsLength} />}
                            {data && data !== undefined && <PaginationComponent dataLength={data} locationsLength={props.locationsLength} />}
                        </div>
                    }
                </div>
            }
            <div style={{ height: height }} className="text-center">
                {props.children}
            </div>
            {mobileFilterMode &&
                <div className="col-12 text-center py-3 pb-4">
                    <Kbutton onClick={toggleMobileList} grey md>
                        {iconFilterClose} <span><T>Søg</T></span>
                    </Kbutton>
                </div>
            }
        </Container>
    );
}

export const AdsComponent = ({ banners }) => {

    return (
        <RenderIf condition={banners !== undefined}>
            <div className="w-100 d-flex justify-content-center py-5">
				<Banners skyScraper data={banners} />
            </div>
        </RenderIf>
    );
}

export const CardDisplay = (props) => {
    
    const highRatingData = props.data.filter(item => item.priority > 3);
    const lowRatingData = props.data.filter(item => item.priority <= 3);

    return (
        <>
            {props.data !== undefined && !!props.data.length && props.listMode ?
                <div>
                    <div className="d-none d-xl-block">
                        <RenderIf condition={highRatingData.length}>
                            <div className="mt-4 mb-2">
                                <span className="text-bold">{props.suppliers ? <T>Fremhævet leverandører</T> : <T>Fremhævet steder</T>}</span>
                                <span className="ps-1"><T>(der matcher din søgning)</T></span>
                            </div>
                        </RenderIf>
                        {highRatingData.map((v, i) => (
                            <Row key={i} xs={12} sm={12} md={6} lg={4} xl={3}>
                                <div className="py-2 w-100">
                                    <ListCardList
                                        data={v} suppliers={props.suppliers ? true : false} key={props.suppliers ? "supplier" : "venue" + i}
                                    />
                                </div>
                            </Row>
                        ))}
                    </div>
                    {!!highRatingData.length && <div style={{ borderTop: "1px solid #E6E8EC", marginBlock: 27 }}></div>}
                    <div className="d-none d-xl-block">
                        {lowRatingData.map((v, i) => (
                            <Row key={i} xs={12} sm={12} md={6} lg={4} xl={3}>
                                <div className="py-2 w-100">
                                    <ListCardList
                                        data={v} suppliers={props.suppliers ? true : false} key={props.suppliers ? "supplier" : "venue" + i}
                                    />
                                </div>
                            </Row>
                        ))}
                    </div>
                </div>
                :
                <div className="d-none d-xl-block">
                    <Row>
                        {props.data.map((v, i) => (
                            <Col key={i} xs={12} md={6} xl={4} xxl={4}>
                                <div className="py-2">
                                    <>
                                        <VenueCard
                                            border suppliers={props.suppliers ? true : false} data={v} key={props.suppliers ? "supplier" : "venue" + i}
                                        />
                                    </>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            }
        </>
    );
}

export const CardDisplayMobile = (props) => {
    return (
        <RenderIf condition={props.data.length}>
            <Row className="d-xs-flex d-xl-none">
                {props.data.map((v, i) => (
                    <Col key={i} xs={12} sm={6} md={4} lg={6} xl={3}>
                        <div className="d-flex justify-content-center py-2">
                            <>
                                <VenueCard
                                    border suppliers={props.suppliers ? true : false} data={v} key={props.suppliers ? "supplier" : "venue" + i}
                                />
                            </>
                        </div>
                    </Col>
                ))}
            </Row>
        </RenderIf>
    );
}

export const PaginationComponent = (props) => {

    const { filters, setFilters } = useContext(FilteringContext);

    const handlePage = (props) => {
        if (props === "next") {
            setFilters({ ...filters, page: filters.page + 1 });
        } else if (props === "previous") {
            setFilters({ ...filters, page: filters.page - 1 });
        } else {
            setFilters({ ...filters, page: props - 1 });
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            {props.locationsLength > 1 && <Pagination setPage={handlePage} page={filters.page + 1} totalPages={Math.ceil(props.locationsLength / 10)} />}
        </div>
    );
}

export const Cards = (props) => {

    const { filters, setFilters } = useContext(FilteringContext);
	const [data, setData] = useState(props.data);

    useEffect(() => {
		setData(props.data);
        // eslint-disable-next-line
    }, [props.data]);

    const clearFilters = () => {
        const updatedState = {
            ...filters,
            searchWord: '',
            location: '',
            type: '',
            //guest_range: '',
            price_range: '',
            facilities: '',
            certificates: '',
            page: 0,
        };
        setFilters(updatedState);
    };

    const [listMode, setListMode] = useState(true);
    const filterLength = props.suppliers ? Object.entries(filters.facilities).length || Object.entries(filters.location).length || (filters.price_range !== undefined && typeof filters.price_range.min === 'number') || Object.entries(filters.type).length || Object.entries(filters.searchWord).length :
        Object.entries(filters.facilities).length || Object.entries(filters.certificates).length || Object.entries(filters.location).length || (filters.price_range !== undefined && typeof filters.price_range.min === 'number') || Object.entries(filters.type).length || Object.entries(filters.searchWord).length /* || Object.entries(filters.guest_range).length */;
    const iconListActive = <img src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_view_list_active-0.svg" width={"34px"} height={"34px"} alt="icon-list-active" />;
    const iconListDisabled = <img src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_view_list_disabled-0.svg" width={"34px"} height={"34px"} alt="icon-list-active" />;
    const iconGridActive = <img src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_view_grid_active-0.svg" width={"34px"} height={"34px"} alt="icon-list-active" />;
    const iconGridDisabled = <img src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_view_grid_disabled-0.svg" width={"34px"} height={"34px"} alt="icon-list-active" />;

    /* 	const filterOptions = [
            {
                name: "Price",
                type: 1,
                dataName: "priceRating",
            },
            {
                name: "City",
                type: 0,
                dataName: "city",
            },
            {
                name: "Region",
                type: 0,
                dataName: "address_2",
            },
            {
                name: "Price Day",
                type: 3,
                dataName: "0",
            },
            {
                name: "Price Kursus",
                type: 3,
                dataName: "1",
            },
        ]; */

    return (
        <div className="container-fluid container-lg mb-3 px-2 px-md-0 pt-0 pt-md-3">
            <div className="mb-1 d-xl-block">
                <Banners data={props.banners} billBoard max sticky={undefined}/>
                {props.suppliers ? <h1 className="mt-5"><T>Søg blandt Møde- og Eventleverandører</T></h1> : <h1 className="mt-5"><T>Søg blandt mere end 190 steder</T></h1>}
                <div className="d-flex mt-3 opposites">
                    <span>
                        <span className="text-xl text-weight-reg grey"><span className="text-bold">{props.locationsLength}</span>&nbsp;<T>Resultater</T></span>
                        {filterLength !== 0 && <span onClick={() => { clearFilters() }} className="text-sm text-bold filterorange ps-3 pt-1 btn"><T>Ryd filter</T></span>}
                    </span>
                    <span style={{ display: "flex" }}>
                        <span className="d-none d-xl-flex align-items-center">
                            <div className="d-flex align-items-center me-2">
                                {listMode &&
                                    <>
                                        <div className="me-1 zoomable-image" onClick={() => { setListMode(true) }}>{iconListActive}</div>
                                        <div className="zoomable-image" onClick={() => { setListMode(false) }}>{iconGridDisabled}</div>
                                    </>
                                }
                                {!listMode &&
                                    <>
                                        <div className="me-1 zoomable-image" onClick={() => { setListMode(true) }}>{iconListDisabled}</div>
                                        <div className="zoomable-image" onClick={() => { setListMode(false) }}>{iconGridActive}</div>
                                    </>
                                }
                            </div>
                        </span>
                        {/*}
                        <CustomFilter
                            data={data}
                            isCleared={isCleared}
                            filterOptions={filterOptions}
                            setData={setData}
                        />
                        {*/}
                    </span>
                </div>
            </div>
            <CardDisplay suppliers={props.suppliers} data={data} listMode={listMode} />
            <CardDisplayMobile suppliers={props.suppliers} data={data} />
        </div>
    );
}

export default AdsComponent;