import React from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";

const TermsOfUseInner = () => {
	return (
		<Container>
			<Row>
				<Col className="privacy_page my-3">
					<h1>Brugsbetingelser</h1>
<p><i>Version 1.2 af 18. maj 2018</i></p>
<p>Det er gratis at anvende Optimeet.dk og/eller den personlige betjening hos Optimeets medarbejdere.</p>
<p>Det er også gratis for dig, når Optimeet placerer en ordre på dine vegne.</p>
<p><strong>Vi forpligter os til:</strong></p>
<p>Optimeet forpligter sig til at svare hurtigt, præcist og fyldestgørende på enhver forespørgsel.</p>
<p><strong>Vi beder dig om:</strong></p>
<p>Vi skal orienteres, hvis der forespørges hos andre bookingportaler/bookingkontorer på det samme arrangement.</p>
<p>Hurtigt svar os på vore tilbud til dit arrangement er vigtigt af hensyn til tilgængelighed hos leverandøren.</p>
<p><strong>Vær opmærksom på følgende:</strong></p>
<p>Optimeet er udelukkende formidler af handlen og kan ikke stilles til ansvar for leverancen på det valgte møde- og
konferencested.</p>
<p>Eventuelle reklamationer i forbindelse med et arrangement skal rettes direkte til møde- og konferencestedet, og
Optimeet kan ikke pålægges at tilbagebetale hverken helt eller delvist.</p>
<p>Vi kan ikke vide, med hvilke hoteller/kursuscentre din virksomhed har indgået specialaftaler. Vi opfordrer til at tjekke
med kolleger om I har særlige aftaler, således at en eventuel aftale overholdes.</p>
<p><strong>Persondata:</strong></p>
<p>Optimeet behandler dine data i henhold til den gældende lovgivning om persondata og har i den anledning truffet de
fornødne foranstaltninger for at overholde denne. Når du anvender systemet og f.eks. sender forespørgsler gennem det,
giver du os tilladelse til at behandle dine personoplysninger og kontakte dig på e-mail og telefon.</p>
<p>I forbindelse med forespørgsler og/eller booking af mødelokaler gør vi opmærksom på, at Optimeet betragter sig som
formidler af din henvendelse. Optimeet handler således ud fra din instruks. Det indebærer, at Optimeet og de steder du
vælger at sende forespørgslen til, alle er selvstændige dataansvarlige. Der er således et delt dataansvar, og de steder, du
vælger, vil behandle dine personoplysninger i henhold til deres egne persondatapolitikker.</p>
<p>Læs mere om Optimeets persondatapolitik og om dine rettigheder for sletning, berigtigelse og indsigt på følgende link: <a href="https://optimeet.dk/persondatapolitik/">Persondatapolitik | Beskyttelse af dine personlige oplysninger (optimeet.dk)</a></p>
				</Col>
			</Row>
		</Container>
	);
}

const TermsOfUse = () => {
	return (
		<div>
			<Header />
			<TermsOfUseInner />
			<Footer />
		</div>
	);
}

export default TermsOfUse;
