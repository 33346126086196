import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { TraksProvider } from './traks';
import { BrowserRouter } from "react-router-dom";
import { MeContext } from './MeContext';
import { KRoutes } from "./Routes";
import { RefreshSession } from './c/modals/LoginModal';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./css/konference.css";
import "leaflet/dist/leaflet.css";
import { APIGet } from "./API";
import { setLanguage } from "helpers/setLanguage";

const root = ReactDOM.createRoot(document.getElementById("root"));
APIGet('whoami')
	.then((whoami) => {
		if (whoami.data) {
			whoami = whoami.data;
		} else {
			whoami = null;
		}
		const App = (props) => {
			let [me, setMe] = useState(whoami);
			const lang = setLanguage()
			if (window.location.href === "http://localhost:63001/da") {
				window.location.href = "http://localhost:63001" 
			}
			if (!me) {
				RefreshSession(false);
			}

			return (
				<React.StrictMode>
					<TraksProvider lang={lang}>
						<MeContext.Provider value={{ me, setMe }}>
							<BrowserRouter>
								<KRoutes />
							</BrowserRouter>
						</MeContext.Provider>
					</TraksProvider>
				</React.StrictMode>
			);
		}
		// If you want to start measuring performance in your app, pass a function
		// to log results (for example: reportWebVitals(console.log))
		root.render(
			<App />
		)	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
		reportWebVitals();
	}).catch((err) => console.log(err));
