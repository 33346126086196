import { createContext } from 'react';

const FilteringContext = createContext({
	filter: {
		"searchWord": "",
		"location": [],
		"categories": [],
		"suppliercategories": [],
		"type": "",
		"certificates": [],
		"guests": [],
		"price": [],
		"facilities": [],
		"page": 0,
		"limit": 10,
	},
	setFilter: () => {},
});

export {
	FilteringContext,
};