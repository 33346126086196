import React, { useState, useEffect } from "react";
import { Collapse, Input } from "reactstrap";
import Kbutton from './KButton';
import { VenueImg, PriceInfo } from "./Reusables";
import GalleryModal from "./modals/GalleryModal";
import InquiryModal from "./modals/InquiryModal";
import Map from "./Map";
import { T, TraksConsumer } from "../traks";
import isYouTubeURL from "./functions/isYouTubeURL";

export const MapAddress = (props) => {

	const venueData = props.data;

	const iconPhone = <img width={17} height={17} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_phone-0.svg" alt='iconPhone' />
	const iconMail = <img width={17} height={13} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_mail-0.svg" alt='iconMail' />
	const iconWebsite = <img width={17} height={13} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_link-0.svg" alt='iconWebsite' />

	return (
		<div className="container px-0">
			<div  id="scroll_map_mobile"  className="find-location mt-5 mb-5 py-5 px-4">
				<div className="mb-3">
					<h5><T>Find vej</T></h5>
				</div>
				{venueData.geoLatLong !== undefined && venueData.geoLatLong.lon &&
					<div className="d-block d-lg-flex">
						<Map data={venueData} customCss={"venuemap"} />
						<div className="d-flex flex-column w-100">
							<div className="ms-0 ms-lg-4 mt-2 mt-lg-0 mb-0 mb-lg-4 w-100 find-location-text p-4 pt-2">
								<h5 className="mt-4">{venueData.title}</h5>
								<div>
									<div className="mt-3">
										{venueData.address_1}
									</div>
									<div>
										<span className="pe-1">
											{venueData.zipcode}&nbsp;-&nbsp;
										</span>
										<span>
											{venueData.city}
										</span>
									</div>
									<div className="mt-3">
										{venueData.address_2}
									</div>
								</div>
							</div>
							<div className="pt-3 d-flex flex-column ms-4 w-100">
								<Kbutton icon={iconPhone} copyable title={venueData.phoneNumber} onClick={() => navigator.clipboard.writeText(venueData.phoneNumber)} grey custom=" btn text-black py-2 px-3 me-2" />
								<Kbutton icon={iconMail} copyable title={venueData.email} onClick={() => navigator.clipboard.writeText(venueData.email)} grey custom=" btn text-black py-2 px-3 me-2 my-2" />
								<Kbutton icon={iconWebsite} onClick={()=> window.open(venueData.website, "_blank")} title={"Besøg hjemmeside"} grey custom=" btn text-black py-2 px-3 me-2" />
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export const VenueHeader = (props) => {

	const data = props.data;
	const iconShare = <img width={16} height={20} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_share-0.svg" alt='iconShare' />;

	return (
		<div className="container">
			<div className="d-none d-xl-block">
				<div className="d-flex flex-column-reverse flex-lg-row pt-4">
					<div>
						<h1 className="mt-4">{data.title}</h1>
						<div>
							{(data.rating || data.reviews || data.priceRating !== '') &&
							<span className="font_13px text-bold ms-1 mb-1">
								{(data.rating && data.reviews) &&
								<span>
									<img className="mb-1 mx-1" width={17} height={17} src="https://static.optimeet.dk/gfx/ico_star-fill-0.svg" alt='iconstar' />
									<span className="text-md">{data.rating}</span>
									<span className="text-md grey ms-2">({data.reviews}&nbsp;<T>reviews</T>)</span>
								</span>
								}
{/* 								{data.priceRating !== '' && //! debug
									<span className="text-md grey ms-2"><T>Pris niveau</T>
										<span className="ms-1"><Dollars count={data.priceRating} /></span>
									</span>
									} */}
							</span>
							}
						</div>
					</div>
				</div>
				<div className="mt-2 opposites pb-2">
					<span>
						<img className="mb-1 me-2" width={12} height={16} src="https://static.optimeet.dk/gfx/ico_maplocation-1.svg" alt='iconmap' />
						<span className="text-md mt-1">{data.address_1},&nbsp;{data.city},&nbsp;{data.zipcode},&nbsp;{data.address_2}</span>
						<button onClick={() => document.getElementById('scroll_map').scrollIntoView({ behavior: "smooth" })} className="k-btn-greybg btn py-2 px-3 ms-3"><T>Vis på kort</T></button>
					</span>
					<div className="d-none">
						<span>
							<Kbutton icon={iconShare} lightgrey custom=" text-black btn py-2 px-3 me-2"><T>Del</T></Kbutton>
						</span>
					</div>
				</div>
			</div>
			<div className="d-xs-block d-xl-none pt-4">
				<div className="d-flex flex-row">
					<span className="pt-2">
						<h2>{data.title}</h2>
					</span>
				</div>
				<div className="font_13px pt-2 mb-1">
					{(data.rating && data.reviews) &&
					<span>
						<img className="mb-1" width={17} height={17} src="https://static.optimeet.dk/gfx/ico_star-fill-0.svg" alt='iconstar' />
						<span className="text-md text-bold">{data.rating}</span>
						<span className="text-md grey ms-2">({data.reviews}&nbsp;<T>reviews</T>)</span>
						<span className="text-md grey ms-3 me-2"><T>Pris niveau</T></span>
					</span>}
{/* 					{data.priceRating && <Dollars count={data.priceRating} />} */}
					<div className="pt-1">
						<img className="mb-1 me-2" width={12} height={16} src="https://static.optimeet.dk/gfx/ico_maplocation-1.svg" alt='iconmap' />
						<span className="text-md mt-1">{data.address_1},&nbsp;{data.city},&nbsp;{data.zipcode},&nbsp;{data.address_2}</span>
					</div>
					<a href="#scroll_map_mobile">
						<button className="k-btn-greybg btn py-2 mt-3 w-100"><T>Vis på kort</T></button>
					</a>
				</div>
			</div>
		</div>
	);
}

export const VenueGallery = (props) => {

	const data = props.data;
	const iconGalleryBlack = <img width={16} height={20} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_images-black-0.svg" alt='iconGalleryBlack' />;

	const MobileGallery = () => {

		const [imgIndex, setImgIndex] = useState(0);
		let imgLength = data.imagesUrls.length - 1;

		const imageButtonClick = (props) => {
			if ((props && imgIndex === imgLength) || (!props && imgIndex === 0)) {
				setImgIndex(props ? 0 : imgLength);
			}
			else {
				setImgIndex(props ? imgIndex + 1 : imgIndex - 1);
			}
		}

		return (
			<div>
				<div className="position-relative">
					<VenueImg supplier={props.supplier} full_size className="pt-1 gallery_mobile_img" img={data.imagesUrls[imgIndex] ? data.imagesUrls[imgIndex] : null} alt="mobilegallery" />
					<div className="mobile_gallery_buttons_wrapper">
						<div onClick={() => imageButtonClick(true)}><img className="mobile_gallery_rbtn btn" src="https://static.optimeet.dk/gfx/ico_btn-left.svg" alt="btnleft" /></div>
						<div onClick={() => imageButtonClick(false)}><img className="mobile_gallery_lbtn btn" src="https://static.optimeet.dk/gfx/ico_btn-right.svg" alt="btnright" /></div>
					</div>
				</div>
			</div>
		);
	}

	let buttonText = <T>Vis alle {props.data.imagesUrls.length} billeder</T>;
	if (isYouTubeURL(data.videoLink)) {
		buttonText = <T>Vis video og alle {props.data.imagesUrls.length} billeder</T>;
	}
	return (
		<div className="container">
			<div className="d-flex flex-column flex-lg-row d-none d-xl-flex pt-4">
				<div className="position-relative">
					{/*} hide until image is correct
					{data.iconUrl &&
						<div className="venuecard_profile_image p-1 d-flex align-items-center shadow-soft" style={{backgroundColor: "#fff", borderRadius: 100, overflow: "hidden", left: 10}}>
							<img width="100%" src={props.data.iconUrl} alt="iconimg" />
						</div>
					}
				{*/}
					<GalleryModal supplier={props.supplier} images={data.imagesUrls} videoLink={data.videoLink}>
						<VenueImg supplier={props.supplier} cover className="gallery_thumb_large" img={data.imagesUrls[0]} alt="thumblarge" />
					</GalleryModal>
					<GalleryModal images={data.imagesUrls} videoLink={data.videoLink}>
						{isYouTubeURL(data.videoLink) && (
							<div class="position-absolute top-50 start-50 translate-middle child">
								<img src='https://static.optimeet.dk/gfx/play-video-button-red-1.svg' alt='playvideo' style={{'width': '100px'}}/>
							</div>
						)}
						<div className="thumb_large_btn_position hoverfx">
							<Kbutton icon={iconGalleryBlack} whitebg sm noborder>{buttonText}</Kbutton>
						</div>
					</GalleryModal>
				</div>
				<GalleryModal supplier={props.supplier} images={data.imagesUrls}>
					<div className="d-flex flex-column gallery_container_inner">
						<div className="d-flex gallery_container_section mb-1">
							<div className="gallery_thumb_small_wrapper">
								{data.imagesUrls[1] && <VenueImg supplier={props.supplier} card className="gallery_thumb_small" img={data.imagesUrls[1] ? data.imagesUrls[1] : null} id={1} alt="thumbsmall1" />}
							</div>
							<div className="gallery_thumb_small_wrapper">
								{data.imagesUrls[2] && <VenueImg supplier={props.supplier} card className="gallery_thumb_small" img={data.imagesUrls[2] ? data.imagesUrls[2] : null} id={2} alt="thumbsmall2" />}
							</div>
						</div>
						<div className="d-flex gallery_container_section mt-1">
							<div className="gallery_thumb_small_wrapper">
								{data.imagesUrls[3] && <VenueImg supplier={props.supplier} card className="gallery_thumb_small" img={data.imagesUrls[3] ? data.imagesUrls[3] : null} id={3} alt="thumbsmall3" />}
							</div>
							<div className="gallery_thumb_small_wrapper">
								{data.imagesUrls[4] && <VenueImg supplier={props.supplier} card className="gallery_thumb_small" img={data.imagesUrls[4] ? data.imagesUrls[4] : null} id={4} alt="thumbsmall4" />}
							</div>
						</div>
					</div>
				</GalleryModal>
			</div>
			<div className="d-flex d-xs-block d-xl-none pt-3">
				<MobileGallery />
			</div>
		</div>
	);
}

export const VenueDescription = (props) => {

	const data = props.data;
	const [index, setIndex] = useState(3);

	useEffect(() => {
		if (index === 0 ) {
			document.getElementById('scroll_description').scrollIntoView({ behavior: "smooth"});
		}
		if (index === 1 ) {
			document.getElementById('scroll_capacity').scrollIntoView({ behavior: "smooth"});
		}
		if (index === 2 ) {
			document.getElementById('scroll_facilities').scrollIntoView({ behavior: "smooth"});
		}
	}, [index]);

	const [showMore, setShowMore] = useState(false);
	const toggle = () => {
		setShowMore(!showMore);
	}

	return (
		<div id="scroll_description" className="container px-0 pe-lg-5">
			<div className="d-none d-xl-block pt-4 mb-3 font-primary">
				<button className="btn_radio zoomable-image" style={{paddingLeft: 0}} onClick={() => setIndex(0)} disabled={index === 0 || index === 3}>
					<T>Beskrivelse</T>
				</button>
				{!props.supplier &&
					<>
						<button className="btn_radio zoomable-image" onClick={() => setIndex(1)} disabled={index === 1}>
							<T>Kapacitet</T>
						</button>
						<button className="btn_radio zoomable-image" onClick={() => setIndex(2)} disabled={index === 2}>
							<T>Faciliteter</T>
						</button>
					</>
				}
				<div  className="underline"></div>
			</div>
			{data.description && data.description.length < 1950 ?
				<div className="pt-2 grey text-md text-lineheight-24 text-weight-reg">
					<span dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g,"<br />") }} />
				</div>
			:
			<div>
				<Collapse className="pt-3 grey text-md text-lineheight-24 text-weight-reg" isOpen={!showMore}>
					{data.description && <span dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g,"<br />").substring(0, 1950) + "..." }} />}
				</Collapse>
				<Collapse className="pt-3 grey text-md text-lineheight-24 text-weight-reg" isOpen={showMore}>
					{data.description && <span dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g,"<br />") }} />}
				</Collapse>
				{ showMore ? null :
				<div className="pt-4 btn px-0 mx-0 font_weight_700 font-primary" onClick={toggle}>
					<T>Læs mere</T><img alt="caret" width="10" height="10" src={"https://static.optimeet.dk/gfx/ico_dropdown_toggle_open.svg"} className="ms-1 mt-1 pb-1" />
				</div>}
			</div>}
			{!props.supplier &&
				<div className="d-none d-xl-block">
					<TraksConsumer>
						<CapacityFacilites data={data}/>
					</TraksConsumer>
				</div>
			}
			<div className="mt-4"/>
		</div>
	);
}

export const CapacityFacilites = (props) => {

	const data = props.data;

	const FilteredVal = ({ props }) => {
		const filtered = data.capacities.find(obj => { return obj.name === props });
		return (
			<span className="text-md text-weight-med py-1">
				{filtered && filtered.value ?
					<span>{filtered.value}</span>
					:
					<span>N/A</span>
				}
			</span>
		);
	};

	return (
		<div>
			<div id="scroll_capacity" className="pt-5">
				<h5><T>Kapacitet</T></h5>
				<div className="pt-2 pb-4 grey text-md text-lineheight-24 text-weight-reg">
					<span dangerouslySetInnerHTML={{ __html: data.capacityDescription }} />
				</div>
			</div>
			<div className="greyoutline p-4">
				<div className="pb-4 text-md text-bold"><T>Kapacitet</T></div>
				<div className="mt-2 opposites underline">
					<span className="text-md text-weight-reg py-1"><T>Max Kapacitet (bio)</T></span>
					<span className="text-md text-weight-med py-1"><FilteredVal props='capacity' /></span>
				</div>
				<div className="mt-2 opposites underline">
					<span className="text-md text-weight-reg py-1"><T>Max. pers. i auditorium</T></span>
					<span className="text-md text-weight-med py-1"><FilteredVal props='max_people_auditoriums' /></span>
				</div>
				<div className="mt-2 opposites underline">
					<span className="text-md text-weight-reg py-1"><T>Max. pers. ved skoleborde</T></span>
					<span className="text-md text-weight-med py-1"><FilteredVal props='schoolTables' /></span>
				</div>
				<div className="mt-2 opposites underline">
					<span className="text-md text-weight-reg py-1"><T>Max. pers. i U-bord</T></span>
					<span className="text-md text-weight-med py-1"><FilteredVal props='u-tables' /></span>
				</div>
				<div className="mt-2 opposites underline">
					<span className="text-md text-weight-reg py-1"><T>Max. pers. i ø-opstilling á 6-8 personer</T></span>
					<span className="text-md text-weight-med py-1"><FilteredVal props='setup' /></span>
				</div>
				<div className="mt-2 opposites">
					<span className="text-md text-weight-reg py-1"><T>Max. pers. ved stående reception</T></span>
					<span className="text-md text-weight-med py-1"><FilteredVal props='reception' /></span>
				</div>
			</div>
			<div id="scroll_facilities" className="pt-5">
				<h5><T>Faciliteter og aktiviteter</T></h5>
				<FacilityCheckbox data={data} />
			</div>
		</div>
	);
};

export const FacilityCheckbox = (props) => {

	const facilityFilter = (fd) => {
		const matchedValues = fd.map((facilitiesData) => {
			return (
				<div className="checkbox_container d-flex" key={facilitiesData.value}>
					<img alt="checkbox" style={{ backgroundColor:"#242624", padding:"4px", borderRadius:"5px", marginRight:"6px" }} width={"20px"} height={"20px"} src="https://static.optimeet.dk/gfx/ico_checkmark_light.svg" />
					<span className="text-md text-weight-reg">{facilitiesData.name}</span>
				</div>
			);
		});

		return [...matchedValues];
	};

	return <div className="pt-2">{facilityFilter(props.data.facilities)}</div>;
};

export const VenueInquire = (props) => {

	const [startDate, setStartDate] = useState('');
	const [participants, setParticipants] = useState('');
	const filters = {
 		"venueId": props.data ? props.data.id : '',
		"startDate": startDate,
		"participants": participants,
	};

	const iconInfo = <img width={16} height={16} className="mb-1 me-2 ms-1" src="https://static.optimeet.dk/gfx/ico_info_white-0.svg" alt='iconinfo' />

	return (
		<div>
			{props.data &&
				<div className="container venue_rightpanel_scale p-4 mt-4 mb-3 mg-sm-0 shadow-hard booking-box">
					<div>
						<h5 className="text-center mt-1 text-white"><T>Send en forespørgsel</T></h5>
						<div className="underline mt-4 mb-4" style={{opacity: 0.2}}/>
						<div className="py-2">
							<div className="dropdownlist-calendar hoverfxlight w-100">
								<Input
									placeholder={"Skriv dato for event"}
									onChange={(e) => setStartDate(e.target.value)}
									className="text-weight-reg bg-white"
									name="date"
									id="date"
									form="selector"
									value={filters.startDate}
								/>
							</div>
						</div>
						<div className="mt-2 dropdownlist-calendar hoverfxlight w-100">
							<Input
								placeholder={"Angiv antal gæster"}
								onChange={(e) => setParticipants(e.target.value)}
								className="text-weight-reg bg-white"
								name="date"
								id="date"
								form="selector"
								value={filters.participants}
							/>
						</div>
						<div className="d-flex flex-row pt-4">
							{iconInfo}
							<p className="text-white text-sm ps-1"><T>Du får lynhurtigt svar på din henvendelse. Vi sidder klar til at yde gratis råd og vejledning.</T></p>
						</div>
						<div className="text-center pt-3 pb-2">
							<InquiryModal supplier={props.supplier} filters={filters} modalTitle={"Uforpligtende forespørgsel på " + props.data.title}>
								<Kbutton custom="px-5 py-3 w-100 hoverfxlight" sec md><span style={{fontSize: 18}}><T>Udfyld forespørgsel</T></span></Kbutton>
							</InquiryModal>
						</div>
					</div>
				</div>
			}
		</div>
	);
};

export const Bookings = (props) => {

	const data = props.data;
	const [product, setProduct] = useState(null);
	const [price, setPrice] = useState(null);

	const filters = {
		"venueId": props.data ? props.data.id : '',
		"product": product,
		"price": price,
	};

	const setThings = (i) => {
		if (data[i] !== undefined) {
			setPrice(data[i].price);
			setProduct(data[i].name);
		}
	};

	return (
		<div>
			{data["products"] &&
				<InquiryModal filters={filters} modalTitle={"Uforpligtende forespørgsel på " + props.data.title} ignoreClick={"iconinfo"}>
					<div className="venue_rightpanel_scale mb-3 px-4 pt-4 pb-4">
						<div className="text-center text-md text-bold mb-4">{props.supplier ? <T>Service</T> : <T>Book mødested</T>}</div>
						{data.products[0] && data.products[0].price &&
						<div onClick={() => setThings(data.products[0])} className="mt-3 cursor_pointer hoverfxlight">
							<div className="opposites popularbooking_bg py-3 px-4 position-relative">
								<span className="text-weight-reg"><T>Dagsmøde</T></span>
								<span className="text-bold me-3">{data.products[0].price}&nbsp;{data.currency}</span>
								<span style={{position: "absolute", right: 10}}><PriceInfo DayMeet /></span>
							</div>
						</div>
						}
						{data.products[1] && data.products[1].price &&
							<div onClick={() => setThings(data.products[1])} className="mt-3 cursor_pointer hoverfxlight">
								<div className="opposites popularbooking_bg py-3 px-4 position-relative">
									<span className="text-weight-reg"><T>Kursusdøgn</T></span>
									<span className="text-bold me-3">{data.products[1].price}&nbsp;{data.currency}</span>
									<span style={{position: "absolute", right: 10}}><PriceInfo GroupMeet /></span>
								</div>
							</div>
						}
		{/* 				<div className="text-center text-md mt-4">
							<span className="text-center btn"><T>Se alle priser</T></span>
						</div> */}
					</div>
				</InquiryModal>
			}
		</div>
	);
};

export const Information = (props) => {

	const data = props.data.capacities;

	const FilteredVal = (props) => {
		const filtered = data.find(obj => {
			return obj.name === props.name;
		});
		return (
			<span className="text-md text-weight-med text-bold py-1">
				{filtered && filtered.value ?
					<div className="mt-3 opposites pb-1 underline">
						{props.children}
						<span>{filtered.value}</span>
					</div>
					:
					null
				}
			</span>
		);
	};

	return (
		<div className="mt-3">
			<div className="venue_rightpanel_scale px-4 pt-4 booking-information">
				<div className="text-center text-md text-bold mt-1 mb-3"><T>Information</T></div>
				<div className="py-3 px-2 mb-3">
					<FilteredVal name='participants'>
						<span>
							<img width={16} height={12} className="mb-1 me-2" src="https://static.optimeet.dk/gfx/ico_persons-0.svg" alt='icopeople' />
							<span className="text-md text-weight-reg py-1"><T>Deltagere</T></span>
						</span>
					</FilteredVal>
					<FilteredVal name='rooms'>
						<span>
							<img width={17} height={12} className="mb-1 me-2" src="https://static.optimeet.dk/gfx/ico_bed-0.svg" alt='icobed' />
							<span className="text-md text-weight-reg py-1"><T>Værelser</T></span>
						</span>
					</FilteredVal>
					<FilteredVal name='eatingPlaces'>
						<span>
							<img width={17} height={14} className="mb-1 me-2" src="https://static.optimeet.dk/gfx/ico_food-0.svg" alt='icoutensils' />
							<span className="text-md text-weight-reg py-1"><T>Spisepladser</T></span>
						</span>
					</FilteredVal>
					<div className="mt-2 opposites underline">
						<span>
							<img width={17} height={14} className="mb-1 me-2" src="https://static.optimeet.dk/gfx/ico_handicap-0.svg" alt='icowheelchair' />
							<span className="text-md text-weight-reg py-1"><T>Handikapvenlig</T></span>
						</span>
						<span className="text-md text-bold py-1">{props.data.handicapFriendly ? <T>Ja</T> : <T>Nej</T>}</span>
					</div>
					<FilteredVal name='meetingLocations'>
						<span>
							<img width={17} height={14} className="mb-1 me-2" src="https://static.optimeet.dk/gfx/ico_handshake-0.svg" alt='icoutensils' />
							<span className="text-md text-weight-reg py-1"><T>Mødelokaler</T></span>
						</span>
					</FilteredVal>
					<FilteredVal name='auditoriums'>
						<span>
							<img width={17} height={12} className="mb-1 me-2" src="https://static.optimeet.dk/gfx/ico_auditorium-0.svg" alt='icoauditorium' />
							<span className="text-md text-weight-reg py-1"><T>Auditorier</T></span>
						</span>
					</FilteredVal>
				</div>
			</div>
		</div>
	);
};

export const EnvironmentCert = (props) => {

	const certs = props.data.certificates;
	return (
		<div>
			{!!props.data.certificates.length &&
				<div className="mt-3">
					<div className="venue_rightpanel_scale bg-white px-4 py-4">
						<div className="text-center text-md text-bold mt-1 mb-3"><T>Miljømærkninger</T></div>
						<span>
							{certs.map((cert, i) => (
								<img className="me-2 rounded-circle" width={60} height={60} key={i} src={cert.imageUrl} alt={cert.key} />
							))}
						</span>
					</div>
				</div>}
		</div>
	);
};

export const DollHouse = (props) => {

	const height = window.innerWidth <= 768 ? "100%" : "624vh";
	const bg = window.innerWidth <= 768 ? "p-0 p-lg-4" : "p-0 p-lg-4 find-location";
	const [showMore, setShowMore] = useState(false);
	const toggle = () => {
		setShowMore(!showMore);
	}

	return (
		<div className="container px-0 py-5">
			<div className="pt-2 d-none d-lg-block">
				<Collapse className={bg} isOpen={showMore}>
					<div>
						<iframe
							title="3D venue"
							src={props.data.dollHouseUrl}
							width="100%"
							height={height}
							style={{borderRadius:"12px"}}
						/>
					</div>
				</Collapse>
				{showMore ? null :
					<div className="text-center position-relative venue-3d-bg d-flex align-items-center justify-content-center">
						<Kbutton prim lg onClick={toggle}>Vis venue i 3D</Kbutton>
					</div>}
				</div>
			<div className="pt-2 d-block d-lg-none text-center position-relative venue-3d-bg d-flex align-items-center justify-content-center">
				<Kbutton icon={props.iconLink} prim lg onClick={()=> window.open(props.data.dollHouseUrl, "_blank")}>
					Vis venue i 3D
				</Kbutton>
			</div>
		</div>
	);
};
