import React from "react";

const FixedContactButton = () => {
	return (
		<div className="position-fixed" style={{bottom: 10, right: 10}}>
			<a href="tel:+4533974343">
				<button className="k-btn k-btn-sec btn-width py-2 mt-3 mb-1 k-btn-large px-4 text-lg pop-up-button" style={{height: 50}}>
					<div className="d-flex">
						<div className="me-2">
							<div>
								<img src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_phone-white-0.svg" width={"17px"} height={"17px"} alt="phone-icon" />
							</div>
						</div>
						<div>+45 33 97 43 43</div>
					</div>
				</button>
			</a>
		</div>
	);
}

export default FixedContactButton;