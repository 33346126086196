import React from "react";
import { T } from '../traks';
import SliderCategory from "./SliderCategory";

const SliderSelector = () => {

	const ico2 = <img width={86} height={72} src="https://static.optimeet.dk/categorythumbs/2.svg" alt='icoMødelokaler' />;
	const ico3 = <img width={86} height={72} src="https://static.optimeet.dk/categorythumbs/3.svg" alt='Kursuscenter' />;
	const ico5 = <img width={86} height={72} src="https://static.optimeet.dk/categorythumbs/5.svg" alt='Konferencehotel' />;
	const ico6 = <img width={86} height={72} src="https://static.optimeet.dk/categorythumbs/6.svg" alt='Messecenter' />;
	const ico7 = <img width={86} height={72} src="https://static.optimeet.dk/categorythumbs/7.svg" alt='Kursuslokaler' />;
	const ico8 = <img width={86} height={72} src="https://static.optimeet.dk/categorythumbs/8.svg" alt='Kursuslokaler' />;
	const ico9 = <img width={86} height={72} src="https://static.optimeet.dk/categorythumbs/9.svg" alt='Kursuslokaler' />;

	return (
		<div className="container-fluid pt-4 pt-md-1 px-0">
			<div className="container-sm text-start text-md-center">
				<h2 className="mt-4"><T>Hvad leder du efter?</T></h2>
				<span className="centerComponent">
					<p style={{maxWidth: 610}} className="grey text-xl text-weight-reg mt-2 lead"><T>Find det perfekte møde- og konferencested</T></p>
				</span>
				<span className="centerComponent">
					<p style={{maxWidth: 610}} className="grey text-xl text-weight-reg lead"><T>Bliv inspireret af vores forskellige typer afholdelsessteder</T></p>
				</span>
			</div>
			<div>
				<div className="d-flex pt-3 pb-3 px-0 ps-1 ps-md-0 mx-auto justify-content-center" style={{maxWidth: 1620}}>
					<div className="mx-0 mx-md-3 pt-2 category-slider scrolling-wrapper-flexbox scroll-smooth" style={{maxWidth: 1540}}>
						<SliderCategory icon={ico5} urlName="konferencecenter"><T>Konferencecentre</T></SliderCategory>
						<SliderCategory icon={ico3} urlName="moedelokale"><T>Mødelokaler</T></SliderCategory> 
						<SliderCategory icon={ico6} urlName="kursuscenter"><T>Kursuscenter</T></SliderCategory>
						<SliderCategory icon={ico7} urlName="konferencelokale"><T>Konferencelokaler</T></SliderCategory>
						<SliderCategory icon={ico8} urlName="konferencehotel"><T>Konferencehotel</T></SliderCategory>
						<SliderCategory icon={ico9} urlName="messecenter"><T>Messecenter</T></SliderCategory>
						<SliderCategory icon={ico2} urlName="kursuslokale"><T>Kursuslokaler</T></SliderCategory>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SliderSelector;