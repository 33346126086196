import React from "react";
import { Container, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useState } from "react";
import Kbutton from "./KButton";
import { LL } from "Routes";
import { T } from "../traks";
import { APIPost } from "API";
import { AlertModal } from "./Reusables";

const BookingNewsletters = (props) => {
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })
	const [planner, setPlanner] = useState({ name: "", email: "", phone: "", company: "", job_title: "", city: "", country: "", zip_code: "" })
	const [deliverGuy, setDeliverGuy] = useState({ name: "", email: "", phone: "", company: "", job_title: "", city: "", country: "", zip_code: "" })

	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(!showModal);

	const [toggleForm, setToggleForm] = useState(false);

	const HandleToggleFormPlanner = () => {
		if (toggleForm) {
			setToggleForm(!toggleForm);
		}  
	}

	const HandleToggleFormSupplier = () => {
		if (!toggleForm) {
			setToggleForm(!toggleForm);
		}  
	}

	const NewsletterConfirm = (props) => {
		return (
			<div>
				<Modal isOpen={props.openModal} toggle={props.toggleModal}>
					<ModalBody className="py-4 text-center">
						<T>Tak for din tilmelding!</T>
					</ModalBody>
					<ModalFooter>
						<Kbutton custom="mx-5 px-5 py-2 w-100 mt-1" prim md onClick={props.toggleModal}><T>Luk vindue</T></Kbutton>
					</ModalFooter>
				</Modal>
			</div>
		);
	}

	const SignupForNewsLetter = (type) => {
		const resource = "emaillist"
		const listToken = type === "planner" ? "6067de320a" : "1c115d4d80"
		const contact = type === "planner" ? planner : deliverGuy
		const body = {
			contact: contact,
			list_token: listToken
		}

		APIPost(resource, body).then(res => {
			setShowModal(true)
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true)
		})
	}

	const NameText = props.render_static(<T>name</T>).toUpperCase()
	const TitleText = props.render_static(<T>titel</T>).toUpperCase()
	const EmailText = props.render_static(<T>email</T>).toUpperCase()
	const CompanyText = props.render_static(<T>firma</T>).toUpperCase()
	const PhoneText = props.render_static(<T>telefon nr</T>).toUpperCase()
	const CountryText = props.render_static(<T>land</T>).toUpperCase()
	const ZipcodeText = props.render_static(<T>postnummer</T>).toUpperCase()

	const ToggleButtons = () => {

		return (
			<div className="w-100 d-flex align-items-center justify-content-center mb-4">
				<div className="border p-2 rounded-pill">
					<button onClick={HandleToggleFormPlanner} className={`btn rounded-pill me-1 p-3 ${!toggleForm ? "btn-secondary" : ""}`}>For planlæggere</button>
					<button onClick={HandleToggleFormSupplier} className={`btn rounded-pill ms-1 p-3 ${toggleForm ? "btn-secondary" : ""}`}>For leverandører</button>
				</div>
			</div>
		)
	}

	return (
		<>
			<Container className="mt-0 mt-lg-5 pt-3 pt-lg-5 pb-0 pb-lg-3 text-center">
				<h1><T>Tilmeld nyhedsbrev</T></h1>
				<p><T>Tilmeld dig Optimeet News og modtag:</T></p>
				<ul className="text-start d-flex flex-column mt-5 mb-5" style={{ maxWidth: 700, margin: "auto" }}>
					<li><T>Ugentlig nyhedsmail med sidste nyt fra møde- og eventbranchen samt faglig inspiration til dine møder og konferencer</T></li>
					<li><T>Invitationer til gratis inspirationsarrangementer, workshops og messer</T></li>
					<li><T>Særtilbud og nyheder fra leverandører i møde- og eventbranchen</T></li>
				</ul>
				<p><T>Sådan håndterer Optimeet</T> <a href={LL("/persondatapolitik")}><T>din data</T></a></p>
			</Container>
			<ToggleButtons />
			<div className="d-flex justify-content-center align-items-center flex-column flex-lg-row mx-2">
				{!toggleForm &&
					<div className="w-100" style={{ maxWidth: 600 }}>
					<div className="mx-2 mx-lg-0 greyoutline py-5 px-3 px-lg-5 mb-4">
						<div className="text-center">
							<h5><T>Tilmeldingsformular</T> (<T>Planlægger</T>)</h5>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{NameText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setPlanner({ ...planner, name: e.target.value })}
								value={planner.name}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{TitleText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setPlanner({ ...planner, job_title: e.target.value })}
								value={planner.job_title}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{EmailText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setPlanner({ ...planner, email: e.target.value })}
								value={planner.email}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{CompanyText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setPlanner({ ...planner, company: e.target.value })}
								value={planner.company}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{PhoneText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setPlanner({ ...planner, phone: e.target.value })}
								value={planner.phone}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{ZipcodeText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setPlanner({ ...planner, zip_code: e.target.value })}
								value={planner.zip_code}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{CountryText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setPlanner({ ...planner, country: e.target.value })}
								value={planner.country}
							/>
						</div>
						<Kbutton custom="px-5 py-3 w-100 mt-4" prim md onClick={() => SignupForNewsLetter("planner")}><T>Tilmeld nyhedsbrev</T></Kbutton>
					</div>
				</div>
				}
				{toggleForm &&
					<div className="w-100" style={{ maxWidth: 600 }}>
					<div className="mx-2 mx-lg-0 greyoutline py-5 px-3 px-lg-5 mb-4">
						<div className="text-center">
							<h5><T>Tilmelding</T> (<T>Leverandør</T>)</h5>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{NameText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setDeliverGuy({ ...deliverGuy, name: e.target.value })}
								value={deliverGuy.name}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{TitleText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setDeliverGuy({ ...deliverGuy, job_title: e.target.value })}
								value={deliverGuy.job_title}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{EmailText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setDeliverGuy({ ...deliverGuy, email: e.target.value })}
								value={deliverGuy.email}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{CompanyText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setDeliverGuy({ ...deliverGuy, company: e.target.value })}
								value={deliverGuy.company}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{PhoneText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setDeliverGuy({ ...deliverGuy, phone: e.target.value })}
								value={deliverGuy.phone}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{ZipcodeText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setDeliverGuy({ ...deliverGuy, zip_code: e.target.value })}
								value={deliverGuy.zip_code}
							/>
						</div>
						<div className="w-100">
							<p className="text-sm pt-3">
								{CountryText}
							</p>
							<Input
								className="py-3 text-weight-reg"
								onChange={(e) => setDeliverGuy({ ...deliverGuy, country: e.target.value })}
								value={deliverGuy.country}
							/>
						</div>
						<Kbutton custom="px-5 py-3 w-100 mt-4" prim md onClick={() => SignupForNewsLetter("deliver")}><T>Tilmeld nyhedsbrev</T></Kbutton>
					</div>
				</div>
				}
				
			</div>
			<NewsletterConfirm openModal={showModal} toggleModal={toggleModal} />
			<div className="mb-5 pb-4 text-sm text-center">
				<a href="/"><T>Tilbage til forsiden</T></a>
			</div>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</>
	);
}

export default BookingNewsletters;
