import React, { useEffect, useState, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import { Input } from "reactstrap";
import { FilteringContext } from './../FilteringContext';
import Kbutton from './KButton';
import LDropDown from "./LDropdown";
import KCheckbox from "./KCheckbox";
import Map from "./Map";
import { T, TraksConsumer } from "../traks";
import { APIPost } from "API";
import { AlertModal, RenderIf, HorizontalSlider, /* CustomFilter */ } from "./Reusables";
import Footer from "./Footer";
import { Loading } from "./Loading";
import axios from "axios";
import { AdsComponent, PaginationComponent, MobileFilterHeader, Cards } from "./SharedListingComponents";

const ListingComponent = () => {
    
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });

    const [locationsData, setLocationsData] = useState(undefined);
    const [locationsLength, setLocationsLength] = useState(undefined);
    const [banners, setBanners] = useState(undefined);

    const [modeMap, setModeMap] = useState(false);
    const switchMode = (props) => {
        !props ? setFilters({ ...filters, limit: 10 }) : setFilters({ ...filters, limit: 300 });
        setModeMap(props);
    }

    const [filters, setFilters] = useState({
        "searchWord": "",
        "location": [],
        "type": "",
        "certificates": [],
        "price_range": {
            "min": '',
            "max": ''
        },
        "facilities": [],
        "page": 0,
        "limit": 10,
    });

    let params = {
        limit: modeMap ? 300 : filters.limit,
        page: modeMap ? 0 : filters.page,
        "listing_keys_only": true,
    };

    function handleQuery(query) {
        if (query.query_string) params["query_string"] = query.query_string;
        if (query.location.length) params["locationIds"] = [query.location];
        if (query.facilities[0]) params["facilityIds"] = query.facilities;
        if (query.type.length) params["categoryIds"] = [query.type];
        if (query.certificates.length) params["certificateIds"] = query.certificates;
/*         if (query.guest_range && typeof query.guest_range.min === 'number') {
            params["capacities"] = query.guest_range;
        } */
        if (query.price_range && typeof query.price_range.min === 'number') {
            params["price_range"] = query.price_range;
        }
        doQuery(params);
    }

    function doQuery(query) {
        setShowLoading(true);
        const body = { query, includeBanners: true };
        APIPost("search", body).then(res => {
            setLocationsLength(res.data.total.value);
            setLocationsData(res.data.result);
            setBanners(res.data.banners);
        }).catch(err => {
            setAlertType({ type: "error", text: err.response.data })
            setShowAlert(true);
            setShowLoading(false)
        });
    }

    const [filtersData, setFiltersData] = useState(undefined);
    useEffect(() => {
	console.log(window.location.origin)
	const filterUrl = window.location.origin.includes("https://optimeet.dk") ? "https://static.optimeet.dk/stats/filters.json" : "https://static.optimeet.dk/stats/test-filters.json"
        if (!filtersData)
            axios.get(filterUrl).then(res => {
                setFiltersData(res.data);
            }).catch(err => setAlertType({ type: "error", text: err.response.data }))
        const handleBeforeUnload = () => {
            //sessionStorage.removeItem('OptimeetSearchFilters');
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        // eslint-disable-next-line
    }, []);

    const [mobileMode] = useState(window.innerWidth < 992 ? true : false);
    const containerCss = modeMap ? "container-fluid" : "container"

    const FooterMemo = useMemo(() => <Footer />, []);

    return (
        <div>
            {filtersData !== undefined &&
                <div className={modeMap && !mobileMode ? "listing-map-container" : ""}>
                    <FilteringContext.Provider value={{ filters, setFilters }}>
                        <div className={containerCss + " px-0 d-lg-flex flex-column-reverse flex-lg-row pt-0 position-relative overflow-hidden"}>
                            <div className="d-lg-none">
                                <MobileFilterHeader data={locationsData} locationsLength={locationsLength} switchMode={switchMode}>
                                    <TraksConsumer>
                                        <Filtering filtersData={filtersData} sendQuery={handleQuery} mobileMode={true} />
                                    </TraksConsumer>
                                </MobileFilterHeader>
                            </div>
                            <div style={modeMap ? { width: "450px", background: "#fff", height: "100vh", overflowY: "auto" } : { width: "450px", background: "#fff" }} className="d-none d-lg-block">
                                <div className="filter-container pt-4" style={modeMap ? { marginTop: 95, marginBottom: 50 } : null}>
                                    <TraksConsumer>
                                        <Filtering filtersData={filtersData} sendQuery={handleQuery} switchMode={switchMode} mobileMode={false} />
                                    </TraksConsumer>
                                </div>
                                {modeMap ? null : <AdsComponent banners={banners} />}
                            </div>
                            <div className="w-100 d-none d-lg-block">
                                <RenderIf condition={locationsData === undefined}>
                                    <Loading
                                        showLoading={showLoading}
                                        inFront
                                    />
                                </RenderIf>
                                <RenderIf condition={locationsData !== undefined}>
                                    {modeMap && !mobileMode ?
                                        <span className="d-none d-sm-block">
                                            <Map data={locationsData} customCss={"listingmap"} />
                                        </span>
                                        :
                                        <span>
                                            <Cards banners={banners} data={locationsData} locationsLength={locationsLength} />
                                            <PaginationComponent dataLength={locationsData} locationsLength={locationsLength} />
                                        </span>
                                    }
                                </RenderIf>
                            </div>
                        </div>
                        {modeMap ? null : FooterMemo}
                    </FilteringContext.Provider>
                    <AlertModal
                        isShowing={showAlert}
                        setIsShowing={setShowAlert}
                        type={alertType.type}
                        text={alertType.text}
                    />
                </div>}
        </div>
    );
}

const Filtering = (props, { urlParams }) => {

    const { filters, setFilters } = useContext(FilteringContext);
    urlParams = useParams();
    const [defaultSelect, setDefaultSelect] = useState(false);
    const [urlSearch, setUrlSearch] = useState(true);
    const [preCheckedBoxesFacilities, setPreCheckedBoxesFacilities] = useState('');
    const [preCheckedBoxesCerts, setPreCheckedBoxesCerts] = useState('');
    const [savedPrice, setSavedPrice] = useState(['']);
    //const [savedGuests, setSavedGuests] = useState(['']);

    const [dropdownPlaceholder, setDropdownPlaceholder] = useState({
        "loc": "",
        "cat": "",
    });

    useEffect(() => {
        if ((props.mobileMode && window.innerWidth < 992) || (!props.mobileMode && window.innerWidth > 992)) {
            setUrlSearch(false);
            const savedFilters = sessionStorage.getItem('OptimeetSearchFilters');
            if (savedFilters && props.filtersData !== undefined) {
                setDefaultSelect(true);
                const parsedFilters = JSON.parse(sessionStorage.getItem('OptimeetSearchFilters'));
                if (parsedFilters.searchWord !== "") {
                    setSearchText(parsedFilters.searchWord);
                }
                if ((parsedFilters.location.length !== 0 && parsedFilters.location !== "") || (parsedFilters.type.length && parsedFilters.type !== "")) {
                    let filterValType = props.filtersData.categories.filter((item) =>
                        item.id === parsedFilters.type
                    );
                    let filterValLoc = props.filtersData.locations.filter((item) =>
                        item.id === parsedFilters.location
                    );
                    setDropdownPlaceholder({
                        ...dropdownPlaceholder,
                        loc: parsedFilters.location !== "" && filterValLoc[0] !== undefined ? filterValLoc[0].name : dropdownPlaceholder.loc,
                        cat: parsedFilters.type !== "" && filterValType[0] !== undefined ? filterValType[0].name : dropdownPlaceholder.cat
                    });
                }
                if (parsedFilters.facilities !== "") {
                    setPreCheckedBoxesFacilities(parsedFilters.facilities);
                }
                if (parsedFilters.certificates !== "") {
                    setPreCheckedBoxesCerts(parsedFilters.certificates);
                }
                if (parsedFilters.price_range !== "") {
                    setSavedPrice(parsedFilters.price_range);
                }
                /*  if (parsedFilters.guest_range !== "") {
                    setSavedGuests(parsedFilters.guest_range);
                } */
                let limitFilter = (JSON.parse(savedFilters));
                limitFilter.limit = 10;
                setFilters(limitFilter);
                return;
            }
            else if (!defaultSelect && props.filtersData !== undefined && urlParams.criteria !== undefined) {
                let arg = urlParams.criteria.toLowerCase();
                let filtereVal;
                filtereVal = props.filtersData.locations.filter((item) =>
                    item.key.replace(/-/g, '').toLowerCase() === arg.replace(/-/g, '').toLowerCase()
                );
                if (filtereVal.length === 1) {
                    setDefaultSelect(filtereVal[0].name);
                    setDropdownPlaceholder({ ...dropdownPlaceholder, loc: filtereVal[0].name });
                    setFilters({ ...filters, location: filtereVal[0].id });
                } else {
                    filtereVal = props.filtersData.categories.filter((item) =>
                        item.key.toLowerCase() === arg.toLowerCase()
                    );
                    if (filtereVal.length === 1) {
                        setDefaultSelect(filtereVal[0].name);
                        setDropdownPlaceholder({ ...dropdownPlaceholder, cat: filtereVal[0].name });
                        setFilters({ ...filters, type: filtereVal[0].id });
                    } else {
                        setDefaultSelect(true);
                        handleFilters();
                    }
                }
                return;
            }
            else {
                setDefaultSelect(true);
                handleFilters();
            }
        }
        // eslint-disable-next-line
    }, []);

    const params = {
        limit: filters.limit,
        listing_keys_only: true,
        query_string: filters.searchWord,
        location: filters.location,
        type: filters.type,
        certificates: filters.certificates,
        //guest_range: filters.guest_range,
        price_range: filters.price_range,
        facilities: filters.facilities,
    };

    const [searchText, setSearchText] = useState('');
    useEffect(() => {
        const searchDelay = setTimeout(() => {
            if (searchText && searchText === originalSearch.current) {
                setFilters({ ...filters, searchWord: searchText, page: 0 });
            } else if (defaultSelect && searchText === '') {
                filters.searchWord !== '' && setFilters({ ...filters, searchWord: '', page: 0 });
            }
        }, 700);
        return () => clearTimeout(searchDelay);
        // eslint-disable-next-line
    }, [searchText]);

	const originalSearch = React.useRef("");
	const handleSearchTextChange = (e) => {
		originalSearch.current = e.target.value.replace(/[^a-zA-Z0-9æøåÆØÅ\s]/g, '');
		setSearchText(e.target.value);
	};

    const [isCleared, setIsCleared] = useState(true);

    const handleFilters = () => {
        props.sendQuery(params);
        if (filters.searchWord === "" && filters.certificates === "" && filters.location === "" && filters.type === "" && /* filters.guest_range === "" && */ filters.price_range === "" && filters.facilities === "") {
            setSearchText("");
            setIsCleared(!isCleared);
            setDropdownPlaceholder({ ...dropdownPlaceholder, cat: "", loc: "" });
        } else if (defaultSelect) {
            setPreCheckedBoxesFacilities('');
            setPreCheckedBoxesCerts('');
        }
    };

    useEffect(() => {
        if (!urlSearch) {
            handleFilters();
            sessionStorage.setItem('OptimeetSearchFilters', JSON.stringify(filters));
        }
        // eslint-disable-next-line
    }, [filters]);

    const [modeMap, setModeMap] = useState(false);
    const toggleModeMap = () => {
        props.switchMode(!modeMap);
        setModeMap(!modeMap);
    };

    const iconFilter = <img style={{ marginBottom: "3px", marginInlineEnd: "8px" }} width={13} height={12} src="https://static.optimeet.dk/gfx/ico_filter-0.svg" alt='iconFilter' />
    const iconMap = <img style={{ marginBottom: "5px", marginInlineEnd: "8px" }} width={14} height={14} src="https://static.optimeet.dk/gfx/ico_map-0.svg" alt='iconMap' />

    return (
        <div>{defaultSelect && props.filtersData !== undefined &&
            <div className="ms-4 me-4">
                <div className="pb-4 mt-4 mt-lg-0">
                    <div className="mb-4 d-none d-lg-block">
                        {modeMap ?
                            <Kbutton onClick={toggleModeMap} icon={iconFilter} noborder custom=" px-3 py-2"><T>Liste</T></Kbutton>
                            :
                            <div onClick={toggleModeMap} className="map_bg_img zoomable-image text-center cursor_pointer py-5 d-flex align-items-center justify-content-center">
                                <Kbutton icon={iconMap} md whitebg noborder custom="px-3 py-2 hoverfxlight"><T>Kort</T></Kbutton>
                            </div>
                        }
                    </div>
                    <Input
                        type="kform"
                        className="py-2 hoverfxlight text-weight-reg search_bg_transparent w-100"
                        placeholder={props.render_static(<T>Søg på mødested</T>)}
                        onChange={handleSearchTextChange}
                        name="search"
                        id="search"
                        value={searchText}
                    />
                </div>
                <div className="text-lg font-primary"><T>Lokation</T></div>
                <div className="py-2 w-100 px-0">
                    <LDropDown
                        resettable={<T>Alle regioner</T>}
                        data={props.filtersData.locations.sort((a, b) => a.name.localeCompare(b.name))}
                        placeholder={dropdownPlaceholder.loc.length ? dropdownPlaceholder.loc : props.render_static(<T>Alle regioner</T>)}
                        height={50}
                        defaultVal={isCleared}
                        setVal={(clickData) => {
                            setFilters({ ...filters, location: clickData, page: 0 });
                        }}
                    />
                </div>
                <div className="text-lg pt-3 font-primary"><T>Mødestedstype</T></div>
                <div className="py-2">
                    <LDropDown
                        resettable={<T>Alle mødestedstyper</T>}
                        data={props.filtersData.categories}
                        placeholder={dropdownPlaceholder.cat.length ? dropdownPlaceholder.cat : props.render_static(<T>Alle mødestedstyper</T>)}
                        height={50}
                        defaultVal={isCleared}
                        setVal={(clickData) => {
                            setFilters({ ...filters, type: clickData, page: 0 });
                        }}
                    />
                </div>
                <div className="text-lg py-3 font-primary"><T>Prisniveau</T></div>
                <div className="pb-3">
                    <HorizontalSlider
                        min={0}
                        max={2500}
                        onChange={({ min, max }) => setFilters({ ...filters, price_range: { min: min, max: max } })}
                        unit=".kr"
                        savedValues={savedPrice}
                        reset={isCleared}
                    />
                </div>
                {/* 				<div className="text-lg py-3 font-primary"><T>Antal gæster</T></div>
 				<div className="pb-3">
					<HorizontalSlider
						min={1}
						max={2000}
						onChange={({ min, max }) => setFilters({ ...filters, guest_range: { min: min, max: max } })}
						unit="gæster"
						savedValues={savedGuests}
						reset={isCleared}
					/>
				</div> */}
                <div className="text-lg pt-4 pb-2 font-primary"><T>Faciliteter og aktiviteter</T></div>
                <div style={{ overflow: 'scroll', height: '385px', overflowX: 'hidden', }}>
                    <KCheckbox
                        preCheckedBoxes={preCheckedBoxesFacilities}
                        defaultVal={isCleared}
                        showAll={true}
                        data={props.filtersData.facilities}
                        setVal={(clickData) => {
                            if (filters.facilities.includes(clickData)) {
                                setFilters({ ...filters, facilities: filters.facilities.filter(i => i !== clickData), page: 0 });
                            } else {
                                setFilters({ ...filters, facilities: [...filters.facilities, clickData], page: 0 });
                            }
                        }}
                    />
                </div>
                <div className="text-lg pt-4 pb-2 font-primary"><T>Miljømærkninger</T></div>
                <div className="mb-3 mb-lg-0">
                    <KCheckbox
                        preCheckedBoxes={preCheckedBoxesCerts}
                        defaultVal={isCleared}
                        data={props.filtersData.certificates}
                        setVal={(clickData) => {
                            if (filters.certificates.includes(clickData)) {
                                setFilters({ ...filters, certificates: filters.certificates.filter(i => i !== clickData), page: 0 });
                            } else {
                                setFilters({ ...filters, certificates: [...filters.certificates, clickData], page: 0 });
                            }
                        }}
                        showAll={true}
                    />
                </div>
            </div>
        }</div>
    );
}

export default ListingComponent;
