import React from "react";

const SliderLandingCategory = ({ img, title, urlSlug, location = undefined, shortDescription = undefined }) => {

    const handleClick = () => {
        if (location) {
            window.location.href = ("/konferencesteder/lokationer/" + urlSlug)
        }
        else {
            window.location.href = ("/konferencesteder/kategorier/" + urlSlug)
        };
    };

    return  (
        <div className="text-center" onClick={() => handleClick()} >
            <img src={img} alt={title} className="img-fluid category_landing btn hover-effect m-2 p-1" width="190" height="245" />
            <div className="container" style={{ maxWidth: "190px" }}>
                <div className="text-weight-reg text-bold">
                    {title}
                </div>
                <div className="text-sm grey mt-2">
                    {shortDescription && shortDescription}
                </div>
            </div>
        </div>
    );
}

export default SliderLandingCategory;