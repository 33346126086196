import React from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import { T } from "../traks";
import FixedContactButton from "c/FixedContactButton";
import { TeamList } from "c/Cards";

const AboutUsInner = () => {

	const heroImg = "https://static.optimeet.dk/imgs/aboutus-1920x890px.webp";

	const employees = [
		{
			"id": "1",
			"name": "Ole Sorang",
			"jobTitle": "Adm. Direktør",
			"phone": "29 28 79 55",
			"email": "olso@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/ole-sorang-a326824/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_ole_sorang_300x420-0.webp"
		},
		{
			"id": "2",
			"name": "Jacob Nedergaard",
			"jobTitle": "COO Exhibitions & Events ",
			"phone": "38 14 60 43",
			"email": "jne@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/jacob-nedergaard-40559a1/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_jacob_nedergaard_300x420-1.webp"
		},
		{
			"id": "3",
			"name": "Søren Hansen",
			"jobTitle": "Head of Sales & Booking",
			"phone": "31 53 22 05",
			"email": "sha@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/s%C3%B8ren-hansen-3579066/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_soeren_hansen_300x420-1.webp"
		},
		{
			"id": "4",
			"name": "Charlotte Jørgensen",
			"jobTitle": "Key Account Manager",
			"phone": "38 14 60 45",
			"email": "cj@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/charlotte-j%C3%B8rgensen-bb54bb32/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_charlotte_joergensen_300x420-1.webp"
		},
		{
			"id": "5",
			"name": "Line Tange",
			"jobTitle": "Account Manager",
			"phone": "38 14 60 42",
			"email": "lita@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/linetange/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_line_tange_300x346-0.webp"
		},
		{
			"id": "6",
			"name": "Kathrine Henriksen",
			"jobTitle": "Konference & Booking Manager",
			"phone": "88 77 97 89",
			"email": "kahe@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/kathrinesverdruphenriksen/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_kathrine_henriksen_300x420-1.webp"
		},
		{
			"id": "7",
			"name": "Carsten Hartkopf",
			"jobTitle": "Konference & Booking Manager",
			"phone": "88 77 97 80",
			"email": "caha@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/carsten-hartkopf-2123b017/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_carsten_300x420px-2.webp"
		},
		{
			"id": "8",
			"name": "Sandra Bergstrøm",
			"jobTitle": "Head of Relations & Events",
			"phone": "38 14 60 41",
			"email": "sb@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/sandrabergstroem/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_sandra_bergstroem_300x420-1.webp"
		},
		{
			"id": "9",
			"name": "Pia Dewachter",
			"jobTitle": "Coordinator, Relations & Events",
			"phone": "38 14 60 40",
			"email": "pd@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/pia-dewachter-b5b6abb/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_pia_dewachter_300x420-1.webp"
		},
		{
			"id": "10",
			"name": "Alexander Holde",
			"jobTitle": "Marketing Manager",
			"phone": "60 13 76 72 ",
			"email": "alho@opusgroup.dk",
			"linkedIn": "https://www.linkedin.com/in/alexander-holde-larsen-520aa9203/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_alexander_300x420px-2.webp"
		},
		{
			"id": "11",
			"name": "Jan Aagaard",
			"jobTitle": "Chefredaktør",
			"phone": "60 15 58 06",
			"email": "ja@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/janaagaarddk/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_jan_aagaard_300x420-0.jpg"
		},
		{
			"id": "12",
			"name": "Isabell Strouhal",
			"jobTitle": "Studerende",
			"phone": "38 14 60 47",
			"email": "isst@optimeet.dk",
			"linkedIn": "https://www.linkedin.com/in/isabell-strouhal-bba502204/",
			"imageUrl": "https://static.optimeet.dk/imgs/team_isabell_300x420px-2.webp"
		},
		{
			"id": "13",
			"name": "Helle Steenholdt",
			"jobTitle": "Praktikant",
			"phone": "",
			"email": "hels@optimeet.dk",
			"linkedIn": "",
			"imageUrl": ""
		},
	];

	return (
		<Container>
			<Row style={{paddingBottom: "100px"}}>
				<Col className="pt-5">
					<img alt="hero" className="mb-5" src={heroImg} style={{width: "100%", borderRadius: 10}}/>
					<div className="whitebox fade-up">
						<h1><T>Om os</T></h1>
						<T>Optimeet har siden 1972 udviklet og udgivet værktøjer til møde-, kursus- og kongresplanlæggere. Værktøjerne består af magasiner, websites, messer og arrangementer, hvor mødeplanlæggere gratis kan finde gode råd, tips og inspiration.</T>
						<h4 className="mt-5 text-bold">Teamet bag Optimeet</h4>
						<div className="mt-5">
							<TeamList col={2} data={employees} />
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

const AboutUs = () => {
	return (
		<div>
			<Header />
			<AboutUsInner />
			<Footer />
			<FixedContactButton />
		</div>
	);
}

export default AboutUs;
