import React, { useState, useEffect } from "react";

export const Banners = ({billBoard = undefined, skyScraper = undefined, className="", data, sticky, max = undefined}) => {
	
	const bannerData = data ? data : undefined;
	const stickyBanner = sticky ? " sticky-top" : null;
	const maxWidth = max ? " 100%" : " 970px";

	const [rndSkyscraperBanner, setRndSkyscraperBanner] = useState(undefined);
	const [rndBillboarBanner, setRndBillboardBanner] = useState(undefined);

	useEffect(() => {
		const visibleData = bannerData && bannerData.filter(item => item.visible === true);
		if (visibleData && !rndSkyscraperBanner) {
			const skyscraperBanners = visibleData && visibleData.filter(item => item.banner_type === "skyscraper");
			const billboardBanners = visibleData && visibleData.filter(item => item.banner_type === "billboard");
			const randomIndex1 =  Math.floor(Math.random() * skyscraperBanners.length);
			setRndSkyscraperBanner(skyscraperBanners[randomIndex1]);
			const randomIndex2 =  Math.floor(Math.random() * billboardBanners.length);
			setRndBillboardBanner(billboardBanners[randomIndex2]);
		}
		// eslint-disable-next-line
	}, [bannerData])

	const SelectedBanner = () => {
		if (rndBillboarBanner && billBoard) {
			return (
				<a href={rndBillboarBanner.link} target="_blank" rel="noopener noreferrer">
					<img className="hoverfxbright" src={rndBillboarBanner.image_url} alt="bannerImg" style={{ width: "100%", maxWidth: maxWidth }} />
				</a>
			);
		};
		if (rndSkyscraperBanner && skyScraper) {
			return (
				<a href={rndSkyscraperBanner.link} target="_blank" rel="noopener noreferrer">
					<img className="hoverfxbright" src={rndSkyscraperBanner.image_url} alt="bannerImg"/>
				</a>
			)
		};
	};
	
	return (
		<>
		{ rndSkyscraperBanner === undefined &&
			<div className={className}></div>
		}
		{ rndSkyscraperBanner &&
			<div className={className + stickyBanner}>
				<SelectedBanner />
			</div>
		}
		</>
	);
};