import React, { useEffect, useState, useContext } from "react";
import axios from "axios"
import { useParams } from "react-router-dom";
import "../../css/dashboard.css";
import { APIGet, APIPost } from "API";
import { T } from "../../traks";
import { MeContext } from '../../MeContext';
import { DataContext } from '../../DataContext';
import NoAccess from "c/admin/NoAccess";
import { AlertModal, RenderIf } from "c/Reusables";
import { Loading } from "c/Loading";
import { EditVenue } from "./EditVenue.js";
import { DashboardWrapper } from "../../c/admin/DashboardComponents";
import fetchImgsWithRetry from "../../c/functions/fetchImgsWithRetry";

export const EditVenuePage = ({ urlParams }) => {
	urlParams = useParams();
	const { me } = useContext(MeContext);
	const Id = urlParams.venueId;
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });
	const [showLoading, setShowLoading] = useState(false);
	const [data, setData] = useState(undefined);
	const [noVenue, setNoVenue] = useState(false);
	const [partners, setPartners] = useState([]);
	const [certificates, setCertificates] = useState([]);
	const [locations, setLocations] = useState([]);
	const [categories, setCategories] = useState([]);
	const [facilities, setFacilities] = useState([]);

	useEffect(() => {
		setShowLoading(true);
		APIGet("venues/" + Id).then(res => {
			const { venue } = res.data;
			setData(venue);
			setPartners(res.data.users);
			setCertificates(res.data.certificates);
			setLocations(res.data.locations);
			setCategories(res.data.categories);
			setFacilities(res.data.facilities);
			setShowLoading(false);
		}).catch(err => {
			setShowLoading(false);
			setNoVenue(true);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
		// eslint-disable-next-line
	}, []);

	const uploadLogo = (files) => {
		setShowLoading(true);
		const filesInfo = files.map(({ file, ...f }) => f);
		APIPost("venues/" + Id + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const file = files[0];
			const preSignedUrl = res.data.upload_urls[0];
			const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
			return axios.put(preSignedUrl, file.file, { headers }).then(res => {
				const newFileName = preSignedUrl.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk");
				setData({ ...data, iconUrl: newFileName });
				saveVenue({ "iconUrl": newFileName })
				setShowLoading(true);
				return newFileName;
			}).catch(err => console.log(err))
		}).catch(err => console.log(err))
	};

	const uploadFiles = (files) => {
		setShowLoading(true)
		const filesInfo = files.map(({ file, ...f }) => f)
		//get presigned urls for upload
		APIPost("venues/" + Id + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const preSignedUrls = res.data.upload_urls;
			//upload each file to the upload folder
			const promises = preSignedUrls.map((url, index) => {
				const file = files[index];
				const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
				return axios.put(url, file.file, { headers }).then(res => {
					const newFileName = url.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk")
					return newFileName
				}).catch(err => console.log(err))
			})
			// at this point we have the new temporary upload images
			Promise.allSettled(promises).then(res => {
				const newImagesUrls = res.map(el => el["value"]);
				const totalNewImageArr = (data["imagesUrls"] || []).concat(newImagesUrls);
				const resource = "venues/" + Id + "/images";
				APIPost(resource, { "imagesUrls": totalNewImageArr }).then(res => {
					fetchImgsWithRetry(totalNewImageArr.length, "venues/" + Id, "venue", setData, setShowLoading);
				}).catch(err => console.log(err));
			}).catch(err => console.log(err));
		}).catch(err => console.log(err));
	};

	const saveVenue = (newVenue = null) => {
		if (!showLoading) {
			setShowLoading(true);
			let body = !newVenue ? data : newVenue;
			!body["priceRating"] && delete body["priceRating"];
			APIPost("venues/" + Id, body).then(res => {
				setData(res.data.edited_venue);
				setShowLoading(false);
				setAlertType({ type: "success", text: "Venue saved" });
				setShowAlert(true);
			}).catch(err => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		};
	}


	if (!me) {
		return (
			<DashboardWrapper index={2}>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!Id) {
		return (
			<DashboardWrapper index={2}>
				<span><T>No venue found</T></span>
			</DashboardWrapper>
		)
	}

	if (data === undefined) {
		return (<>
			<DashboardWrapper index={2}>
				<Loading
					showLoading={showLoading}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
				<RenderIf condition={noVenue}>
					<span><T>No venue found</T></span>
				</RenderIf>
			</DashboardWrapper>
		</>)
	}

	return (
		<DashboardWrapper index={2}>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<Loading
				showLoading={showLoading}
				inFront
				overlay
			/>
			<DataContext.Provider value={{ data, setData, partners, setPartners, Id, certificates, facilities, locations, categories }}>
				<EditVenue
					venue={data}
					saveVenue={saveVenue}
					uploadLogo={uploadLogo}
					uploadFiles={uploadFiles}
				/>
			</DataContext.Provider>
		</DashboardWrapper>
	);
};
