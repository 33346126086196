import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MeContext } from '../MeContext';
import { APIGet, APIPost, APIDelete } from "API";
import { Advanced, DashboardWrapper } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import CreateButton from "c/CreateButton";
import Sidebar from "c/admin/Sidebar";
import { AlertModal, RenderIf } from "c/Reusables";
import NoAccess from "c/admin/NoAccess";
import { T } from "../traks";
import { Loading } from "c/Loading";
import { LL } from "Routes";
import EditFAQ from "../c/EditFAQ";
import axios from "axios";
import LandingToggleVisibility from "../c/admin/landingcomponents/LandingToggleVisibility";
import LandingTexts from "../c/admin/landingcomponents/LandingTexts";
import LandingImage from "../c/admin/landingcomponents/LandingImage";
import LandingSaveDelete from "../c/admin/landingcomponents/LandingSaveDelete";
import SetVenuesComponent from "../c/admin/landingcomponents/SetVenuesComponent";

const EditLandingRegion = ({ urlParams }) => {

	const { me } = useContext(MeContext);
	const navigate = useNavigate();
	urlParams = useParams();
	const isAdmin = me && me.user.roles.includes("admin");
	const landingId = urlParams.landingRegionId;

	const [title, setTitle] = useState(undefined);
	const [faq, setFaq] = useState(undefined);

	const [pageContent, setPageContent] = useState({
		"imagesUrls": "",
		"shortDescription": "",
		"description": "",
		"faq": faq,
		"venues1": [],
		"venues1Title": "",
		"venues2": [],
		"venues2Title": "",
		"venues3": [],
		"venues3Title": "",
	});

	const [landingPage, setLandingPage] = useState({
		createdTs: 0,
		id: "",
		pageType: "",
		published: false,
		title: title,
		updatedTs: 0,
		urlSlug: "",
		pageContent: pageContent,
	});

	useEffect(() => {
		faq && setPageContent(prevPageContent => ({
		  	...prevPageContent,
		  	faq: faq
		}));
	}, [faq]);

	useEffect(() => {
		setShowLoading(true);
		if (landingId) {
			APIGet("editlandingpage/" + landingId).then(res => {
				setShowLoading(false);
				setTitle(res.data.landing_page.title);
				res.data.landing_page && setLandingPage(res.data.landing_page);
				res.data.landing_page.pageContent && setPageContent(res.data.landing_page.pageContent);
				res.data.landing_page.pageContent.faq && setFaq(res.data.landing_page.pageContent.faq);
				if (!res.data) {
					navigate(LL("/landingregions/"));
				}
			}).catch(err => {
				err.response && setAlertType({ type: "error", text: err.response.data });
				err.response && setShowAlert(true);
			})
		} else {
			setAlertType({ type: "error", text: "No landing region exists with id" });
		}
		// eslint-disable-next-line
	}, []);

	const saveLandingRegion = () => {
		setShowLoading(true);
		landingPage.title = title;
		landingPage.pageContent = pageContent;
		APIPost("editlandingpage/" + landingId, landingPage ).then(res => {
			setShowLoading(false);
			setAlertType({ type: "success", text: "Landing region saved" });
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	const deleteLandingRegion = () => {
		setShowLoading(true);
		const resource = "editlandingpage/" + landingId;
		APIDelete(resource).then(res => {
			navigate(LL("/editlandingregions/"));
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	const [files, setFiles] = useState([])

    const deleteImage = () => {
        setFiles([]);
        setPageContent({ ...pageContent, imagesUrls: "" });
        saveLandingRegion();
    }

    const handleUploadImage = () => {
        uploadFiles(files);
        setFiles([]);
    }

	const uploadFiles = (files) => {
		setShowLoading(true)
		const filesInfo = files.map(({ file, ...f }) => f)
		//get presigned urls for upload
		APIPost("editlandingpage/" + landingId + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const preSignedUrls = res.data.upload_urls;
			//upload each file to the upload folder
			const promises = preSignedUrls.map((url, index) => {
				const file = files[index];
				const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
				return axios.put(url, file.file, { headers }).then(res => {
					const newFileName = url.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk")
					return newFileName
				}).catch(err => console.log(err))
			})
			// at this point we have the new temporary upload images
			Promise.allSettled(promises).then(res => {
				const newImagesUrls = res.map(el => el["value"]);
				const resource = "editlandingpage/" + landingId + "/images";
				APIPost(resource, { "imagesUrls": newImagesUrls }).then(res => {
					setPageContent(prevState => ({
						...prevState, imagesUrls: (prevState["imagesUrls"] || []).concat(newImagesUrls)
					}));
					setTimeout(() => {
						saveLandingRegion();
						setShowLoading(false);
					}, 1000);
				}).catch(err => console.log(err));
			}).catch(err => console.log(err));
		}).catch(err => console.log(err));
	};

	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	if (!isAdmin) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!landingPage) {
		return (
			<DashboardWrapper>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	return (
		<div className="dashboard mb-5">
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<CreateButton />
			<Sidebar index={10} />
			<RenderIf condition={landingPage && pageContent && isAdmin && !showLoading}>
				<div className="dashboard-wrapper">
					<h5 className="mb-3"><T>Rediger landing region</T></h5>
					<Notification><T>Du redigerer</T>:&nbsp;{title}</Notification>
					<LandingSaveDelete saveLandingCategory={saveLandingRegion} deleteLandingCategory={deleteLandingRegion} />
					<LandingToggleVisibility landingPage={landingPage} setLandingPage={setLandingPage} />
					<LandingTexts pageContent={pageContent} setPageContent={setPageContent} title={title} setTitle={setTitle} />
					<EditFAQ data={faq} setData={setFaq} />
					<LandingImage pageContent={pageContent} files={files} setFiles={setFiles} deleteImage={deleteImage} handleUploadImage={handleUploadImage} />
					<SetVenuesComponent pageContent={pageContent} setPageContent={setPageContent} setPageContentVenues={setPageContent} setAlertType={setAlertType} setShowAlert={setShowAlert} />
					<Advanced type={"news"} news={landingPage} setNews={setLandingPage} MetaDesc={"Meta description here"} PageTitle={"Meta title here"}/>
				</div>
			</RenderIf>
			<RenderIf condition={landingPage}>
				<Loading
					showLoading={showLoading}
					inFront
				/>
			</RenderIf>
		</div>
	);
};


export default EditLandingRegion;