import React, { useContext } from "react";
import { ListFacilities } from "c/admin/DashboardComponents";
import Sidebar from "c/admin/Sidebar";
import CreateButton from "c/CreateButton";
import { MeContext } from './../MeContext';
import NoAccess from "c/admin/NoAccess";
import { T } from "../traks";

const Facilities = () => {

	const { me } = useContext(MeContext);

	return (
		<div>
			{me.user.roles.includes("admin") ?
				<div className="dashboard">
					<CreateButton />
					<Sidebar index={4} />
					<div className="dashboard-wrapper" style={{ maxWidth: 1140 }}>
						<h5 className="mb-3"><T>Faciliteter</T></h5>
						<ListFacilities />
					</div>
				</div>
			: <NoAccess />}
		</div>
	);
}

export default Facilities;