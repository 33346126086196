import React, { useState, useEffect, useContext } from "react";
import CreateButton from "c/CreateButton";
import Sidebar from "../c/admin/Sidebar";
import DashboardContainer from "../c/admin/DashboardContainer";
import DashboardContainerHeader from "../c/admin/DashboardContainerHeader";
import "../css/dashboard.css";
import { T, TraksConsumer } from "../traks";
import { APIGet } from "API";
import { MeContext } from './../MeContext';
import NoAccess from "c/admin/NoAccess";
import ListItemLarge from "c/admin/ListItemLarge";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import { Loading } from "c/Loading";
import { AlertModal } from "c/Reusables";

const MyMagazines = () => {
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin")
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })
	const [showLoading, setShowLoading] = useState(false)

	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		setShowLoading(true)
		APIGet("magazines").then(res => {
			setShowLoading(false)
			setData(res.data.magazines);
			setFilteredData(res.data.magazines)
		}).catch(err => {
			setShowLoading(false)
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const searchPlaceholder = <T>Search</T>;

	if (!isAdmin) {
		return <NoAccess />
	}

	if (!data.length) {
		return (
			<DashboardWrapper>
				<Loading
					showLoading={showLoading}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	return (
		<div className="dashboard">
			<CreateButton />
			<Sidebar index={14} />
			<div className="dashboard-wrapper">
				<h5 className="mb-3"><T>Magasiner</T></h5>
				<DashboardContainer>
					<TraksConsumer>
						<DashboardContainerHeader
							valueToSearch={"title"}
							data={data}
							searchPlaceholder={searchPlaceholder}
							setData={setFilteredData}
							search
						>
							Alle magasiner
						</DashboardContainerHeader>
					</TraksConsumer>
					<span>
						{filteredData.map((e, i) => (
							<ListItemLarge
								title={e.title}
								id={e.id}
								key={"magazines" + i}
								shortDescription={null}
								image={e.imageUrl}
								visible={true}
								goTo={("/editmagazine/")}
							/>
						))}
					</span>
				</DashboardContainer>
			</div>
		</div>
	)
};

export default MyMagazines;
