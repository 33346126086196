import React from "react";
import Notification from "c/admin/Notification";
import { PartnerInformation, SetPassword, SendPasswordReset } from "c/admin/DashboardComponents";
import { T } from "../../traks";
import { RenderIf } from "c/Reusables";

export const EditPartner = ({ saveUser, setUser, user, deleteUser, resetPassword, isAdmin }) => {

	const userStatus = () => {
		const newIsActive = !user["active"]
		setUser({...user, active: newIsActive})
		saveUser({active: newIsActive})
	}

	const savePassword = (newPassword) => {
		setUser({...user, password: newPassword})
		saveUser({password: newPassword})
	}

	if (!user) {
		return <T>No user found</T>
	}

	return (<>
		<h5 className="mb-3">
			<T>Partner profil</T>
		</h5>
		<div>
			<Notification className="mb-0"><T>Du redigerer</T>: {user["screenName"]}</Notification>
			<div className="d-flex">
				<PartnerInformation
					isAdmin={isAdmin}
					createUserState={false}
					user={user}
					setUser={setUser}
					saveUser={saveUser}
					userStatus={userStatus}
					deleteUser={deleteUser}
				/>
				<div className="d-flex flex-column">
					<SetPassword
						createUserState={false}
						data={user}
						updateUser={setUser}
						savePassword={savePassword}
					/>
					<RenderIf condition={isAdmin}>
						<SendPasswordReset
							resetPassword={resetPassword}
						/>
					</RenderIf>
				</div>
			</div>
		</div>
	</>)
}
