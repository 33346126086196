import { createContext } from 'react';

const MeContext = createContext({
	me: null,
	setMe: (newMe) => {},
});

export {
	MeContext,
};
