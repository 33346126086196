// @ts-nocheck
import { VenueImg, VenueVideo } from "../Reusables";
import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import isYouTubeURL from "../functions/isYouTubeURL";

const GalleryModal = (props) => {

	let images = props.images;
	const [modalOpen, setModalOpen] = useState(false);
	const toggle = (e) => {
		setModalOpen(!modalOpen);
		let id = e !== undefined && parseInt(e.target.id);
		if (id) {
			setImgId(id);
		}
		else {
			setImgId(0);
		}
	};

	if (isYouTubeURL(props.videoLink)) {
		images = [props.videoLink, ...images];
	}
	const [imgId, setImgId] = useState(0);
	let imgLength = images.length - 1;

	const imageButtonClick = (props) => {
		if ((props && imgId === imgLength) || (!props && imgId === 0)) {
			setImgId(props ? 0 : imgLength);
		}
		else {
			props ? setImgId(imgId + 1) : setImgId(imgId - 1);
		}
	}

	const closeIcon = <img className="btn p-1 rounded-circle" width={20} height={20} style={{ position: "absolute", right: "-20px", top: "-20px", backgroundColor: "white" }} onClick={() => toggle()} src="https://static.optimeet.dk/gfx/ico_close.svg" alt='icoClose' />
	const rightIcon = <img className="btn p-1 rounded-circle mobile_right_icon" width={40} height={40} onClick={() => imageButtonClick(true)} src="https://static.optimeet.dk/gfx/ico_btn-right.svg" alt='icoNext' />
	const leftIcon = <img className="btn p-1 rounded-circle mobile_left_icon" width={40} height={40} style={{ transform: 'rotate(180deg)' }} onClick={() => imageButtonClick(false)} src="https://static.optimeet.dk/gfx/ico_btn-left.svg" alt='icoPrev' />

	return (
		<div className="w-100">
			<div className="gallery_container" onClick={(e) => toggle(e)}>{props.children}</div>
			<Modal style={{ minWidth: "70%" }} fade={true} isOpen={modalOpen} centered className="gallery_wrapper">
				<ModalBody className="p-0 border-0 mx-auto gallery_modal" style={{ maxWidth: 1200 }}> {closeIcon}
					<div className="mobile_buttons_wrapper">
						{rightIcon}
						{leftIcon}
					</div>
					<div className="w-100 mx-auto overflow-hidden" style={{ maxWidth: 1200, borderRadius: 20 }}>
						{isYouTubeURL(images[imgId]) ? (
							<VenueVideo url={images[imgId]} />
						) : (
							<VenueImg supplier={props.supplier} full_size style={{ maxHeight: "80vh", objectFit: "cover" }} width="100%" img={images[imgId]} alt="galleryimg" />
						)}
						<div className="gallery-thumbnails-wrapper position-relative px-5">
							<div className="text-center text-bold pt-2 text-white mb-3">{imgId + 1}/{images.length}</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default GalleryModal;
