import axios from "axios";
import { getSession } from "helpers/session"

let K_API_URL = "http://localhost:3000/"; // Should switch depending on build
let K_CONTEXT = "development";

if (window.location.hostname === "booking.optimeet.dk" || window.location.hostname === "optimeet.dk") {
	K_API_URL = "https://api.optimeet.dk/";
	K_CONTEXT = "production";
} else if (window.location.hostname.includes("devel")) {
	K_API_URL = "https://devel-api.optimeet.dk/";
	K_CONTEXT = "production";
}

export const APIGet = (resource, params = {}) => {
	const session_id = getSession()
	const url = K_API_URL + resource;
	const options = { params, headers: {} };
	if (session_id) {
		options["headers"]["X-Session-Id"] = session_id || "";
	}

	return axios({ url, method: "get", ...options })
};

export const APIPost = (resource, body, headers = { "Content-Type": "application/json" }) => {
	const session_id = getSession()
	const url = K_API_URL + resource;
	if (session_id) {
		headers["X-Session-Id"] = session_id;
	}

	return axios({ url, method: "post", data: body, headers })
};

export const APIDelete = (resource, data = {}) => {
	const session_id = getSession()
	const url = K_API_URL + resource;
	const headers = {};
	if (session_id) {
		headers["X-Session-Id"] = session_id;
	}

	// @ts-ignore
	return axios({ url, method: "delete", data, headers })
};
