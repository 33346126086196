import React from "react";
import { Col, Row } from "reactstrap";
import { T } from '../traks';

const FindAfterLocation = () => {
	return (
		<div className="container pt-lg-5">
			<h2 className="text-center d-none d-lg-block mt-5 pb-3"><T>Find mødested efter lokation</T></h2>
			<h2 className="d-block d-lg-none mt-5 text-start"><T>Find efter lokation</T></h2>
			<div className="d-flex my-4">
				<LocationCategories />
			</div>
		</div>
	);
}

const LocationCategories = () => {

	const eventTypeData = [
		{
			location: "København",
			urlName: "koebenhavn",
			src: "https://static.optimeet.dk/upload/zWOotfOSgT-ts1712131372740",
			srcSet: "https://static.optimeet.dk/upload/zWOotfOSgT-ts1712131372740 2x",
			id: 1,
			smallImg: false,
		},
		{
			location: "Aarhus",
			urlName: "aarhus",
			src: "https://static.optimeet.dk/upload/ooyeGzerg3-ts1712234501363",
			srcSet: "https://static.optimeet.dk/upload/ooyeGzerg3-ts1712234501363 2x",
			id: 2,
			smallImg: false,
		},
		{
			location: "Odense",
			urlName: "odense",
			src: "https://static.optimeet.dk/upload/Xb45F9tMAI-ts1712227443294",
			srcSet: "https://static.optimeet.dk/upload/Xb45F9tMAI-ts1712227443294 2x",
			id: 3,
			smallImg: true,
		},
		{
			location: "Vejle",
			urlName: "vejle",
			src: "https://static.optimeet.dk/upload/vDtjZiEDn0-ts1712230175133",
			srcSet: "https://static.optimeet.dk/upload/vDtjZiEDn0-ts1712230175133 2x",
			id: 4,
			smallImg: true,
		},
		{
			location: "Kolding",
			urlName: "kolding",
			src: "https://static.optimeet.dk/upload/vWAhyK4AqG-ts1712226504692",
			srcSet: "https://static.optimeet.dk/upload/vWAhyK4AqG-ts1712226504692 2x",
			id: 5,
			smallImg: true,
		},
		{
			location: "Nordsjælland",
			urlName: "nordsjaelland",
			src: "https://static.optimeet.dk/upload/bvBL3qS3gb-ts1712238515373",
			srcSet: "https://static.optimeet.dk/upload/bvBL3qS3gb-ts1712238515373 2x",
			id: 6,
			smallImg: true,
		},
		{
			location: "Aalborg",
			urlName: "aalborg",
			src: "https://static.optimeet.dk/upload/fq3MUx2GEg-ts1712229498864",
			srcSet: "https://static.optimeet.dk/upload/fq3MUx2GEg-ts1712229498864 2x",
			id: 7,
			smallImg: true,
		},  
		{
			location: "Sverige",
			urlName: "sverige",
			src: "https://static.optimeet.dk/upload/JyqX3WDteD-ts1712233354810",
			srcSet: "https://static.optimeet.dk/upload/JyqX3WDteD-ts1712233354810 2x",
			id: 8,
			smallImg: true,
		}, 
		{
			location: "Tyskland",
			urlName: "tyskland",
			src: "https://static.optimeet.dk/upload/2pHmWLzr34-ts1712239458125",
			srcSet: "https://static.optimeet.dk/upload/2pHmWLzr34-ts1712239458125 2x",
			id: 9,
			smallImg: true,
		},  
		{
			location: "Tyskland",
			urlName: "tyskland",
			src: "https://static.optimeet.dk/upload/2pHmWLzr34-ts1712239458125",
			srcSet: "https://static.optimeet.dk/upload/2pHmWLzr34-ts1712239458125 2x",
			id: 10,
			smallImg: true,
		},
		{
			location: "Norge",
			urlName: "norge",
			src: "https://static.optimeet.dk/upload/h0nVFhWCcW-ts1712231173731",
			srcSet: "https://static.optimeet.dk/upload/h0nVFhWCcW-ts1712231173731 2x",
			id: 11,
			smallImg: true,
		},  
	];

	let dataSubset1 = eventTypeData.slice(0, 2);
	let dataSubset2 = eventTypeData.slice(2, 5);
	let dataSubset3 = eventTypeData.slice(5, 9);
	
	return (
		<div>
			<div>
				<Row className="d-flex justify-content-center">
					{dataSubset1.map((v, i) => (
						<Col key={i} xs={12} sm={12} md={6} lg={6} xl={6}>
							<div className="d-flex justify-content-center py-2">
								<>
									<LocationBadge
										eventTypeData={v} key={"promoted" + i}
									/>
								</>
							</div>
						</Col>
					))}
				</Row>
				<Row className="d-flex justify-content-center">
					{dataSubset2.map((v, i) => (
						<Col key={i} xs={12} sm={12} md={4} lg={4} xl={4}>
							<div className="d-flex justify-content-center py-2">
								<>
									<LocationBadge
										eventTypeData={v} key={"promoted" + i}
									/>
								</>
							</div>
						</Col>
					))}
				</Row>
				<Row className="d-flex justify-content-center">
					{dataSubset3.map((v, i) => (
						<Col key={i} xs={12} sm={12} md={4} lg={4} xl={4}>
							<div className="d-flex justify-content-center py-2 mb-xl-4">
								<>
									<LocationBadge
										eventTypeData={v} key={"promoted" + i}
									/>
								</>
							</div>
						</Col>
					))}
				</Row>
			</div>
		</div>
	);
}

const LocationBadge = (props) => {

	const handleClick = () => {
		window.location.href = ("/konferencesteder/lokationer/" + props.eventTypeData.urlName);
	}
		
	return (
		<span onClick={() => handleClick()} className="w-100 zoomable-image cursor_pointer">
			<div className="position-relative overflow_hidden border_radius_24 w-100">
				<img className="selected_venue_img zoomable-image w-100" height="300" src={props.eventTypeData.src} srcSet={props.eventTypeData.SrcSet} alt="locimg" />
				<div className="gradient_bg_loc white location_text_wrapper">
					<div>
						<h4 className="text-bold mb-md-4 w-100 white text-break">{props.eventTypeData.location}</h4>
					</div>
				</div>
			</div>
		</span>
	);
}

export default FindAfterLocation;
