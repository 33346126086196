import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { T, TraksConsumer } from '../traks';
import LocationSelector from './LocationSelector';
import { LL } from 'Routes';

const Finder = ({ customImg = undefined, customText = undefined, landingPage = false }) => {

	const headerText = customText ? customText : <T>Find det rette sted til dine møder og konferencer</T>;
	const img = customImg ? customImg : "https://static.optimeet.dk/imgs/hero_1920x800-3.webp";

	return (
		<Container fluid className="hero_bg_img pt-5 pb-3 pb-md-4 pb-xl-0" style={{ backgroundImage: `url("${img}")` }}>
			<div className="py-0 mt-0 py-xl-5 my-xl-5">
				<Container className="d-flex align-items-center flex-column pt-0 pb-3" style={{ maxWidth: 1070 }}>
					<Row className="flex-column pt-0 pt-md-5">
						<Col>
							<h1 className="display-5 text-light mt-0 mt-md-4 hero-title">{headerText}</h1>
							<div className="hero-tagline">
								<p className="text-light font-primary lead mt-2 mt-md-4"><T>Søg her mellem 200+ mødesteder eller kontakt os på <br /> +45 33 97 43 43 eller booking@optimeet.dk.</T></p>
								<p className="font-primary lead text-white"><strong><T>Vi er klar med gratis rådgivning.</T></strong></p>
							</div>
						</Col>
						<Col>
							<div className="datePickerWrapper font-primary mt-1 mt-md-5">
								<TraksConsumer>
									<LocationSelector />
								</TraksConsumer>
							</div>
							<div className="pt-4 pt-md-5 pb-0 pb-md-2 w-100 d-flex justify-content-center">
								<div className="pt-0 pt-xl-4 hoverfx">
									<a className="white font-primary border-bottom hero-link" href={!landingPage ? LL("/konferencesteder/") : "#venueList"} style={{ fontSize: 18 }}><T>Se mødesteder</T></a>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>	
		</Container>
	);
}

export default Finder;
