import React, { useState } from 'react';
import Kbutton from './KButton';
import { VenueImg } from "./Reusables";
import { T } from '../traks';
import { LL } from 'Routes';
import { Container } from 'reactstrap';

export const PromotedCard = (props) => {

	const hasPopular = props.data.priority > 3 ? "107px" : "10px";

	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [mouseEnter, setMouseEnter] = useState(false);

	const incrementImage = () => {
	  	setCurrentImageIndex((prevIndex) => {
			const newIndex = prevIndex === props.data.imagesUrls.length - 1 ? 0 : prevIndex + 1;
			return newIndex;
	  	});
	};
  
	const decrementImage = () => {
	  	setCurrentImageIndex((prevIndex) => {
			const newIndex = prevIndex === 0 ? props.data.imagesUrls.length - 1 : prevIndex - 1;
			return newIndex;
	  	}); 
	};

	const toggleMouseEnter = () => {
		setMouseEnter(!mouseEnter);
  	};

	const conditionalCSS = mouseEnter ? "border border-2" : "";
  
	return (
		<div onMouseEnter={toggleMouseEnter} onMouseLeave={toggleMouseEnter} className="w-100">
			<div className="promoted_card">
				<a href={LL((props.suppliers ? "/leverandoerer/" : "/kursuscenter/") + props.data.urlSlug)}>
					<div style={{ position: "relative" }}>
						{props.data.priority > 3 && <span className='priority_badge px-3 py-1 text-bold'><T>Populært</T></span>}
						{props.data.venueOfTheMonth && 
							<span style={{ marginLeft: hasPopular }} className='text-white text-bold text-center hotelofmonth_badge py-1 px-3'><T>Månedens hotel</T></span>
						}
						<VenueImg
							supplier={props.suppliers}
							cover
							className="promoted_img_card_scale"
							img={props.data.imagesUrls[currentImageIndex]}
							alt="venueimg"
						/>
						{/*} Hide until image logos has been changed to correct resolution
							{props.data.iconUrl && <img className="promotedcard_profile_image rounded-circle" width="30" height="30" src={props.data.iconUrl} alt="iconimg" />}
						{*/}
					</div>
				</a>
				<Container style={{position:"relative"}}>
					<button className={conditionalCSS} style={{backgroundColor: "rgba(255, 255, 255, 0.50)", border:"none", position:"absolute", left:"0px", bottom:"195px", width:"35px", height:"35px", borderRadius:"50%" }} onClick={decrementImage}>
						<img
							src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_chevron_down-0.svg"
							alt="icon-chevron"
							className={"chevron-icon"}
							width={"13px"}
							height={"13px"}
							style={{transform: 'rotate(90deg)'}}
						/>
					</button>
					<button className={conditionalCSS} style={{backgroundColor: "rgba(255, 255, 255, 0.50)", border:"none", position:"absolute", bottom:"195px", left:"270px", width:"35px", height:"35px", borderRadius:"50%" }} onClick={incrementImage}>
						<img
							src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_chevron_down-0.svg"
							alt="icon-chevron"
							className={"chevron-icon"}
							width={"13px"}
							height={"13px"}
							style={{transform: 'rotate(270deg)'}}
						/>
					</button>
				</Container>
				<div>
					<div className="opposites mt-3">
						<span className="text-bold ps-2">{props.data.title.length > 28 ? `${props.data.title.substring(0, 28)}...` : props.data.title}</span>
						{props.data.reviews &&
							<span className="font_13px text-bold me-2 mb-1">
								<img className="mb-1 me-1" width={17} height={17} src="https://static.optimeet.dk/gfx/ico_star-fill-0.svg" alt='iconstars' />
								{props.data.rating}
							</span>
						}
					</div>
					<div className="text-md mt-1 ps-2 promoted-cards-address font-primary">{props.data.address_1},&nbsp;{props.data.city},&nbsp;{props.data.zipcode},&nbsp;{props.data.address_2}</div>
				</div>
			</div>
		</div>
	);
};

export const VenueCard = (props) => {

	const border = props.border ? "venue_card_border venue_card my-1 pb-3" : "venue_card my-1 pb-3";
	const hasPopular = props.data.priority > 3 ? "95px" : "10px"; 

	return (
		<a className="remove-link-style w-100" href={LL((props.suppliers ? "/leverandoerer/" : "/kursuscenter/") + props.data.urlSlug)}>
			<div style={props.data.priority > 3 ? {borderRadius: '24px', backgroundColor: '#EFF7F8'} : {borderRadius: '24px'}} className={border}>
					<div>
						{props.data.priority > 3 && <span className='priority_badge px-3 py-1 text-bold text-sm'><T>Populært</T></span>}
						{props.data.venueOfTheMonth && 
							<span style={{ marginLeft: hasPopular }} className='text-bold text-sm hotelofmonth_badge px-3 py-1'><T>Månedens hotel</T></span>
						}
						<VenueImg supplier={props.suppliers} card className="venue_img_card_scale" width="312" height="110" img={props.data.imagesUrls[0]} alt="venueimg" />
						{/*} Hide until images are correct
						{props.data.iconUrl && 
						<div className="venuecard_profile_image p-1 d-flex align-items-center" style={{backgroundColor: "#fff", borderRadius: 100, overflow: "hidden"}}>
							<img width="100%" src={props.data.iconUrl} alt="iconimg" />
						</div>
						}	
						{*/}
					</div>

				<div className="pt-3">
					<div className='px-3'>
						<div className="text-bold text-nowrap font-primary" style={{ overflow: "hidden" }}>{props.data.title.length > 20 ? `${props.data.title.substring(0, 25)}...` : props.data.title}</div>
						<div className="d-flex align-items-start mt-2" style={{maxHeight: 35, overflow: "hidden"}}>
							<img src="https://static.optimeet.dk/gfx/ico_maplocation-1.svg" width={"12px"} height={"18px"} alt="icon-map" style={{marginTop: 3}}/>
							<div className="descfont ms-2 primarycolor">
								{props.data.address_1}{" "}<br />{props.data.city}{" "}{props.data.zipcode}{" "} - {props.data.address_2}
							</div>
						</div>
						<div className="venue-card-desc-container descfont-medium desc_lineheight grey pt-2">
							<div className="venue-card-desc mt-1" style={{fontSize: 14}}>
								{props.data.description.length > 135 ?
									<div style={{lineHeight: 1.5}}>{props.data.description.substring(0, 135)
										.replace(/<p>/g, ' ')
										.replace(/\b[A-Z]+\b/g, match => match.charAt(0) + match.substring(1).toLowerCase())
										.replace(/(<([^>]+)>)/gi, "")
										.replace(/^\w/, match => match.toUpperCase())
										.replace(/<\/?[^>]+>|&[^;]+;/g, '')
									}</div>
									:
									<div>{props.data.description
										.replace(/<p>/g, ' ')
										.replace(/\b[A-Z]+\b/g, match => match.charAt(0) + match.substring(1).toLowerCase())
										.replace(/(<([^>]+)>)/gi, "")
										.replace(/^\w/, match => match.toUpperCase())
										.replace(/<\/?[^>]+>|&[^;]+;/g, '')
									}</div>
								}
							</div>
						</div>
						{props.data["products"] && props.data.products[0] !== undefined && props.data.products[0].price && <div className="descfont grey pt-3 font-primary"><T>PRISER FRA</T></div>}
						<div className="opposites">
							{props.data["products"] && props.data.products[0] !== undefined && props.data.products[0].price && <span className="text-bold">{props.data.products[0].price},-</span>}
							{props.data.reviews && 
								<span className="text-bold me-3">
									{props.data.rating}
									<img className="mb-2 ms-1" width={17} height={17} src="https://static.optimeet.dk/gfx/ico_star-fill-0.svg" alt='iconstars' />
								</span>
							}
						</div>
					</div>
				</div>
			</div>
		</a>
	);
};

export const MapCard = (props) => {
	return (
		<div className="map_card">
			<div className="d-flex">
				<div>
					<a href={LL((props.suppliers ? "/leverandoerer/" : "/kursuscenter/") + props.data.urlSlug)}>
						<div>
							{props.data.priority > 3 && <span className='priority_badge px-3 py-1 text-bold text-sm'><T>Populært</T></span>}
							<VenueImg supplier={props.suppliers} card className="map_card_img" style={{ borderRadius: 10 }} img={props.data.imagesUrls[0]} alt="venueimg" />
							{props.data.iconUrl &&<img className="map_card_icon_image rounded-circle" width="30" height="30" src={props.data.iconUrl} alt="iconimg" />}
						</div>
					</a>
				</div>
				<div className="pt-1">
					<div className='pe-2 ps-2 me-2 mx-2'>
						<div className="text-bold text-lg mt-2">{props.data.title.length > 28 ? `${props.data.title.substring(0, 28)}...` : props.data.title}</div>
						<div className="text-weight-reg text-md pt-2 d-flex">
							<img className="mb-1 me-2 mt-1" width={12} height={16} src="https://static.optimeet.dk/gfx/ico_maplocation-1.svg" alt='iconmap' />
							<div>
								<div>
									<span className="text-md mt-1 font-primary">{props.data.address_1}</span>
								</div>
								<div>
									{props.data.zipcode},&nbsp;{props.data.city}
								</div>
								<div className="d-none">
									{props.data.address_2}
								</div>
							</div>
						</div>
						<div className="pt-2">
							{/* Hide price tag until it works or we agree they want it
							<Dollars count={props.data.priceRating} />
							*/}
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className="d-flex pt-1 pe-0 justify-content-between map-card-text">
					<div className="d-flex align-items-center">
						{props.data["products"] && props.data.products[0] !== undefined && props.data.products[0].price && 
							<div className="me-3">
								<div style={{display:"inline-flex"}} className="priserfra">
									<T>PRISER FRA</T>
								</div>
								<div>
									<span><T>DKK</T>&nbsp;{props.data.products[0].price},-</span>
								</div>
							</div>
						}
						{props.data.reviews &&
							<div className='ms-1'>
								<div><T>ANMELDELSER</T>&nbsp;({props.data.reviews})</div>
								<span>
									<img className="mb-1 me-1" width={17} height={17} src="https://static.optimeet.dk/gfx/ico_star-fill-0.svg" alt='iconstars' />
									{props.data.rating}
								</span>
							</div>
						}
					</div>
					<a href={LL((props.suppliers ? "/leverandoerer/" : "/kursuscenter/") + props.data.urlSlug)}>
						<Kbutton title={<T>Forespørg</T>} prim md custom=" py-3" />
					</a>
				</div>
			</div>
		</div>
	);
};

export const ListCardList = (props) => {

	const hasPopular = props.data.priority > 3 ? "95px" : "10px"; 

	return (
		<div style={props.data.priority > 3 ? {border: '2px solid rgba(0, 0, 0, 0.08)', borderRadius: '24px', backgroundColor: '#EFF7F8' }: {border: '2px solid rgba(0, 0, 0, 0.08)', borderRadius: '24px'}} className="listing_card_list shadow-soft p-3">
			<div className="d-flex flex-column-reverse flex-lg-row w-100 position-relative">
				<div className="lcl_1">
					<a  
						href={LL((props.suppliers ? "/leverandoerer/" : "/kursuscenter/") + props.data.urlSlug)}
					>
						<div>
							{props.data.priority > 3 && <span className='priority_badge px-3 py-1 text-bold text-sm'><T>Populært</T></span>}
							{props.data.venueOfTheMonth && 
								<span style={{marginLeft: hasPopular}} className='text-bold text-sm hotelofmonth_badge px-3 py-1'><T>Månedens hotel</T></span>
							}
							<VenueImg supplier={props.suppliers} card className="venue_img_card_scale border-radius-12" img={props.data.imagesUrls[0]} alt="venueimg" />
						</div>
					</a>
				</div>
				<div className="lcl_2 ms-3 pt-1">
					<div>
						<div className="text-bold text-xl mt-1 font-primary" style={{maxHeight: 30, overflow: "hidden"}}>{props.data.title}</div>
						<div className="text-weight-reg text-md pt-2">
							<img className="mb-1 me-1" width={12} height={16} src="https://static.optimeet.dk/gfx/ico_maplocation-1.svg" alt='iconmap' />
							<span className="text-md mt-1 font-primary">{props.data.address_1},&nbsp;{props.data.city},&nbsp;{props.data.zipcode},&nbsp;{props.data.address_2}</span>
						</div>
						<div className="text-weight-reg text-md ms-3">{props.data.zip}</div>
						<div style={{maxHeight: 30, overflow: "hidden"}} className="descfont_listing grey pt-3">
							{props.data.description.length > 170 ?
								<div style={{lineHeight: 1.5}}>{props.data.description.substring(0, 170)
									.replace(/<p>/g, ' ')
									.replace(/\b[A-Z]+\b/g, match => match.charAt(0) + match.substring(1).toLowerCase())
									.replace(/(<([^>]+)>)/gi, "")
									.replace(/^\w/, match => match.toUpperCase())
									.replace(/<\/?[^>]+>|&[^;]+;/g, '')
								}</div>
								:
								<div>{props.data.description
									.replace(/<p>/g, ' ')
									.replace(/\b[A-Z]+\b/g, match => match.charAt(0) + match.substring(1).toLowerCase())
									.replace(/(<([^>]+)>)/gi, "")
									.replace(/^\w/, match => match.toUpperCase())
									.replace(/<\/?[^>]+>|&[^;]+;/g, '')
								}</div>
							}
							</div>
						<div className="d-flex flex-column-reverse flex-lg-row pt-3">
							{props.data["products"] && props.data.products[0] !== undefined && props.data.products[0].price && <div>
								<div className="priserfra font-primary"><T>PRISER FRA</T></div>
								<span className="text-bold font-primary">DKK&nbsp;{props.data.products[0].price},-</span>
							</div>}
							{props.data.reviews &&
								<div className="ms-5">
									<div className="priserfra"><T>ANMELDELSER</T>&nbsp;({props.data.reviews})</div>
									<span className="me-3">
										<img className="mb-1 me-1" width={17} height={17} src="https://static.optimeet.dk/gfx/ico_star-fill-0.svg" alt='iconstars' />
										{props.data.rating}
									</span>
								</div>
							}
							{/*} Hide this until price filtering is correctly implemented
							<div className='ms-5'>
								<div className="dollars-container">
									<Dollars count={props.data.priceRating} />
								</div>
							</div>
						{*/}
						</div>
					</div>
				</div>
				<div className="d-flex align-items-end flex-column">
					<div>
						{props.data.iconUrl ? 
						 <div>
							{/*} Hide until images are correct
								<div className="venuecard_profile_image p-1 d-flex align-items-center shadow-soft" style={{backgroundColor: "#fff", borderRadius: 100, overflow: "hidden"}}>
									<img width="100%" src={props.data.iconUrl} alt="iconimg" />
								</div>
							{*/}
						 </div>
						: 
						<div className='temp_icon_null' />}
					</div>
					<div className="mt-5 pt-5 position-absolute" style={{bottom: "7px"}}>
						<a href={LL((props.suppliers ? "/leverandoerer/" : "/kursuscenter/") + props.data.urlSlug)}>
							<Kbutton textSm prim md><T>Forespørg</T></Kbutton>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export const TeamList = ({ data, col }) => {
	const rows = [];
	let currentRow = [];
	const columnCount = col; 

	for (let i = 0; i < data.length; i++) {
		currentRow.push(
			<div className={`col-lg-${12 / columnCount} mb-4 ps-0`} key={data[i].id}>
				<div className="shadow-soft w-100 d-flex align-items-center rounded overflow-hidden" style={{border: '2px solid rgba(0, 0, 0, 0.08)'}}>
					<div className="me-3">
						{data[i].imageUrl ? <img style={{maxWidth: 120}} src={data[i].imageUrl} alt="team-member" /> : <div className='my-5 py-5'/>}
					</div>
					<div>
						<div className="fw-bold">{data[i].name}</div>
						<div>{data[i].jobTitle}</div>
						<div>{data[i].phone}</div>
						<div>{data[i].email}</div>
						{data[i].linkedIn &&
						<div>
							<a href={data[i].linkedIn} target="_blank" rel="noopener noreferrer">
								<img src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_linkedin.svg" alt="ico_linkedin" width="20" />
							</a>
						</div>}
					</div>
				</div>
			</div>
		);
		if ((i + 1) % columnCount === 0 || i === data.length -1) {
			rows.push(<div className="row" key={`row-${i}`}>{currentRow}</div>);
			currentRow = [];
		}
	}

	return <div className="container">{rows}</div>;
};