import React, { useState, useEffect } from "react";
import { TraksConsumer } from "../traks";
import Header from "../c/Header";
import FixedContactButton from "c/FixedContactButton";
import SuppliersComponent from "../c/SuppliersComponent";
import SupplierComponent from "../c/SupplierComponent";

const Suppliers = () => {

	const [supplierPage, setSupplierPage] = useState(undefined);

	useEffect(() => {
		const url = window.location.href;
		const suppliertxt = url.split('leverandoerer')[1];
		const textAfterLeverandoerer = suppliertxt.split('/').filter(Boolean)[0];

		if (textAfterLeverandoerer) {
			setSupplierPage(true);
		} else {
			setSupplierPage(false);
		}
	}, []);

	return (
		<div>
			<Header />
			{!supplierPage ?
				<TraksConsumer>
					<SuppliersComponent />
				</TraksConsumer>
				:
				<TraksConsumer>
					<SupplierComponent />
				</TraksConsumer>
			}
			<FixedContactButton />
		</div>
	);
}

export default Suppliers;