import React, { useState, useEffect, useContext } from "react";
import { Container, Modal, ModalBody, Input, Spinner } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { MeContext } from '../../MeContext';
import { APIPost } from '../../API.js';
import { isValidEmail, DotLoader, RenderIf, passwordValidator, rePasswordValidator } from '../Reusables';
import Kbutton from '../KButton';
import { T } from "../../traks";
import { LL } from "Routes";

export const RefreshSession = (session_id) => {
	const key = "optimeet_ipse_session_id";
	if(session_id) {
		localStorage.setItem(key, session_id);
	} else {
		localStorage.removeItem(key);
	}
	return;
}

const LoginModal = (props) => {

	useEffect(() => {
		if (props.children.props.newpsstage !== undefined) {
			setModalOpen(true);
		}
	}, [props.children.props.newpsstage]);

    const [modalOpen, setModalOpen] = useState(false);
    const toggle = () => {
        if (!modalOpen) {
            props.disableDropdown();
        }
		setModalOpen(!modalOpen);
	};
    const closeIcon = <img className="btn p-1 rounded-circle" style={{ position: "absolute", right: 15, top: 15, backgroundColor: "white", width: 24, height: 24 }} onClick={() => props.closeModal() + toggle()} src="https://static.optimeet.dk/gfx/ico_close.svg" alt='icoClose' />

	return (
		<div>
			<div className="p-0 m-0" onClick={() => toggle(props)}>{props.children}</div>
			<Modal autoFocus={false} fade={true} style={{ maxWidth: 800 }} isOpen={modalOpen} centered>
				<ModalBody className="pb-3"> {closeIcon}
					<LoginModalStages state={props.children.props.stage} sessionId={props.sessionid} />
					<div className="text-center">
						<img style={{ filter: "invert(95%)" }} alt="klogo" width={84} height={26} src={"https://static.optimeet.dk/gfx/klogo_dark.svg"} />
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

const LoginModalStages = (props) => {

	const [signUpStage, setSignUpStage] = useState(props.state);

	const callback = payload => {
		setSignUpStage(payload);
	}

	switch (signUpStage) {
		case 'Login':
			return (
				<LoginStage callback={callback} />
			);
		case 'ResetPassword':
			return (
				<ResetPasswordStage callback={callback} />
			);
		case 'BecomePartner':
			return (
				<BecomePartnerStage callback={callback} />
			);
		case 'CreateNewPassword':
			return (
				<CreateNewPassword callback={callback} sessionId={props.sessionId} />
			);
		case 'Processing':
			return (
				<Processing />
			);
		default:
			return <div className='text-center p-2'>Registering...<Spinner className="p-2" color="secondary" /></div>;
	}
}

const LoginStage = ({ callback }) => {

	const handleBtnResetPS = () => callback("ResetPassword");
	const handleBtnPartner = () => callback("BecomePartner");

    const { setMe } = useContext(MeContext);
	const [loginForm, setLoginForm] = useState({
        'username': '',
        'password': ''
	});

	const [showInvalidEmail, setShowInvalidEmail] = useState(false);
    const [showInvalidPassword, setShowInvalidPassword] = useState(false);
    const [loginFail, setLoginFail] = useState(false);
    const navigate = useNavigate();

	const submit = () => {
		setLoginFail(false);
		if (!isValidEmail(loginForm.username)) {
			setShowInvalidEmail(true);
		}
		if (loginForm.password.length < 6) {
			setShowInvalidPassword(true);
		}
		else {
			login();
		}
	};

    const login = () => {
        APIPost("login", loginForm)
            .then((reply) => {
                const me = reply.data;
                if (reply.data.session_id) {
                    RefreshSession(reply.data.session_id);
                    setMe(me);
                    navigate(LL('/dashboard/'), { replace: true });
                }
            })
            .catch((error) => {
                setLoginFail(true);
                if (error.response.status === 401) {
                    setLoginForm({ ...loginForm, password: '' });
                }
            }
        )
    }

	return (
		<Container>
			<div className="py-5 my-5">
				<div className="pb-5">
					<h1 className="text-center py-3 mb-5 mb-lg-0"><T>Partner login</T></h1>
				</div>
				<div style={{height: '36vh' }} className="d-flex justify-content-center align-items-center px-1">
					<form className="w-100 mx-2 mx-lg-5" id="signup" onSubmit={(e) => e.preventDefault()}>
						<div className="w-100">
							<p className="text-bold"><T>Email</T></p>
							<Input
								autoFocus={true}
								type="kform"
								className="py-3 text-weight-reg"
								onChange={(e) => setLoginForm({ ...loginForm, username: e.target.value })}
								name="email"
								id="email"
								form="signup"
								value={loginForm.username}
							/>
							{showInvalidEmail && !isValidEmail(loginForm.username) && <div className="red_text"><i><T>Please provide a valid email</T></i></div>}
						</div>
						<div className="w-100">
							<p className="text-bold pt-4"><T>Adgangskode</T></p>
							<Input
								type="password"
								className="py-3 text-weight-reg"
								onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
								name="password"
								id="password"
								form="signup"
								value={loginForm.password}
							/>
							{showInvalidPassword && loginForm.password.length < 6 && <div className="red_text"><i><T>Please provide a sufficient password</T></i></div>}
						</div>
						{loginFail && <div className="red_text text-center py-3"><i><T>Login fail</T></i></div>}
						<div className="mt-5 text-center">
							<Kbutton onClick={submit} custom=" py-3 px-5" prim md><T>Login</T></Kbutton>
						</div>
						<div className="mt-5 px-0 px-lg-5 text-center">
							<button style={{ color: "#5E7175" }} className="text-weight-reg btn" onClick={handleBtnResetPS}><T>Glemt adgangskode</T></button>
							<span style={{ borderLeft: "1px solid grey", paddingBottom: "5px" }} />
							<button style={{ color: "#5E7175" }} className=" text-weight-reg btn" onClick={handleBtnPartner}><T>Bliv partner</T></button>
						</div>
					</form>
				</div>
			</div>
		</Container>
	);
}

const ResetPasswordStage = ({ callback }) => {

	const handleBtnLogin = () => callback("Login");
	const handleBtnPartner = () => callback("BecomePartner");

	const [resetForm, setResetForm] = useState({ 'email': '' });
	const [showInvalidEmail, setShowInvalidEmail] = useState(false);

	const [resetSent, setResetSent] = useState(false);

	const submit = () => {
		if (!isValidEmail(resetForm.email)) {
			setShowInvalidEmail(true);
		}
		else {
			setShowInvalidEmail(false);
			resetPassword();
		}
	};

	const resetPassword = () => {
		const postObj = {
			'username': resetForm.email,
			'link': window.location.origin + LL(""),
		};
		APIPost('forgot-password', postObj).then((response) => {
			if (response.status === 200) {
				setResetSent(true);
				//setSuccessText(<div className="text-center color_green font_15px"><i><T>Check your inbox for a link to reset password.</T></i></div>)
			}
		})
	}

	return (
		<Container>
			<div className="py-5 my-5">
				{!resetSent ?
					<div>
						<div className="pb-5">
							<h1 className="text-center py-3 mb-5 mb-lg-0"><T>Glemt adgangskode</T></h1>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '36vh' }}>
							<form className="w-100 mx-2 mx-lg-5" id="signup" onSubmit={(e) => e.preventDefault()}>
								<div className="w-100">
									<p className="text-bold"><T>Email</T></p>
									<Input
										autoFocus={true}
										type="kform"
										onChange={(e) => setResetForm({ ...resetForm, email: e.target.value })}
										className="text-weight-reg py-3"
										name="email"
										id="email"
										form="signup"
										value={resetForm.location}
									/>
									{showInvalidEmail && !isValidEmail(resetForm.email) && <div className="red_text"><i><T>Indsæt venligst gyldig e-mail</T></i></div>}
								</div>
								<div className="mt-5 text-center">
									<Kbutton onClick={submit} title={<T>Gensend</T>} custom=" py-3 px-5" prim md></Kbutton>
								</div>
								<div className="mt-5 px-0 px-lg-5 text-center">
									<button style={{ color: "#5E7175" }} className="text-weight-reg btn" onClick={handleBtnLogin}><T>Login</T></button>
									<span style={{ borderLeft: "1px solid grey", paddingBottom: "5px" }} />
									<button style={{ color: "#5E7175" }} className=" text-weight-reg btn" onClick={handleBtnPartner}><T>Bliv partner</T></button>
								</div>
							</form>
						</div>
					</div>
					:
					<div>
						<div className="pb-5">
							<h1 className="text-center py-3 mb-5 mb-lg-0"><T>Tjek din email</T></h1>
						</div>
						<div className="text-center" style={{ height: '36vh' }}>
							<div style={{ fontSize: "17px" }} className="text-bold">
								<p><T>En gendannelses-email er lige blevet sendt til din emailadresse</T></p>
							</div>
							<div style={{ fontSize: "17px" }} className="text-bold">
								<span>
									<span><T>Fik du ikke mailen?</T></span>
									<span onClick={resetPassword} className="primarycolor-light btn mb-1 ps-1 text-bold"><T>Send igen</T></span>
								</span>
							</div>
							<div className="my-5">
								<Kbutton onClick={handleBtnLogin} title={<T>Login</T>} custom=" py-3 px-5" prim md></Kbutton>
							</div>
							<div className="mt-5 px-0 px-lg-5 text-center">
								<button style={{ color: "#5E7175" }} className="text-weight-reg btn" onClick={handleBtnLogin}><T>Login</T></button>
								<span style={{ borderLeft: "1px solid grey", paddingBottom: "5px" }} />
								<button style={{ color: "#5E7175" }} className=" text-weight-reg btn" onClick={handleBtnPartner}><T>Bliv partner</T></button>
							</div>
						</div>
					</div>
				}
			</div>
		</Container>
	);
}

const BecomePartnerStage = ({ callback }) => {

	const handleBtnLogin = () => callback("Login");
	const handleBtnResetPS = () => callback("ResetPassword");

	return (
		<Container>
			<div className="py-5 my-5">
				<div>
					<h1 className="text-center py-3 mb-5 mb-lg-0"><T>Bliv Partner</T></h1>
				</div>
				<div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '36vh' }}>
					<form style={{ width: '410px' }} id="signup" onSubmit={(e) => e.preventDefault()}>
						<div className="w-100">
							<p className="text-bold"><T>Kontakt os på</T></p>
							<p className="text-bold primarycolor-light"><T>kontakt@optimeet.dk</T></p>
						</div>
						<div className="mt-5 px-0 px-lg-5 text-center">
							<button style={{ color: "#5E7175" }} className="text-weight-reg btn" onClick={handleBtnLogin}><T>Login</T></button>
							<span style={{ borderLeft: "1px solid grey", paddingBottom: "5px" }} />
							<button style={{ color: "#5E7175" }} className=" text-weight-reg btn" onClick={handleBtnResetPS}><T>Glemt adgangskode</T></button>
						</div>
					</form>
				</div>
			</div>
		</Container>
	);
}

const CreateNewPassword = (props) => {
	const [newPassword, setNewPassword] = useState("");
	const [rePassword, setRePassword] = useState("");
	const [showInvalidPassword, setShowInvalidPassword] = useState(false);
	const [showNonMatchingPassword, setShowNonMatchingPassword] = useState(false);
	const [resetSuccess, setResetSuccess] = useState(false);
	const [resetFail, setResetFail] = useState(false);

	const submit = () => {
		const isValidPassword = passwordValidator(newPassword);
		const isMatching = rePasswordValidator(rePassword, newPassword);
		if (!isValidPassword)
			setShowInvalidPassword(true);
		if (!isMatching)
			setShowNonMatchingPassword(true);
		if (isValidPassword && isMatching)
			APIPost("reset-password", {session_id: props.sessionId, password: newPassword}).then(res => {
				window.history.replaceState({}, document.title, window.location.origin);
				setResetSuccess(true);
				setTimeout(() => {
					props.callback("Login");
				}, 2000);
			})
		.catch(res => setResetFail(true))
	}

	return (
		<Container>
			<div className="py-5 my-5">
				<div>
					<div className="pb-5">
						<h1 className="text-center py-3 mb-5 mb-lg-0"><T>Glemt adgangskode</T></h1>
					</div>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '36vh' }}>
						<form className="w-100 mx-2 mx-lg-5" id="signup" onSubmit={(e) => e.preventDefault()}>
							<div className="w-100">
								<p className="text-bold"><T>Nyt kodeord</T></p>
								<Input
									autoFocus={true}
									type="password"
									onChange={(e) => setNewPassword(e.target.value)}
									className="text-weight-reg py-3"
									name="password"
									form="forgot-password"
									value={newPassword}
								/>
								<RenderIf condition={showInvalidPassword}>
									<div className="red_text"><i><T>Adgangskoden skal være på mindst 8 tegn og indeholde 1 stor, 1 lille og en speciel tegn</T></i></div>
								</RenderIf>
								<RenderIf condition={resetFail}>
									<div className="red_text"><i><T>Nulstilling af adgangskode mislykkedes</T></i></div>
								</RenderIf>
							</div>
							<div className="w-100 mt-5">
								<p className="text-bold"><T>Gentag adgangskode</T></p>
								<Input
									type="password"
									onChange={(e) => setRePassword(e.target.value)}
									className="text-weight-reg py-3"
									name="password"
									form="forgot-password"
									value={rePassword}
								/>
								<RenderIf condition={showNonMatchingPassword}>
									<div className="red_text"><i><T>Kodeordene skal være ens</T></i></div>
								</RenderIf>
								<RenderIf condition={resetSuccess}>
									<div className="my-2 py-2 k-btn-sec text-bold text-center"><T>Nulstil kodeord. Omdirigerer til login.</T></div>
								</RenderIf>
							</div>
							<div className="mt-5 text-center">
								<Kbutton onClick={submit} title={<T>Nulstille kodeord</T>} custom=" py-3 px-5" prim md></Kbutton>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Container>
	)
}

const Processing = () => {
	return (
		<div className="event_signup_modal w-100">
			<div style={{ height: "180px" }} className="d-flex flex-row justify-content-center align-items-center">
				<T>Processing</T><DotLoader />
			</div>
		</div>
	);
}

export default LoginModal;
