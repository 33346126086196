import React from "react";

const LandingDescriptionText = ( { description = undefined } ) => {

    return (
        <div className="container">
            <div className="mt-4 grey text-md text-lineheight-24 text-weight-reg" dangerouslySetInnerHTML={{ __html: description && description }} />
        </div>
    );
};

export default LandingDescriptionText;