import languagedefs from './languagedefs.json';

const array2set = (xs) => {
	let set = {};
	for (const x of xs || []) set[x] = true;
	return set;
};


const languages = languagedefs.map((x) => x.lang);
const language_set = array2set(languages);
const DEFAULT_LANGUAGE = "da"

const url_lang = () => {
	let lang = DEFAULT_LANGUAGE
	const try_lang = window.location.pathname
		.split('/')
		.slice(1)[0]
		.toLowerCase();
		if(language_set[try_lang]){
		lang = try_lang
	}
	if(language_set[try_lang]){
		lang = try_lang
	}

	for (const languagedef of languagedefs) {
		if (languagedef.lang === lang) {
			return languagedef;
		}
	}
};

export { languages, language_set, languagedefs, url_lang, DEFAULT_LANGUAGE };
