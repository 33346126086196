import React from "react"
import { T } from "../../traks";

const NoContent = () => {
	return (
		<div className="py-4">
            <T>Content not found</T>
    	</div>
    );
};

export default NoContent;

