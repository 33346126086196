import React, { useEffect, useState } from "react";
import Header from "../c/Header";
import Footer from "../c/Footer";
import Finder from "../c/Finder";
import LandingSliderSelector from '../c/LandingSliderSelector';
import GetHelp from '../c/GetHelp';
import FixedContactButton from "c/FixedContactButton";
import { APIGet } from "../API";
import { AlertModal, RenderIf } from "../c/Reusables";
import LandingDescriptionText from "../c/LandingDescriptionText";
import { VenueInquire } from "../c/SharedVenueComponent";
import LandingVenueCards from "../c/LandingVenueCards";
import FAQ from "../c/FAQ";
import { useParams } from "react-router-dom";
import Map from "../c/Map";
import { T } from "../traks";

const LandingCategories = ({ urlParams }) => {
	
	urlParams = useParams();
	const landingId = urlParams.kategoriSlug;
	
	const [individualPageData, setIndividualPageData ] = useState(undefined);

	const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });

	useEffect(() => {
		const getIndividualLocation = () => {
			APIGet("landingpages/" + landingId + "?pageType=categories").then(res => {
				setIndividualPageData(res.data.landing_page);
			}).catch(err => {
				err.response && setAlertType({ type: "error", text: err.response.data });
				err.response && setShowAlert(true);
			})
		};
		landingId && getIndividualLocation();
        // eslint-disable-next-line
    }, [])

	return (
		<>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<RenderIf condition={landingId && individualPageData}>
				<LandingCategoriesIndividualPage individualPageData={individualPageData} />
			</RenderIf>
			<RenderIf condition={!landingId}>
				<LandingCategoriesPage />
			</RenderIf>
		</>
	);
};

const LandingCategoriesIndividualPage = ({ individualPageData }) => {

	const allVenues = [
		...((individualPageData && individualPageData.pageContent.venues1) || []),
		...((individualPageData && individualPageData.pageContent.venues2) || []),
		...((individualPageData && individualPageData.pageContent.venues3) || [])
	];

	const uniqueVenues = [];
	const seenIds = new Set();
	
	allVenues.forEach(venue => {
		if (!seenIds.has(venue.id)) {
			uniqueVenues.push(venue);
			seenIds.add(venue.id);
		}
	});
	
	return (
		<>
			<Header transparent />
			<Finder customImg={individualPageData.pageContent.imagesUrls[0]} customText={"Find og book dit " + individualPageData.title} landingPage />
			<div className="d-block d-lg-flex container">
				<div className="col-lg-9 me-4 my-5 my-lg-0">
					<LandingDescriptionText description={individualPageData.pageContent.description} />
				</div>
				<div className="col-lg-3 mt-0 mt-lg-4">
					<VenueInquire data={individualPageData} />
				</div>
			</div>
			<div id="venueList">
				<LandingVenueCards venueData={individualPageData.pageContent.venues1} title={individualPageData.pageContent.venues1Title} />
				<LandingVenueCards venueData={individualPageData.pageContent.venues2} title={individualPageData.pageContent.venues2Title} />
				<LandingVenueCards venueData={individualPageData.pageContent.venues3} title={individualPageData.pageContent.venues3Title} />
			</div>
			<FAQ faq={individualPageData && individualPageData.pageContent.faq} title={individualPageData && "Ofte stillede spørgsmål når du skal booke et " + individualPageData.title} />
			<div className="container my-5 pt-3 pb-5 d-block d-xl-flex">
				<div style={{ background: "#fff", overflowY: "auto", height:"400px", borderRadius:"10px"}} className="col-12">
					<Map data={uniqueVenues && uniqueVenues} customCss={"regionmap"} loc={individualPageData && individualPageData.title} />
				</div>
			</div>
			<LandingSliderSelector category />
			<GetHelp />
			<div className="mb-5"/>
			<Footer />
			<FixedContactButton />
		</>
	);
};

const LandingCategoriesPage = () => {

	return (
		<>
			<Header transparent />
			<Finder customText={<T>Book dit næste møde- og konferencested nemt her</T>} />
			<LandingSliderSelector category={true} />
			<GetHelp />
			<div className="mb-5"/>
			<Footer />
			<FixedContactButton />
		</>
	);
};

export default LandingCategories;
