import React from "react";
import { T } from "../../../traks";
import Kbutton from "../../KButton";
import { VerifyDelete } from "../../Reusables";


const LandingSaveDelete = ({ saveLandingCategory, deleteLandingCategory }) => {

  	return (
        <div className="d-flex">
            <Kbutton
                onClick={() => saveLandingCategory()}
                custom="px-5 py-2 my-4 mb-4 me-3"
                prim
                md
                >
                <T>Opdater</T>
            </Kbutton>
            <VerifyDelete
                deleteAction={() => deleteLandingCategory()}
                deleteText={<T>Delete landing kategori</T>}
                prompt={<T>Type 'delete item' to delete the item</T>}
                verifyText="delete item" 
            />
        </div>
  	);
};

export default LandingSaveDelete;