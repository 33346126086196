import React, { useState } from "react";
import { Input, Row, Col } from "reactstrap";
import { T, TraksConsumer } from "../traks";
import InquiryModal from "./modals/InquiryModal";
import Kbutton from './KButton';

const GetHelp = () => {

	return (
		<div className="container py-2 position-relative">
			<div className="d-flex justify-content-center text-center mt-0 mt-lg-4">
				<div>
					<h2 className="mt-5"><T>Få gratis hjælp og rådgivning</T></h2>
					<div>
						<div style={{ maxWidth: 610 }} className="grey text-xl text-weight-reg lead mt-3 pb-2 text-center">
							<T>Find det perfekte møde- og konferencested</T>
						</div>
					</div>
					<div>
						<div style={{ maxWidth: 610 }} className="grey text-xl text-weight-reg lead mb-4 pb-2 text-center">
							<T>Få ekspertenes bud på det helt rigtige sted til jeres næste møde, konference eller event</T>
						</div>
					</div>
				</div>
			</div>
			<TraksConsumer>
				<ContactForm />
			</TraksConsumer>
		</div>
	);
}

const ContactForm = (props) => {

	const coverImage = <img className="rounderborder gethelp_img" width="100%" height="540" alt="header" src="https://static.optimeet.dk/imgs/contact_1290x600-3.webp"
		srcSet="https://static.optimeet.dk/imgs/contact_2580x1200-3.webp 2x" />

	const Form = () => {

		const [properties, setProperties] = useState({
			"telephone": "",
			"email": "",
		});
	
		return (
			<div className="w-100 d-flex flex-column align-items-center align-items-md-end ">
				<div className="me-xs-0 me-lg-4 py-3 px-4 bg-white border_radius_24 contact-form-mobile w-100" style={{maxWidth: 480}}>
					<div className="mx-1">
						<h4 className="text-center mt-4 mb-4"><T>Kontakt os</T></h4>
						<p className="text-sm pt-3"><T>TELEFON NR.</T></p>
						<Input
							type="kform"
							className="py-3 text-weight-reg hoverfxlight"
							placeholder={props.render_static(<T>Dit telefonnummer</T>)}
							onChange={(e) => setProperties({ ...properties, telephone: e.target.value })}
							name="telefon"
							id="telefon"
							form="help"
							value={properties.telephone}
						/>
						<p className="text-sm pt-4"><T>EMAIL</T></p>
						<Input
							type="kform"
							className="py-3 text-weight-reg hoverfxlight"
							placeholder={props.render_static(<T>Dit email</T>)}
							onChange={(e) => setProperties({ ...properties, email: e.target.value })}
							name="email"
							id="email"
							form="help"
							value={properties.email}
						/>
						<div className="text-center pt-4 pt-md-4 pb-3 pb-md-4">
							<InquiryModal fields={properties}>
								<Kbutton custom="px-5 mt-3 mb-1 py-2" prim md lg><T>Kontakt os</T></Kbutton>
							</InquiryModal>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const FormWrapperDesktop = () => {
		
		return (
			<div className="position-relative">
			<div>{coverImage}</div>
			<div className="position-absolute top-0 w-100 h-100 d-flex align-items-center">
				<div className="position-relative w-100">
					<Row className="d-flex px-5 mx-0 mx-md-5 px-md-4 mx-lg-0">
						<Col className="d-none d-lg-block ps-4 ps-xl-5 text-xxl text-white text-center">
							<div className="h-100 d-flex align-items-center flex-col">
								<div className="w-100 font-primary" style={{lineHeight: "2.6rem"}}>
									<div>
										<div className="mt-3 text-weight-reg fst-italic"><T>Kontakt os i dag og få et</T></div>
										<div className="mb-4 text-bold"><T>uforpligtende tilbud.</T></div>
									</div>
									<div className="py-2">
										<div className="mt-3 text-weight-reg fst-italic"><T>Eller ring på tlf.</T></div>
										<div className="text-bold" style={{fontSize: 40}}><T>+45 33 97 43 43</T></div>
									</div>
								</div>
							</div>
						</Col>
						<Col>
							<Form />
						</Col>
					</Row>
				</div>
				<img src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_arrow_curved.svg" alt="arrow-icon" className="icon-arrow" />
			</div>
		</div>
		)
	}

	return (
		<>
			<div className="d-none d-md-block">
				<FormWrapperDesktop />
			</div>
			<div className="d-block d-md-none">
				<Form />
			</div>
		</>
	);
}

export default GetHelp;