import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { T } from "../traks";
import Kbutton from "c/KButton";
import { Input, Alert, Tooltip } from "reactstrap";
import LDropDown from "./LDropdown";
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';
import "../css/multiRangeSlider.css";

export const VenueVideo = ({ url }) => {
	const containerStyle = {
		position: 'relative',
		paddingTop: '56.25%', // 16:9 Aspect Ratio
		height: 0,
		overflow: 'hidden',
	};

	const playerStyle = {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	};

	return (
		<div style={containerStyle}>
			<ReactPlayer
				url={url}
				style={playerStyle}
				width="100%"
				height="100%"
				controls
				playing
				config={{
					youtube: {
						playerVars: { showinfo: 1, controls: 1, rel: 0, modestbranding: 1, vq: 'hd1080', },
					},
				}}
			/>
		</div>
	);
};

export const Dollars = (props) => {
	const amount = props.count ? props.count : 4;
    let count_dollars = amount;

    const dollar_elem = (
        <img width={10} height={21} key={props.count} src="https://static.optimeet.dk/gfx/ico_dollar-0.svg" alt='iconDollar' />
    );
    const dollar_empty_elem = (
        <img width={10} height={21} key={props.count} src="https://static.optimeet.dk/gfx/ico_dollar_grey-0.svg" alt='icon0Dollar' />
    );
    let dollars = [dollar_elem];

	const setPriceRating = (e) => {
		if (props.editVenue){
			props.setValue(Number(e + 1));
		}
	}

    if(count_dollars) {
        dollars = [<span onClick={() => {setPriceRating(0)}} key={props} style={{paddingRight: "5px"}}>{dollar_elem}</span>];
        for(let i = 0; i < count_dollars - 1; i++) {
            dollars.push(<span onClick={() => {setPriceRating(i+1)}} key={i} style={{paddingRight: "5px"}}>{dollar_elem}</span>);
        }
        for(let i = 0; i < (4 - count_dollars ); i++) {
            dollars.push(<span onClick={() => {setPriceRating(amount+i)}} key={i+"empty"} style={{paddingRight: "5px"}}>{dollar_empty_elem}</span>);
        }
    }
    return dollars;
}

export const DotLoader = ({ children=[], className = 'text-xxl', interval = 250 }) => {
	const [dots, setDots] = useState('.');

	useEffect(() => {
		const timer = setTimeout(() => {
			setDots((d) => {
				return d.length === 5 ? '.' : d + ' .';
			});
		}, interval);

		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dots]);

	return (
		<div className={className}>
			<div><img width={161} height={50} src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico-logo-color-0.svg" alt='logo' /></div>
			<div className='me-3'>{children}&nbsp;{dots}</div>
		</div>
	);
};

export const isValidEmail = (email) => {
	return /\S+@\S+\.\S+/.test(email);
}

export const passwordValidator = (password) => {
	const re = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
	if (!re.test(password)) {
		return false
	} else {
		return true
	}
}

export const rePasswordValidator = (rePassword, password) => {
	if (rePassword !== password) {
		return false
	} else {
		return true
	}
}

export const RenderIfFallback = ({ children, condition }) => {
	return ((condition && condition !== undefined) ? <>{children}</> : <><div className='text-center my-5 py-5'><DotLoader /></div></>)
}

export const RenderIf = ({ children, condition }) => {
	return ((condition && condition !== undefined) ? <>{children}</> : <></>)
}

export const VenueImg = ({ img, ...props }) => {

	const isMobile = window.innerWidth <= 768;

	let srcSet = ""
	if(img === undefined)
		return(<></>)

	let src = img
	const isStaticOptimeet = props.supplier ? img.includes("optimeet.dk/suppliers/") : img.includes("optimeet.dk/venues/")
	if(isStaticOptimeet){
		const regx = props.supplier ? /(.*suppliers\/)(2x_)?(.*)/ : /(.*venues\/)(2x_)?(.*)/
		if (props.card) {
			src = img.replace(regx, "$1card-$3")
			srcSet = isMobile ? src : img.replace(regx, "$1card_2x-$3 2x")
		} else if (props.cover) {
			src = img.replace(regx, "$1cover-$3")
			srcSet = isMobile ? src : img.replace(regx, "$1cover_2x-$3 2x")
		} else if (props.full_size) {
			src = img.replace(regx, "$1full_size-$3")
			if(img.includes("2x_")){
				srcSet = img.replace(regx, "$1full_size_2x-$3 2x")
			}
		}
	}

	if (srcSet !== "")
		return (
			<img
				onClick={props.onClick}
				srcSet={srcSet}
				src={src}
				className={props.className + " zoomable-image"}
				width={props.width}
				height={props.height}
				alt={props.alt}
			/>
		)
	else
		return (
			<img
				onClick={props.onClick}
				src={src}
				className={props.className + " zoomable-image"}
				width={props.width}
				height={props.height}
				alt={props.alt}
			/>
		)
}

export const ToggleSwitch = ({ handleToggle, isOn }) => {
	return (
		<>
			<input
				checked={isOn}
				onChange={handleToggle}
				className="switch-checkbox"
				id="switch-new"
				type="checkbox"
			/>
			<label
				className="switch-label"
				htmlFor="switch-new"
			>
				<span className="switch-button" />
			</label>
    	</>
	);
};

export const VerifyDelete = ({verifyText, deleteAction, deleteText, prompt}) => {

	const [verifySate, setVerifyStage] = useState(0);
	const [value, setValue] = useState('');

	const checkInput = (e) => {
		if (e === verifyText) {
			setVerifyStage(2);
		}
	};
	const complete = () => {
		setVerifyStage(0);
		deleteAction();
	};

	return (
		<>
			<div className="d-flex align-items-center">
				<div>
					<Kbutton onClick={() => verifySate === 0 && setVerifyStage(1)} custom="px-5 py-2" danger md>
						{verifySate === 0 && <span>{deleteText}</span>}
						{verifySate === 1 &&
							<span>{prompt}
								<Input className="text-weight-reg input-light-blue"
									autoFocus={true}
									value={value}
									onChange={(e) => {setValue(e.target.value); checkInput(e.target.value)}}
								/>
							</span>
						}
						{verifySate === 2 && <Kbutton onClick={() => complete()} custom="p-2" danger noborder md><T>Click to delete permanently</T></Kbutton>}
					</Kbutton>
				</div>
			</div>
    	</>
	);
};

export const AlertModal = ({ text, type, isShowing, setIsShowing }) => {
	let className = type + " position-relative"

	useEffect(() => {
		if (isShowing)
			window.setTimeout(() => {
				setIsShowing(false);
		}, 3000);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShowing])

	const portalContainer = document.getElementById("root");
		return isShowing && (
			ReactDOM.createPortal(
				<React.Fragment>
					<div className="alert-modal" >
						<Alert isOpen={isShowing} className={className}>
							<div className="d-flex justify-content-between border_radius_6px" onClick={() => setIsShowing(false)}>
								<div className="fw_600 me-5">
									{text}
								</div>
								<RenderIf condition={isShowing !== null}>
									<img className="btn p-1 rounded-circle" width={25} height={25} src="https://static.optimeet.dk/gfx/ico_close.svg" alt='icoClose' />
								</RenderIf>
							</div>
						</Alert>
					</div>
				</React.Fragment >,
			portalContainer)
		)
}

export const RedigerBtn = () => {
	return (
		<>
			<div className="d-flex align-items-center justify-between">
				<div className="d-flex">
					<span className="k-btn k-btn-light px-3 py-1 grey">
						<img className='mb-1 me-1' alt="thumb" width={14} height={14} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_edit.svg"} />
						<T>Rediger</T>
					</span>
				</div>
			</div>
    	</>
	);
};

export const CustomFilter = (props) => {

	const [activeFilter, setActiveFilter] = useState(false);
	const [direction, setDirection] = useState(undefined);
	const [isCleared, setIsCleared] = useState(true);
	const data = props.data;
	const filterOptions = props.filterOptions;
	const arrowCss = direction !== undefined ? 'me-2' : '';
	const css = " " + props.css;

	useEffect(() => {
		setIsCleared(!isCleared);
		setActiveFilter(false);
		setDirection(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isCleared]);

	const Sort = (e) => {
		let filterName = e.dataName;
		setActiveFilter(e.type);
		if (e.type === 0) { //sort by character
			e.type !== activeFilter ? props.setData([...data].sort((a, b) => a[filterName].localeCompare(b[filterName]))) : props.setData([...data].sort((a, b) => b[filterName].localeCompare(a[filterName])));
		} else if (e.type === 1) { //sort by number or bool
			e.type !== activeFilter ? props.setData([...data].sort((a, b) => a[filterName] - b[filterName])) : props.setData([...data].sort((a, b) => b[filterName] - a[filterName]));
		} else if (e.type === 3) { //hardcoded sort for price object[0]
			const sorted = [...data].sort((a, b) => {
				if (e.type !== activeFilter) {
					return a.products[filterName].price - b.products[filterName].price;
				} else {
					return b.products[filterName].price - a.products[filterName].price;
				}
			});
			props.setData(sorted);
		}

		activeFilter === e.type ? setDirection(true) : setDirection(false);
		activeFilter === e.type && setActiveFilter(!activeFilter);
	};

	return (
		<>
			<span className={css}>
				<LDropDown
					sendAll
					className="rounded-pill"
					data={filterOptions}
					defaultVal={isCleared}
					placeholder={"Sorter"}
					setVal={(e) => { Sort(e) }}
				>
					<span className={arrowCss}>{direction === undefined ? '' : direction ? '↑' : '↓'}</span>
				</LDropDown>
			</span>
    	</>
	);
};

export const TStoReadable = (props) => {

	const date = new Date(props.date * 1000);
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const hours = date.getHours();
	const days = date.getDate();
	const months = date.getMonth() + 1;
	const years = date.getFullYear();

	return (
		<>
			{props.dateOnly ?
				<span className="text-bold">{days}/{months}/{years}</span>
				:
				<span>{hours}:{minutes}&nbsp;{days}/{months}/{years}</span>
			}
    	</>
	);
};

export const NewsTStoReadable = (props) => {

	const monthNames = ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'];
	const date = new Date(props.createdTs * 1000);
	const month = monthNames[date.getMonth()];
	const day = date.getDate();
	const year = date.getFullYear();

	return (
		<>
			<span>{day}.&nbsp;{month},&nbsp;{year}</span>
    	</>
	);
};

const PaginatinPreviousPages = ({ page, setPage }) => {
	const tooManyPages = page > 2
	const beforePages = page - 1

	return (
		<RenderIf condition={page > 1}>
			<RenderIf condition={tooManyPages}>
				<span className="font-primary text-bold px-3 p-2 mx-1" onClick={() => setPage(1)}>{1}</span>
				<span className="font-primary text-bold me-2">·  ·  ·</span>
			</RenderIf>
			<RenderIf condition={!tooManyPages}>
				{Array.from(Array(beforePages).keys()).map(el =>
					<span key={beforePages + "prev"} className="font-primary text-bold px-3 p-2 mx-1" onClick={() => setPage(el+1)}>{el + 1}</span>
				)}
			</RenderIf>
		</RenderIf>
	)
};

const PaginationNextPages = ({ page, totalPages, setPage }) => {
	const tooManyPages = totalPages - page > 2
	const pages = totalPages - page

	return (
		<RenderIf condition={page < totalPages}>
			<RenderIf condition={tooManyPages}>
				<span className="font-primary text-bold ms-2">·  ·  ·</span>
				<span className="font-primary text-bold px-3 p-2 mx-1" onClick={() => setPage(totalPages)}>{totalPages}</span>
			</RenderIf>
			<RenderIf condition={!tooManyPages}>
				{Array.from(Array(pages).keys()).map(el =>
					<span key={pages + el} className="font-primary text-bold pe-3 mx-1" onClick={() => setPage(el+page+1)}>{el + page+1}</span>
				)}
			</RenderIf>
		</RenderIf>
	)
};

export const Pagination = ({ setPage, page, totalPages }) => {
	return (
		<div className="pt-4 mb-5 text-center cursor_pointer">
			{page > 1 && <span onClick={() => setPage("previous")} className="p-2 pagination-round cursor_pointer hoverfxbright" disabled={page === 1}>
				<img className='mx-1' style={{ transform: 'rotate(90deg)' }} width={14} height={8} src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_dropdown_toggle_open.svg" alt='iconArrowPrev' />
			</span>}
			<PaginatinPreviousPages page={page} setPage={setPage} />
			<span className="font-primary text-bold pagination-round px-3 py-2 mx-1 hoverfxbright">{page}</span>
			<PaginationNextPages page={page} totalPages={totalPages} setPage={setPage}/>
			{page !== totalPages && <span onClick={() => setPage("next")} className="p-2 pagination-round cursor_pointer hoverfxbright" disabled={page === totalPages}>
				<img className='mx-1' style={{ transform: 'rotate(270deg)' }} width={14} height={8} src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_dropdown_toggle_open.svg" alt='iconArrowNext' />
			</span>}
		</div>
	);
};

export const PriceInfo = (props) => {

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggleTooltip = () => {
		setTooltipOpen(!tooltipOpen);
	};

	const DayMeet = (
		<div className='hoverfx'>
			<img id="tip1" style={{ filter: 'brightness(0.6)'}} width={14} height={14} className="mb-1 me-2 ms-1" src="https://static.optimeet.dk/gfx/ico_info_white-0.svg" alt='iconinfo' />
			<Tooltip fade={false} className="custom-tooltip" isOpen={tooltipOpen} target="tip1" toggle={toggleTooltip}>
			<div>
				<div className='text-bold py-2 px-3 text-start'><T>Definition på pris dagmøde:</T></div>
					<ul className="text-start" style={{fontSize: 12}}>
						<li className='py-1'><T>Dagmøde er pr. person og inkl. moms (Kl. 09:00 - 17:00)</T></li>
						<li className='py-1'><T>Formiddagskaffe med brød</T></li>
						<li className='py-1'><T>Frokost med 1 stk. drikkevare pr. person</T></li>
						<li className='py-1'><T>Eftermiddagskaffe med kage</T></li>
						<li className='py-1'><T>Plenumlokale med standard AV-udstyr</T></li>
						<li className='py-1'><T>1 grupperum pr. 10 personer</T></li>
					</ul>
				</div>
			</Tooltip>
		</div>
	);

	const GroupMeet = (
		<div className='hoverfx'>
			<img id="tip2" style={{ filter: 'brightness(0.6)'}} width={14} height={14} className="mb-1 me-2 ms-1" src="https://static.optimeet.dk/gfx/ico_info_white-0.svg" alt='iconinfo' />
			<Tooltip fade={false} className="custom-tooltip" isOpen={tooltipOpen} target="tip2" toggle={toggleTooltip}>
				<div>
					<div className='text-bold py-2 px-3 text-start'><T>Definition på pris Kursusdøgn:</T></div>
					<ul className="text-start" style={{fontSize: 12}}>
						<li className='py-1'><T>Møde inkl. overnatning er pr. person og inkl. moms (Kl. 09:00 - 09:00)</T></li>
						<li className='py-1'><T>Formiddagskaffe med brød</T></li>
						<li className='py-1'><T>Frokost med 1 stk. drikkevare pr. person</T></li>
						<li className='py-1'><T>Eftermiddagskaffe med kage</T></li>
						<li className='py-1'><T>Plenumlokale med standard AV-udstyr (+ evt. grupperum efter aftale)</T></li>
						<li className='py-1'><T>2-retters middag med 1 stk. drikkevare pr. person</T></li>
						<li className='py-1'><T>Overnatning (i enkelt eller dobbelt værelse)</T></li>
						<li className='py-1'><T>Morgenmad</T></li>
					</ul>
				</div>
			</Tooltip>
		</div>
	);

	const custom = (
		<div className='hoverfx'>
			<img id="tip3" style={{ filter: 'brightness(0)'}} width={18} height={18} className="mb-1 me-2 ms-1" src="https://static.optimeet.dk/gfx/ico_info_white-0.svg" alt='iconinfo' />
			<Tooltip fade={false} className="custom-tooltip" isOpen={tooltipOpen} target="tip3" toggle={toggleTooltip}>
				<div className='py-1'>{props.custom}</div>
			</Tooltip>
		</div>
	);

	const content = props.custom ? custom : props.DayMeet ? DayMeet : props.GroupMeet ? GroupMeet : null;

  	return (
		<>
			{content}
		</>
  	);
};

export const HorizontalSlider = ({ min, max, savedValues, onChange, unit, roundValue, reset}) => {
	const minValRef = useRef(min);
	const maxValRef = useRef(max);
	const [minVal, setMinVal] = useState(min);
	const [maxVal, setMaxVal] = useState(max);
	const range = useRef(null);
	const round = roundValue ? 10 : 1;

	useEffect(() => {
		setMinVal(min);
		setMaxVal(max);
		minValRef.current = min;
		maxValRef.current = max;
		// eslint-disable-next-line
	}, [reset]);

	useEffect(() => {
		if ((savedValues !== undefined && savedValues.min && savedValues.min !== min) || ( savedValues !== undefined && savedValues.max && savedValues.max !== max)) {
			setMinVal(savedValues.min);
			setMaxVal(savedValues.max);
			minValRef.current = savedValues.min;
			maxValRef.current = savedValues.max;
		}
		// eslint-disable-next-line
	}, [savedValues]);

	const getPercent = useCallback (
		(value) => Math.round(((value - min) / (max - min)) * 100),
		[min, max]
	);

	// Width left side
	useEffect(() => {
		const minPercent = getPercent(minVal);
		const maxPercent = getPercent(maxValRef.current);
		if (range.current) {
			range.current.style.left = `${minPercent}%`;
			range.current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [minVal, getPercent]);

	// Width right side
	useEffect(() => {
		const minPercent = getPercent(minValRef.current);
		const maxPercent = getPercent(maxVal);
		if (range.current) {
			range.current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [maxVal, getPercent]);

	const [minMaxPrice, setMinMaxPrice] = useState({
		price_range: { min: undefined, max: undefined }
	});

	const originalPrice = React.useRef("");
	const handlePriceChange = (prices) => {
		originalPrice.current = prices;
		setMinMaxPrice({ price_range: { min: prices.min, max: prices.max } })
	};

	useEffect(() => {
		const timer = setTimeout(() => {
		  	if (minMaxPrice.price_range && minMaxPrice.price_range.min !== undefined && minMaxPrice.price_range.min === originalPrice.current.min && minMaxPrice.price_range.max === originalPrice.current.max) {
				if (minMaxPrice.price_range.min === min && minMaxPrice.price_range.max === max) {
					onChange({ min: '', max: '' });
				} else {
					onChange({ min: minMaxPrice.price_range.min, max: minMaxPrice.price_range.max } );
				}
		  	}
		}, 700);
		return () => {
		  	clearTimeout(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minMaxPrice]);


	return (
		<div className="containerslider py-3">
			<input
				type="range"
				value={minVal}
				min={min}
				max={max}
				onChange={(event) => {
					const value = Math.round(Math.min(Number(event.target.value), maxVal - 1) / round) * round;
					setMinVal(value);
					minValRef.current = value;
					handlePriceChange({ min: value, max: maxVal });
				}}
				className="thumb thumb--left hoverfx"
				style={{ zIndex: minVal > max - 100 && "5" }}
			/>
			<input
				type="range"
				value={maxVal}
				min={min}
				max={max}
				onChange={(event) => {
					const value = Math.round(Math.max(Number(event.target.value), minVal + 1) / round) * round;
					setMaxVal(value);
					maxValRef.current = value;
					handlePriceChange({ min: minVal, max: value });
				}}
				className="thumb thumb--right hoverfx"
			/>
			<div className="slider">
				<div className="slider__track" />
				<div ref={range} className="slider__range" />
				<div className="slider__left-value">min. {minVal} {unit && unit}</div>
				<div className="slider__right-value">max. {maxVal} {unit && unit}</div>
			</div>
		</div>
	);
};
HorizontalSlider.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired
};