import React, { useState, useEffect } from "react";
import { Input, Row, Col } from "reactstrap";
import { T, TraksConsumer } from "../traks";
import InquiryModal from "./modals/InquiryModal";
import Kbutton from './KButton';

const GetHelp = ({ regionName }) => {
	return (
		<div className="container py-5 position-relative">
			<TraksConsumer>
				<ContactForm regionName={regionName} />
			</TraksConsumer>
		</div>
	);
}

const ContactForm = (props) => {

	const [imgUrl, setImgUrl] = useState(undefined);

	useEffect(() => {
		if (props.regionName === "Sweden") {
			setImgUrl("https://skyticket.com/guide/wp-content/uploads/2017/09/iStock-524745763-e1516259747348.jpg");
		}
		if (props.regionName === "Germany") {
			setImgUrl("https://llandscapes-10674.kxcdn.com/wp-content/uploads/2022/08/Neuschwanstein-Castle-1280x640.jpg.webp");
		}
		if (props.regionName === "Norway") {
			setImgUrl("https://travelguide.nettavisen.no/wp-content/uploads/2015/03/MoskenesLofoten.jpeg");
		}
	}, [props.regionName]);

	const coverImage = <img className="rounderborder gethelp_img" width="100%" height="510" alt="header" src={imgUrl} srcSet={imgUrl} />

	const Form = () => {

		const [properties, setProperties] = useState({
			"telephone": "",
			"email": "",
/* 			"region": props.regionName //important */
		});
	
		return (
			<div className="w-100 d-flex flex-column align-items-center align-items-md-end ">
				<div className="me-xs-0 shadow me-lg-4 py-3 px-4 bg-white border_radius_24 contact-form-mobile w-100" style={{ maxWidth: 480 }}>
					<div className="mx-1">
						<h3 className="text-center mt-4 mb-4">Find unique meeting locations in {props.regionName}</h3>
						<p className="text-sm pt-4"><T>TELEFON NR.</T></p>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							placeholder={props.render_static(<T>Dit telefonnummer</T>)}
							onChange={(e) => setProperties({ ...properties, telephone: e.target.value })}
							name="telefon"
							id="telefon"
							form="help"
							value={properties.telephone}
						/>
						<p className="text-sm pt-4"><T>EMAIL</T></p>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							placeholder={props.render_static(<T>Dit email</T>)}
							onChange={(e) => setProperties({ ...properties, email: e.target.value })}
							name="email"
							id="email"
							form="help"
							value={properties.email}
						/>
						<div className="text-center pt-4 pt-md-4 pb-3 pb-md-4">
							<InquiryModal fields={properties}>
								<Kbutton custom="px-5 mt-3 mb-1 py-2" prim md lg><T>Kontakt os</T></Kbutton>
							</InquiryModal>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const FormWrapperDesktop = () => {
		
		return (
			<div className="position-relative">
				<div>{coverImage}</div>
				<div className="position-absolute top-0 w-100 h-100 d-flex align-items-center">
					<div className="position-relative w-100">
						<Row className="d-flex">
							<Col>
								<Form />
							</Col>
							<Col className="d-none d-lg-block"/>
						</Row>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			{imgUrl &&
				<>
					<div className="d-none d-md-block">
						<FormWrapperDesktop />
					</div>
					<div className="d-block d-md-none">
						<Form />
					</div>
				</>
			}
		</>
	);
}

export default GetHelp;