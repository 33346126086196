import React, { useEffect, useState } from "react";
import { APIPost } from "API";
import { Col, Row, NavLink } from "reactstrap";
import Kbutton from './KButton';
import { PromotedCard } from './Cards';
import { T } from '../traks';
import { LL } from "Routes";

const SelectedLocations = () => {

	const eventTypeData = [ // XX mockData
		{
			name: "Hovedstadsområdet",
			id: "2e8764e6-20e4-4573-9d10-5b398a922bc6",
		},
		{
			name: "Region Sjælland",
			id: "9a889b46-328f-49ec-8cc4-c2ddb6bbd347",
		},
		{
			name: "Region Midtjylland",
			id: "7b0d9ac5-fded-4d74-97d0-f794dc514a1a",
		},
		{
			name: "Region Syddanmark",
			id: "b15f4331-4d13-4e26-b638-6cd3440fafdc",
		},
		{
			name: "Region Nordjylland",
			id: "c0192b26-6708-4994-80e5-5031ba1235ba",
		},
		{
			name: "Sverige",
			id: "36deec5c-b207-41fc-9acb-23caa1801902",
		},
		{
			name: "Tyskland",
			id: "05969ea8-df36-4c17-af17-d994c0b5e8c5",
		},  
	];

	const [queryLocations, setQueryLocations] = useState(undefined);

	const locations = (k, i) => {
		if (k.length === 0) {
			return null;
		}
		return (
			<span key={i} onClick={() => setQueryLocations(k.id)}>
				<span className='px-2 px-sm-3 grey text-md text-weight-reg btn'>{k.name}</span>
			</span>
		);
	}

	const [promotedLocationsData, setPromotedLocationsData] = useState(undefined);
	const dataText = promotedLocationsData ? <T>No venues found</T> : <T>Loading venues</T>;

	useEffect(() => {
		const body = queryLocations ? {query: {limit: 4, page: 0, locationIds: [queryLocations]}} : {query: {limit: 4, page: 0 }}; //XX 'selected locations' endpoint? {query: {limit: 4, page: randomPage, locationIds: [queryLocations]}}; //XX 'selected locations' endpoint? : {query: {limit: 4, page: 0}}; //XX 'selected locations' endpoint?
		APIPost("search", body).then(res => {
			setPromotedLocationsData(res.data.result);
		}).catch(err => console.log(err));
		// eslint-disable-next-line
	}, [queryLocations]);

	return (
		<div className="container mt-5">
			<h2 className="justify-content-start justify-content-lg-center d-flex"><T>Udvalgte muligheder til dig</T></h2>
			<div className="d-flex p-0 m-0 my-4 text-center justify-content-center">
				<div className="container m-0 p-0">
					<div className="justify-content-start text-start text-lg-center justify-content-lg-center font-primary">
						{eventTypeData.map((k, i) => locations(k, i))}
					</div>
				</div>
			</div>
			<div>
				{promotedLocationsData && promotedLocationsData.length && promotedLocationsData !== undefined ?
				<div className="container promoted-cards-wrapper px-0">
					<Row className="scrolling-wrapper-flexbox scroll-smooth font-primary">
						{promotedLocationsData.map((v, i) => (
							<Col key={i} xs={12} sm={6} md={6} lg={3} xl={3} className="d-flex mb-0 mb-sm-4 mb-lg-0 justify-content-center promoted-card-container">
								<>
									<PromotedCard
										data={v} key={"promoted" + i}
									/>
								</>
							</Col>
						))}
					</Row>
				</div>
				:
				<div className="py-5 my-5 text-center">{dataText}</div>}
			</div>
			<div className="text-md-center text-sm-left pt-5 d-flex justify-content-center w-100">
				<NavLink href={LL("/konferencesteder/")} className="full-mobile"><Kbutton lg grey custom=" btn text-black w-100" md><T>Alle mødesteder</T></Kbutton></NavLink>
			</div>
		</div>
	);
};

export default SelectedLocations;
