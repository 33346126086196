import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { divIcon, point } from 'leaflet'
import { MapCard } from './Cards';
import L from 'leaflet';
import BaseMarkerCluster from '@changey/react-leaflet-markercluster';
import { TraksConsumer } from "../traks";

const Map = (props) => {

	const venues = props.data.length ? props.data : [props.data];
	const [position, setPosition] = useState(undefined);
	const [zoom, setZoom] = useState(undefined);

	useEffect(() => {
		setPosition(props.data.length !== 0 ? [venues[0].geoLatLong.lat, venues[0].geoLatLong.lon] : [55.395919, 10.388081]);
		setZoom(props.data.length > 1 ? 8 : 11);
		// eslint-disable-next-line
	}, []);
	
	const customCss = props.customCss;

	let locIcon = L.icon({
		iconUrl: "https://static.optimeet.dk/gfx/mapIcon.svg",
		iconRetinaUrl: "https://static.optimeet.dk/gfx/ico_maplocation-1.svg",
		iconSize: [40, 40],
		iconAnchor: [20, 40],
	});

	if(venues !== null && venues !== undefined && position !== undefined) {
		return (
			<div className="text-center">
				<MapContainer className={customCss} center={position} zoom={zoom}>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					{props.data.length !== 0 && 
						<MarkerCluster>
							{venues.map((data, index) => (
								<div key={index}>
									{data.geoLatLong.lon !== undefined && data.geoLatLong.lat !== undefined && /^-?\d*\.\d*$/.test(data.geoLatLong.lat) && /^-?\d*\.\d*$/.test(data.geoLatLong.lon) ? 
										<Marker icon={locIcon} key={index} position={[Number(data.geoLatLong.lat), Number(data.geoLatLong.lon)]}>
											<Popup className="map_card">
												<>
													<TraksConsumer>
														<MapCard suppliers={props.suppliers ? true : false} data={data} key={"location" + index} />
													</TraksConsumer>
												</>
											</Popup>
										</Marker>
									: null}
								</div>
							))}
						</MarkerCluster>
					}
				</MapContainer>
			</div>
		);
	}
}

const createClusterCustomIcon = (cluster) => {
	return divIcon({
		html: `<span>${cluster.getChildCount()} </span>`,
		className: 'map_cluster',
		iconSize: point(40, 40, true),
	})
}

const MarkerCluster = ({ children }) => {
	return (
		<BaseMarkerCluster
			iconCreateFunction={createClusterCustomIcon}
			showCoverageOnHover={false}
		>
			{children}
		</BaseMarkerCluster>
	)
}

export default Map;