import React, { useContext, useEffect, useState } from "react";
import { APIGet } from "API";
import { T, TraksConsumer } from ".././traks";
import { MeContext } from './../MeContext';
import NoAccess from "c/admin/NoAccess";
import DashboardContainer from "c/admin/DashboardContainer";
import DashboardContainerHeader from "c/admin/DashboardContainerHeader";
import ListItem from "c/admin/ListItem";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import { Loading } from "c/Loading";
import { AlertModal, CustomFilter } from "c/Reusables";
import { LL } from "Routes";

const Partners = () => {
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin");
	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });
	const [data, setData] = useState([]);
	const searchPlaceholder = <T>Search</T>;
	const [adminUsers, setAdminUsers] = useState([]);
	const [otherUsers, setOtherUsers] = useState([]);
	const [queriedDataAdmin, setQueriedDataAdmin] = useState('');
	const [queriedDataUser, setQueriedDataUser] = useState('');

	useEffect(() => {
		APIGet("users").then(res => {
			setData(res.data.users);
			const adminUsers = res.data.users.filter(user => user.roles.includes('admin'));
			const nonAdminUsers = res.data.users.filter(user => !user.roles.includes('admin'));
			setQueriedDataAdmin(adminUsers);
			setQueriedDataUser(nonAdminUsers);
			setAdminUsers(adminUsers);
			setOtherUsers(nonAdminUsers);
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
			setShowLoading(false)
		});
	}, []);

	const setSearchData = (query) => {
		setQueriedDataAdmin(query);
	};
	const setSearchDataUser = (query) => {
		setQueriedDataUser(query);
	};

	if (!isAdmin) {
		return <NoAccess />
	};

	if (!data.length) {
		return (
			<DashboardWrapper index={11}>
				<Loading
					showLoading={showLoading}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	};

	const filterOptionsAdmin = [
		{
			name: "A-Z",
			type: 0,
			dataName: "screenName",
		},
		{
			name: "Active",
			type: 1,
			dataName: "active",
		},
	];

	const FilterComponentAdmin = 
		<div>
			<CustomFilter
				data={adminUsers}
				filterOptions={filterOptionsAdmin}
				setData={setSearchData}
			/>
		</div>
	;
	const FilterComponentUser = 
		<div>
			<CustomFilter
				data={otherUsers}
				filterOptions={filterOptionsAdmin}
				setData={setSearchDataUser}
			/>
		</div>
	;

	return (
		<DashboardWrapper index={11}>
			<h5 className="mb-3"><T>Users</T></h5>
			<DashboardContainer>
				<TraksConsumer>
					<DashboardContainerHeader
						sortableUser
						filter={FilterComponentAdmin}
						valueToSearch={"screenName"}
						data={adminUsers}
						searchPlaceholder={searchPlaceholder}
						setData={setSearchData}
						search
					>
						<T>Admins</T>
					</DashboardContainerHeader>
				</TraksConsumer>
				{adminUsers &&
					<span>
						{queriedDataAdmin.map((item, i) => (
							<ListItem key={i}
								icon="user"
								title={item.screenName}
								url={LL("/partner/" + item.id)}
								edit
							/>
						))}
					</span>
				}
			</DashboardContainer>
			<div className="py-2"/>
			<DashboardContainer>
				<TraksConsumer>
					<DashboardContainerHeader
						sortableUser
						filter={FilterComponentUser}
						valueToSearch={"screenName"}
						data={otherUsers}
						searchPlaceholder={searchPlaceholder}
						setData={setSearchDataUser}
						search
					>
						<T>Partners</T>
					</DashboardContainerHeader>
				</TraksConsumer>
				{otherUsers &&
					<span>
						{queriedDataUser.map((item, i) => (
							<ListItem key={i}
								icon="user"
								title={item.screenName}
								url={LL("/partner/" + item.id)}
								edit
							/>
						))}
					</span>
				}
			</DashboardContainer>
		</DashboardWrapper>
	);
}

export default Partners;