import React from "react";
import { Container } from "reactstrap";

const DashboardContainer = ({children, ...spacing}) => {

	const topSpacing = spacing ? "mt-3" : null;
	const classes = "p-0 shadow-soft rounded-3 bg-white"
	const combinedClasses = `${topSpacing} ${classes}`

	return (
		<Container fluid className={combinedClasses}>
			{children}
		</Container>
	);
}

export default DashboardContainer;
