import React from "react";
import Header from "../c/Header";
import VenueComponent from "../c/VenueComponent";

const Home = () => {
	return (
		<div>
			<Header />
			<VenueComponent />
		</div>
	);
}

export default Home;