import React from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import { T } from "../traks";

const PrivacyPageInner = () => {
	return (
		<Container>
			<Row className="danish">
				<Col className="privacy_page">
					<h1 className='py-4'><T>Persondatapolitik</T></h1>
					<div className="mb-4">
						<a href="#english">Show english version</a>
					</div>
					<h2>Formål</h2>
					<p>
					Formålet med en persondatapolitik for Optimeet ApS og dets datterselskaber Forlaget Optimeet ApS og Optimeet
Booking ApS (herefter benævnt Optimeet) er at forklare, hvordan vi indsamler, beskytter og anvender
personoplysninger. Det er Optimeets mål at opretholde et højt niveau af sikkerhed og en forsvarlig behandling og
beskyttelse af personlige oplysninger. Personlige oplysninger dækker alle oplysninger, der kan bruges til at identificere
en person, herunder, men ikke begrænset til vedkommendes for- og efternavn, privatadresse eller anden fysisk adresse,
e-mailadresse eller kontaktoplysninger, uanset om de vedrører vedkommendes private bopæl eller arbejdsplads.
					</p>
					<h2>Dataansvarlig</h2>
					<p>
					Optimeet opsamler data om sine brugere (planlæggere af møder og events) og kunder (leverandører til møder og events)
med henblik på at kunne levere informationer, nyheder, magasiner, bøger, tryksager, tilbud, invitationer, arrangementer,
bookingservice og lignende vedrørende møde- og eventbranchen. Optimeet er dataansvarlig og udfører håndtering af
personlige data i overensstemmelse med gældende lovgivning. Optimeet leverer en lang række serviceydelser til
brugere og kunder. For hver enkelt serviceydelse kan der gælde særskilte vilkår og betingelser. Når du ved bestilling af
en ydelse eller ved tilmelding til et arrangement afgiver dine personoplysninger, giver du samtidig samtykke til, at dine
personoplysninger kan behandles af Optimeet og vores samarbejdspartnere.
					</p>
					<h2>Disse personoplysninger indsamler og behandler vi</h2>
					<p>
					For at kunne handle med dig, gennemføre arrangementer med din deltagelse og levere nyheder, informationer og
tjenester til dig, indsamler vi personlige oplysninger i forbindelse med følgende:
						<ul className="mt-3">
							<li>
								Når du bestiller/booker, køber eller tilmelder dig en af Optimeets serviceydelser.
							</li>
							<li>
								Fra personer, der handler/tilmelder på dine vegne.
							</li>
							<li>
								Afgivelse af tilbud og/eller ved anmodning om en samarbejdsaftale.
							</li>
							<li>
							Via browser-cookies og ved brug af Optimeets digitale ydelser.
							</li>
							<li>
							Abonnement på Optimeets forskellige nyhedsmedier (både online og offline)
							</li>
							<li>
							Ved gennemførelse af bruger- eller kundeanalyser
							</li>
							<li>
							Deltagelse i konkurrencer.
							</li>
							<li>
							Fra sociale medier, reklame- og serviceudbydere samt offentlige registre.
							</li>
						</ul>
					</p>
					<p>
					<p><strong>Optimeet indsamler følgende personoplysninger:</strong></p>
					</p>
					<p><ul>
					<li><span>Navn, adresse, firma, telefonnummer, e-mailadresse, titel samt andre almindelige personoplysninger.</span></li>
					<li><span>Bestillings-/booking-/leverings-/deltagerhistorik</span></li>
					<li><span>Type, frekvens og deltagerantal i relation til de møder og events, brugere planlægger</span></li>
					<li><span>Feedback via vores kunde-/brugerundersøgelser.</span></li>
					<li><span>Feedback via fysiske eller onlinebaserede konkurrencer.</span></li>
					<li><span>Feedback på sociale medier og andre digitale platforme.</span></li>
					<li><span>Browserinformationer.</span></li>
					<li><span>Oplysninger om din virksomhed og relevante kontaktpersoner.</span></li>
					</ul></p>
					<p>
					Du kan vælge at give Optimeet personoplysninger ud over de almindelige personoplysninger, hvis du vurderer, at det
har betydning for den ydelse, Optimeet skal levere til dig. Dette kan f.eks. være oplysning om særlige allergier og
fødevarepræferencer. Såfremt du vælger at gøre dette, betragter Optimeet det som et samtykke til at kunne registrere og
gemme disse eventuelt følsomme oplysninger om dig. Ud over at modtage oplysninger fra dig selv, vil Optimeet i nogle
tilfælde supplere vores informationer med oplysninger, som vi har modtaget af tredjepart, f.eks. din kollega eller en

samarbejdspartner (eksempelvis ved fælles tilmelding til vores arrangementer eller ved aftale om levering af en eller
flere ydelser). I disse tilfælde er det pålagt tredjeparten at informere om Optimeets vilkår og betingelser samt vores
persondatapolitik. Det er også pålagt tredjeparten at sørge for indhentning af samtykker til registrering og behandling af
eventuelle følsomme oplysninger. Optimeet ønsker, at vores arrangementer kan gennemføres i så god en stemning og
med så højt et udbytte som muligt for såvel brugere som kunder. Derfor indsamler Optimeet i helt særlige tilfælde
oplysninger om personer, som optræder i uoverensstemmelse med et givent arrangements formål med henblik på kunne
udelukke disse personer ved fremtidige arrangementer.
					</p>
					<h2>Deling og opbevaring af personoplysninger</h2>
					<p>

					Personoplysninger afgivet til Optimeet lagres på servere, der administreres af tredjemænd (databehandler) på vegne af
Optimeet i henhold til vores datapolitik og den gældende lovgivning om beskyttelse af persondata. Personoplysninger
afgivet til Optimeet videregives kun til:
						<ul>
							<li>Interne afdelinger</li>
							<li>Udvalgte tredjemænd i forbindelse med vedligehold og servicering af systemer som anvendes af Optimeet.</li>
							<li>Associerede selskaber af Optimeet, der leverer ydelser, som hører ind under Optimeets normale virkefelt.</li>
							<li>Udvalgte samarbejdspartnere for at kunne levere den serviceydelse, du har bestilt eller tilmeldt dig.</li>
						</ul>
						I forbindelse med forespørgsler og/eller booking af mødelokaler gør vi opmærksom på, at Optimeet betragter sig som
formidler af din henvendelse. Optimeet handler således ud fra din instruks. Det indebærer, at Optimeet og de steder du
vælger at sende forespørgslen til, alle er selvstændige dataansvarlige. Der er således et delt dataansvar, og de steder, du
vælger, vil behandle dine personoplysninger i henhold til deres egne persondatapolitikker. Personoplysninger afgivet
direkte til en udstiller (eksempelvis udlevering af dit visitkort eller ved at give en udstiller tilladelse til at scanne dit
navneskilt) ved et Optimeet-arrangement opfattes som levering af personlige oplysninger uden om Optimeet og
betragtes derfor som levering af personoplysninger direkte til udstilleren, hvormed t udstillerens persondatapolitik
finder anvendelse. Optimeet opbevarer kun personoplysninger, så længe de er nødvendige i forhold til de serviceydelser,
Optimeet leverer. Optimeet har rutiner for sletning af persondata. Efter en periode uden aktivitet på en person slettes
eller anonymiseres denne. Periodens længde varierer efter, hvilken serviceydelse der er tale om – dog maximalt 3 år -
medmindre lovgivningen dikterer andet (f.eks. bogføringsloven).

					</p>
					<h2>Dine rettigheder</h2>
					<p>
					I henhold til gældende lovgivning om persondata har du en række rettigheder. Rettighederne er som følger:
						<ul>
						<li>Du har ret til at få indsigt i, hvilke personoplysninger Optimeet behandler om dig.
						</li>
							<li>
							Du har ret til at få berigtiget og ajourført de personoplysninger, Optimeet har registreret om dig.
							</li>
							<li>
							Du har ret til at få slettet de personoplysninger, Optimeet har registreret om dig, dog ikke oplysninger, som
Optimeet efter lovgivning er pålagt at skulle gemme.
							</li>
							<li>Er behandlingen af personoplysninger baseret på et samtykke fra dig, har du ret til at trække dit samtykket
tilbage. Behandling af dine personoplysninger vil herefter ophøre, medmindre Optimeet er pålagt at skulle
behandle personoplysningerne efter lovgivningen.
							</li>
						</ul>
						Du kan anmode Optimeet om at få leveret en udskrift af dine personoplysninger, få ajourført dine personoplysninger,
gøre indsigelser eller anmode om at få slettet dine personoplysninger. Anmodningen skal ske skriftligt (med underskift)
og indeholde dit navn, adresse, telefonnummer og e- mailadresse. Du kan også kontakte Optimeet, hvis du mener, at
dine persondata bliver behandlet i strid med lovgivningen eller andre retlige forpligtelser.
Anmodningen fremsendes til: Optimeet Jernbanevej 4, 1.sal 2800 Kgs. Lyngby, Att: Jacob Nedergaard. Optimeet vil
inden for 1 måned efter modtagelsen af din anmodning om udskrift fremsende denne til din postadresse. Optimeet kan
afvise anmodninger, der enten er urimeligt gentagende, kræver uforholdsmæssig teknisk indgriben (for eksempel at
udvikle et nyt system eller ændre en eksisterende praksis væsentligt), påvirker beskyttelsen af andres personlige
oplysninger, eller i situationer hvor den ønskede handling må anses for værende yderst kompliceret (for eksempel
anmodninger om oplysninger, der udelukkende findes som sikkerhedskopier).
					</p>

					<h2><b>Kontakt</b></h2>
					<p>Har du spørgsmål, kommentarer eller klager til Optimeets behandling af personoplysninger kan du henvende dig til:
						<div className="mt-3 mb-3">
							<div>Optimeet</div>
							<div>Jernbanevej 4, 1.sal</div>
							<div>2800 Kgs. Lyngby</div>
							<div>Att: Jacob Nedergaard</div>
						</div>
						Hvis en eventuel klage ikke medfører en afklaring med Optimeet, kan du herefter rette din klage til  <a href="https://www.datatilsynet.dk/" rel="noreferrer" target="_blank">Datatilsynet</a>.
						Eventuelle ændringer af persondatapolitikken meddeles ved offentliggørelse af nye vilkår og betingelser på Optimeets
hjemmeside.</p>
				</Col>
			</Row>
			<Row id="english">
				<Col className="privacy_page mt-3">
					<span className="fw-bold">English version</span>
					<h1 className='py-2'>Privacy policy</h1>
					<h2><b>Purpose</b></h2>
					<p><span>The purpose of a personal data policy for Optimeet ApS and its subsidiaries, the publishing house Forlaget Optimeet
ApS, and Optimeet Booking ApS (hereinafter Optimeet), is to explain how we collect, protect, and use personal data.
Optimeet aims to maintain a high level of security, as well as the responsible processing and protection of personal data.</span></p>
					<p><span>Personal information encompasses all information that can be used to identify a person, including, but not limited to,
their first and last name, private address or other physical address, email address, or contact information, regardless of
whether they relate to their private residence or workplace.</span></p>
					<h2><b>Data controller</b></h2>
					<p><span>Optimeet collects data about its users (schedulers of meetings and events) and customers (suppliers of meetings and
events) in order to provide information, news, magazines, books, printed matter, offers, invitations, events, booking
services, and the like within the meeting and event industry.</span></p>
					<p><span>Optimeet is the data controller and performs the processing of personal data in accordance with applicable legislation.</span></p>
					<p><span>Optimeet provides a wide range of services to users and customers. Separate terms and conditions may apply to each
service.</span></p>
					<p><span>When you provide your personal data in connection with ordering a service or registering for an event, you also give
your consent to Optimeet and its business partners processing your personal data.</span></p>
					<h3>Which personal data do we collect and process?</h3>
					<p><span>In order to do business with you, to hold events with your participation, and to provide you with news, information and
services, Optimeet collect personal information in the following way:</span></p>
					<p>
					<ul>
					<li><span>When you order/book, purchase or register for one of Optimeet’s services.</span></li>
					<li><span>From people who act/register on your behalf.</span></li>
					<li><span>In connection with the submission of tenders and/or when requesting a partnership agreement.</span></li>
					<li><span>Via browser cookies and when using Optimeet’s digital services.</span></li>
					<li><span>In connection with subscriptions to Optimeet’s various news media (both online and offline).</span></li>
					<li><span>When conducting user or customer analyses.</span></li>
					<li><span>In connection with participation in competitions.</span></li>
					<li><span>From social media, advertising, and service providers, as well as public registers.</span></li>
					</ul>
					</p>
					<p>
					<h3>Optimeet collects, among other things, the following personal data:</h3>
					</p>
					<p><ul>
					<li><span>Name, address, company, telephone number, email address, title, and other general personal data.</span></li>
					<li><span>Order/booking/delivery/participant history</span></li>
					<li><span>Type, frequency, and number of participants in relation to the meetings and events that users plan</span></li>
					<li><span>Feedback through our customer/user surveys.</span></li>
					<li><span>Feedback via physical or online competitions.</span></li>
					<li><span>Feedback on social media and other digital platforms.</span></li>
					<li><span>Browser information.</span></li>
					<li><span>Information about your company and relevant contact persons.</span></li>
					</ul></p>
					<p><span>You can choose to provide Optimeet with personal data in addition to your general personal data, if you consider it to be
of importance to the service that Optimeet is providing to you. This may include information about allergies or dietary
preferences. If you choose to do so, Optimeet considers this to constitute consent to register and save this potentially
sensitive information about you.</span></p>
					<p><span>In addition to receiving information from yourself, Optimeet will, in some cases, supplement our information with
information that we have received from a third party, e.g. a colleague of yours or a business partner (e.g. by joint
registration for our events or by agreement on the delivery of one or more services). In these cases, the third party is
required to provide information about Optimeet’s terms and conditions and our personal data policy. The third party is
also required to obtain consent for the registration and processing of any sensitive information.</span></p>
					<p><span>Optimeet wants our events to be held in the best possible atmosphere, and with the greatest possible benefit, for both
users and customers. Therefore, in very special cases, Optimeet collects information about persons who conflict with
the purpose of a given event, in order to exclude these persons from future events.</span></p>
					<h3><b>Sharing and storage of personal data</b></h3>
					<p><span>Personal data provided to Optimeet is stored on servers managed by third parties (data processors) on behalf of
Optimeet, in accordance with our data policy and applicable legislation on the protection of personal data.</span><span><br />
					</span><span>Personal data provided to Optimeet is only disclosed to:</span></p>
					<ul>
					<li><span>Internal departments</span></li>
					<li><span>Selected third parties in connection with the maintenance and servicing of systems used by Optimeet.</span></li>
					<li><span>Optimeet&#39;s associated companies that provide services that fall within Optimeet’s normal fields of activity.</span></li>
					<li><span>Selected business partners, in order to deliver the service, you have ordered or registered for.</span></li>
					</ul>
					<p><span>In connection with enquiries and/or bookings of meeting rooms, please note that Optimeet considers itself to be the
intermediary for your enquiry. In this context, Optimeet acts on the basis of your instructions. This means that
Optimeet, and the places to which you choose to send the enquiry, are all independent data controllers. There is thus a
shared responsibility for data, and the places you choose will process your personal data in accordance with their own
personal data policies.</span></p>
					<p><span>Personal data provided directly to an exhibitor (e.g. handing out your business card or giving an exhibitor permission to
scan your name tag) at a Optimeet event is deemed to constitute the delivery of personal data outside of Optimeet and is
therefore deemed to constitute the delivery of personal data directly to the exhibitor, in which case the exhibitor’s
personal data policy will apply.</span></p>
					<p><span>Optimeet only stores personal data for as long as it is necessary in relation to the services provided by Optimeet.
Optimeet has procedures for the deletion of personal data. After an individual has been inactive for a period of time, the
person’s data will be deleted or anonymised. The duration of the period varies according to the service in question, but
there is a maximum of three years unless otherwise stipulated by legislation (e.g. the Danish Bookkeeping Act).</span></p>
					<h3><b>Your rights</b></h3>
					<p><span>In accordance with applicable legislation regarding personal data, you have a number of rights. These rights are as
follows:</span></p>
					<ul style={{paddingLeft: "40px"}}>
					<li><span>You have the right view the personal data that Optimeet processes about you.</span></li>
					<li><span>The right to have the personal data that Optimeet has registered about you corrected and updated.</span></li>
					<li><span>The right to have the personal data that Optimeet has registered about you deleted, with the exception of data
that Optimeet is required to save by law.</span></li>
					<li><span>If the processing of personal data is based on your consent, you have the right to withdraw your consent. The
processing of your personal data will then cease, unless Optimeet is required to process the personal data in
accordance with legislation.</span></li>
					</ul>
					<p><span>You can ask Optimeet to provide you with a printout of your personal data, to have your personal data updated, to raise
objections or to request the deletion of your personal data. Such a request must be made in writing (with a signature)
and include your name, address, telephone number, and email address. You can also contact Optimeet if you believe that
your personal data is being processed in violation of legislation or other legal obligations.</span></p>
					<p><span>Such a request should be sent to:</span></p>
					<p><span>Optimeet</span><br />
					<span>Jernbanevej 4, 1. floor</span><br />
					<span>DK-2800 Kgs. Lyngby</span>
					<span>Attn: Jacob Nedergaard</span></p>
					<p><span>Within one month of receiving your request for a printout, Optimeet will send it to your postal address. Optimeet may
reject requests that are either unreasonably repetitive, require disproportionate technical intervention (for example, the
development of a new system or a substantial change to an existing practice), affect the protection of other individuals&#39;
personal data, or in situations where the action requested may be considered to be highly complex (for example,
requests for information that exists solely as backups).</span></p>
					<p>
					<h3>Contact</h3>
					</p>
					<p><span>If you have any questions, comments, or complaints about Optimeet’s processing of personal data, you can contact:</span></p>
					<p><span>Optimeet</span><br />
					<span>Jernbanevej 4, 1. floor</span><br />
					<span>DK-2800 Kgs. Lyngby</span>
					<span className="mb-5">Attn: Jacob Nedergaard</span></p>
					<p><span>If a complaint is not handled by Optimeet, you may then direct your complaint to the <a href="https://www.datatilsynet.dk/english" rel="noreferrer" target="_blank"><span>Danish Data Protection Agency</span></a>.</span></p>
					<p className="mb-5"><span>Any changes to the personal data policy will be announced through the publication of new terms and conditions on
Optimeet’s website.</span></p>
				</Col>
			</Row>
		</Container>
	);
}

const PrivacyPage = () => {
	return (
		<div>
			<Header custom=" text-dark"/>
			<PrivacyPageInner />
			<Footer />
		</div>
	);
}

export default PrivacyPage;