import React, { /* useState */ } from "react";
import { Container, Row, Col } from 'reactstrap';
import { T, TraksConsumer } from "../traks";
import Kbutton from './KButton';
import InquiryModal from "./modals/InquiryModal";
import { LL } from "../Routes";

const Footer = () => {
	return (
		<Container fluid className="konference_footer">
			<TraksConsumer>
				<Newsletter />
			</TraksConsumer>
			<Contact />
		</Container>
	);
}

const Newsletter = (props) => {

	//const [email, setEmail] = useState('');

	return (
		<div className="newsletter_bg_img text-center pt-1 pb-1">
			<div className="d-flex container justify-content-between align-items-center w-100 mt-3">
				<div className="text-start">
					<h4 className="text-light"><T>Tilmeld nyhedsbrev</T></h4>
					<span className="text-light text-weight-reg text-lg mt-1"><T>Hold dig opdateret med nyt fra optimeet.dk</T></span>
				</div>
				<div className="mx-2">
					<a href={LL("/tilmelding")}>
						<div className="d-none d-md-block">
							<Kbutton custom="my-1" lg noborder md><T>Tilmeld nyhedsbrev</T></Kbutton>
						</div>
						<div className="d-block d-md-none">
							<Kbutton custom="my-1" lg noborder md><T>Tilmeld</T></Kbutton>
						</div>
					</a>
				</div>
			</div>
				<hr></hr>
		</div>
	);
}

const Contact = () => {

	return (
		<Container>
			<div className="d-none d-lg-block">
				<Row className="pt-3 pt-lg-5 pb-4 justify-content-between text-white">
					<Col xs="12" lg="auto">
						<a href={LL("/")}>
							<img className="hoverfx" alt="klogo" width={161} height={50} src={"https://static.optimeet.dk/gfx/klogo.svg"} />
						</a>
						<div className="pt-5">
							<a href="https://www.facebook.com/optimeetdk/" rel="noreferrer" target="_blank">
								<img className="hoverfx" alt="fblogo" width={24} height={24} src={"https://static.optimeet.dk/gfx/ico_k-facebook.svg"} />
							</a>
							<a href="https://www.instagram.com/optimeet/" rel="noreferrer" target="_blank">
								<img className="mx-2 hoverfx" alt="fblogo" width={24} height={24} src={"https://static.optimeet.dk/gfx/ico_k-ig.svg"} />
							</a>
							<a href="https://www.linkedin.com/company/3357896/" rel="noreferrer" target="_blank">
								<img className="hoverfx" alt="fblogo" width={24} height={24} src={"https://static.optimeet.dk/gfx/ico_k-linkedin.svg"} />
							</a>
						</div>
					</Col>
					<Col xs="12" lg="auto" className="text-center text-lg-start pt-3 pt-lg-0">
						<div className="text-white font_24px">
							<span className="font_weight_600"><T>Information</T></span>
						</div>
						<div className="py-1">
							<a style={{ textDecoration: 'none' }} href={LL("/konferencesteder")} className="text-md text-white opacity-50"><T>Møde- og konferencesteder</T></a>
						</div>
						<div className="py-1">
							<a style={{ textDecoration: 'none' }} href={LL("/leverandoerer")} className="text-md text-white opacity-50"><T>Leverandører</T></a>
						</div>
						<div className="py-1">
							<a style={{ textDecoration: 'none' }} href={LL("/om-os")} className="text-md text-white opacity-50"><T>Om os</T></a>
						</div>
						<div className="py-1">
							<a style={{ textDecoration: 'none' }} href={LL("/persondatapolitik")} className="text-md text-white opacity-50"><T>Privatlivsbetingelser</T></a>
						</div>
						<div className="py-1">
							<a style={{ textDecoration: 'none' }} href={LL("/betingelser")} className="text-md text-white opacity-50"><T>Betingelser</T></a>
						</div>
						<div className="py-1">
							<a style={{ textDecoration: 'none' }} href={LL("/cookies")} className="text-md text-white opacity-50"><T>Cookies</T></a>
						</div>
					</Col>
					<Col xs="12" lg="auto">
						<div className="text-white font_24px">
							<span className="font_weight_600"><T>Kontakt Os</T></span>
						</div>
						<div className="py-3">
							<InquiryModal modalTitle={<T>Få hjælp til din booking</T>}>
								<Kbutton custom="px-4 py-2 hoverfxlight" sec md><T>Gratis bookinghjælp</T></Kbutton>
							</InquiryModal>
						</div>
						<div className="text-md text-lineheight-24 mt-2 text-white opacity-50">
							<T>Optimeet ApS</T>
						</div>
						<div className="text-lineheight-24 text-md text-white opacity-50">
							<T>CVR: 4048 4876</T>
						</div>
						<div className="text-lineheight-24 text-md text-white opacity-50">
							<T>kontakt@optimeet.dk</T>
						</div>
						<div className="text-lineheight-24 text-md text-white opacity-50">
							<T>Telefon: +45 33 97 43 43</T>
						</div>
					</Col>
					<Col xs="12" lg="auto" className="text-center text-lg-start pt-4 pt-lg-0">
						<div className="text-white font_24px"><span className="font_weight_600"><T>Bliv partner</T></span></div>
						<InquiryModal modalTitle={<T>Bliv partner</T>} modalDesc={<T>Tak for din interesse i at blive partner. Udfyld formularen og vi kontakter dig hurtigst muligt. Ellers er du altid velkommen til at ringe på tlf: 33 97 43 43</T>}>
							<div className='py-3 text-center'>
								<Kbutton custom="btn py-2 px-4" white><T>Bliv partner</T></Kbutton>
							</div>
						</InquiryModal>
					</Col>
				</Row>
			</div>
			<div className="d-xs-block d-lg-none">
				<div className="text-center pb-3 mt-3 mt-lg-0">
					<img alt="klogo" width={161} height={50} src={"https://static.optimeet.dk/gfx/klogo.svg"} />
				</div>
				<Row className="pt-3 mx-2 pb-2 justify-content-evenly text-white">
					<Col>
						<div className="font_24px">
							<span className="text-bold"><T>Information</T></span>
						</div>
						<div className="py-1 text-lineheight-24">
							<a style={{ textDecoration: 'none' }} href={LL("/konferencesteder")} className="text-sm text-white opacity-50"><T>Møde- og konferencesteder</T></a>
						</div>
						<div className="py-1 text-lineheight-24">
							<a style={{ textDecoration: 'none' }} href={LL("/leverandoerer")} className="text-sm text-white opacity-50"><T>Leverandører</T></a>
						</div>
						<div className="py-1 text-lineheight-24">
							<a style={{ textDecoration: 'none' }} href={LL("/om-os")} className="text-sm text-white opacity-50"><T>Om os</T></a>
						</div>
						<div className="py-1 text-lineheight-24">
							<a style={{ textDecoration: 'none' }} href={LL("/persondatapolitik")} className="text-sm text-white opacity-50"><T>Privatlivsbetingelser</T></a>
						</div>
						<div className="py-1 text-lineheight-24">
							<a style={{ textDecoration: 'none' }} href={LL("/betingelser")} className="text-sm text-white opacity-50"><T>Betingelser</T></a>
						</div>
						<div className="py-1 text-lineheight-24">
							<a style={{ textDecoration: 'none' }} href={LL("/cookies")} className="text-sm text-white opacity-50"><T>Cookies</T></a>
						</div>
					</Col>
					<Col>
						<div className="font_24px">
							<span className="text-bold"><T>Kontakt Os</T></span>
						</div>
						<div className="py-3">
							<InquiryModal modalTitle={<T>Få hjælp til din booking</T>}>
								<Kbutton custom="py-2" sec md><T>Bookinghjælp</T></Kbutton>
							</InquiryModal>
						</div>
						<div className="text-md text-lineheight-24 mt-2">
							<T>Optimeet ApS</T>
						</div>
						<div className="text-lineheight-24 text-md">
							<T>CVR: 3605 1167</T>
						</div>
						<div className="text-lineheight-24 text-md">
							<T>kontakt@optimeet.dk</T>
						</div>
						<div className="text-lineheight-24 text-md">
							<T>Telefon: +45 33 97 43 43</T>
						</div>
						<div className="font_24px pt-2">
							<span className="text-bold"><T>Bliv partner</T></span>
						</div>
						<div className='py-3'>
							<InquiryModal modalTitle={<T>Bliv partner</T>} modalDesc={<T>Tak for din interesse i at blive partner. Udfyld formularen og vi kontakter dig hurtigst muligt. Ellers er du altid velkommen til at ringe på tlf: 33 97 43 43</T>}>
								<div className='text-center'>
									<Kbutton custom="btn py-2 px-4" white><T>Bliv partner</T></Kbutton>
								</div>
							</InquiryModal>
						</div>
					</Col>
				</Row>
			</div>
			<hr></hr>
			<div className="text-center text-white copyrighttext pt-5 pb-4">
				<T>Copyright © 2023 optimeet.dk. All rights reserved</T>
			</div>
		</Container>
	);
}

export default Footer;