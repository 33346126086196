import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import Kbutton from './KButton';
import { useLocation } from 'react-router-dom';
import { APIPost } from "API";
import Map from "./Map";
import { T, TraksConsumer } from "../traks";
import GetHelp from "./GetHelp";
import Footer from "./Footer";
import { VenueHeader, MapAddress, VenueGallery, VenueDescription, Bookings, VenueInquire } from "./SharedVenueComponent";
import { useWindowSize } from "./Hooks/useWindowSize";

const SupplierComponent = ()=> {

	const location = useLocation();
	const preview = location["state"] && location.state["preview"];

	const url = window.location.href;
	const suppliertxt = url.split('leverandoerer')[1];
	const textAfterLeverandoerer = suppliertxt.split('/').filter(Boolean)[0];

	const [venueData, setVenueData] = useState(undefined);

	const iconPhone = <img width={17} height={17} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_phone-0.svg" alt='iconPhone' />
	const iconMail = <img width={17} height={13} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_mail-0.svg" alt='iconMail' />
	const iconWebsite = <img width={17} height={13} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_link-0.svg" alt='iconWebsite' />

	useEffect(() => {
		const body = { query: { urlSlug: textAfterLeverandoerer} }
		if(preview)
			body.query["show_hidden"] = true
		APIPost("searchsuppliers", body).then(res => {
			setVenueData(res.data.result[0]);
			window.scrollTo({ top: 0, behavior: 'instant' });
		}).catch(err => {
			console.log(err);
		})
		// eslint-disable-next-line
	}, []);

	const { width } = useWindowSize();
	const breakpoint = 1200;

	if (venueData !== undefined ) {
		return (
			<div className="container-fluid p-0 m-0"> 
				<div className="container">
					{width > breakpoint ?
						<div>
							<VenueHeader data={venueData} />
							<VenueGallery supplier data={venueData} />
							<Container>
								<div className="d-flex flex-column flex-lg-row pt-4">
									<VenueDescription supplier data={venueData} />
									<TraksConsumer>
										<RightPanel data={venueData} />
									</TraksConsumer>
								</div>
							</Container>
							{venueData.geoLatLong !== undefined &&
								<div id="scroll_map" className="mt-2 find-location p-5">
									<div>
										<div className="mb-3">
											<h5><T>Find vej</T></h5>
										</div>
										<div className="d-flex">
											<Map data={venueData} customCss={"venuemap"} />
											<div className="d-flex flex-column w-100">
												<div className="ms-4 mb-4 w-100 find-location-text p-4 pt-2">
													<h5 className="mt-4">{venueData.title}</h5>
													<div>
														<div className="mt-3">
															{venueData.address_1}
														</div>
														<div>
															<span className="pe-1">
																{venueData.zipcode} -
															</span>
															<span>
																{venueData.city}
															</span>
														</div>
														<div className="mt-3">
															{venueData.address_2}
														</div>
													</div>
												</div>
												<div className="pt-3 d-flex flex-column ms-4 w-100">
													<Kbutton icon={iconPhone} copyable title={venueData.phoneNumber} onClick={() => navigator.clipboard.writeText(venueData.phoneNumber)} grey custom=" btn text-black py-2 px-3 me-2" />
													<Kbutton icon={iconMail} copyable title={venueData.email} onClick={() => navigator.clipboard.writeText(venueData.email)} grey custom=" btn text-black py-2 px-3 me-2 my-2" />
													<Kbutton icon={iconWebsite} onClick={() => window.open(venueData.website, "_blank")} title={"Besøg hjemmeside"} grey custom=" btn text-black py-2 px-3 me-2" />
												</div>
											</div>
										</div>
									</div>
								</div>}
							<div className="pb-0 pb-xl-5 mb-0 mb-xl-5 d-none d-xl-block">
								<GetHelp />
							</div>
						</div>
						:
						<div>
							<VenueHeader supplier data={venueData} />
							<VenueGallery supplier data={venueData} />
							<VenueInquire supplier data={venueData} />
							<VenueDescription supplier data={venueData} />
							<Bookings supplier data={venueData} />
							<MapAddress supplier data={venueData} />
						</div>
					}
				</div>
				<Footer />
			</div>
		);
	} else {
		return (
			<div className="w-100" style={{height: 1900}}></div>
		);
	}
};

const RightPanel = (props) => {

	return (
		<div className="ms-3">
			<VenueInquire supplier data={props.data} />
		</div>
	);
};

export default SupplierComponent;