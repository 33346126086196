import React, { useContext } from 'react';
import { Container } from "reactstrap";
import Sidebar from "../c/admin/Sidebar";
import StatBox from "../c/admin/StatBox";
import { ListActivity } from "c/admin/DashboardComponents";
import NoAccess from "c/admin/NoAccess";
import "../css/dashboard.css";
import CreateButton from "c/CreateButton";
import { T } from "../traks";
import { MeContext } from './../MeContext';
/* import { StatsPage } from '../c/Stats'; */

const Home = () => {

    const { me } = useContext(MeContext);

    if (!me) {
        return (
            <div className="dashboard">
                <NoAccess />
            </div>
        )
    }

    return (
        <div className="dashboard">
            <CreateButton />
            <Sidebar index={1} />
            <div className="dashboard-wrapper" style={{ maxWidth: 1140 }}>
                <h1 className="mb-3"><T>Velkommen</T>,&nbsp;{me.user.firstName}</h1>
                <StatBox />
                <Container fluid className="p-0 mt-4">
                    <ListActivity />
                </Container>
            </div>
        </div>
    );
};

export default Home;
