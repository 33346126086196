import React, { useContext } from "react";
import { LL } from "../../Routes";
import { T, TraksConsumer } from "../../traks";
import { VenueIsVisible, EditVenueText, EditVenueLocation, Gallery, Settings, ShareVenuePartner, Advanced, Premium, EditSupplierInfo } from "../../c/admin/DashboardComponents";
import Kbutton from "../../c/KButton";
import PreviewCards from "../../c/modals/PreviewCards";
import Notification from "../../c/admin/Notification";
import { MeContext } from '../../MeContext';
import { RenderIf, TStoReadable } from "../../c/Reusables";
import DashboardContainer from "../../c/admin/DashboardContainer";

export const EditSupplier = ({ supplier, saveSupplier, uploadFiles }) => {
	
	const { me } = useContext(MeContext);
	const isAdmin = me["user"] && me.user.roles.includes("admin")
	const isPreviewBtnDisabled = !supplier["imagesUrls"] || supplier["imagesUrls"] === undefined || !supplier["description"]

	return (
		<div>
			<div>
				<h5 className="mb-3"><T>Rediger supplier</T></h5>
				<div style={{ height: 1500 }}>
					<Notification>
						{supplier.visibleOnWebsite ?
							<div><T>Du redigerer</T>:&nbsp;
								<a style={{borderBottom: "2px", borderBottomColor: "lightgrey", borderBottomStyle: "solid"}} className="link_nostyle" href={LL("/kursuscenter/" + supplier["urlSlug"])} rel="noreferrer" target="_blank">{supplier["title"]}</a>
							</div>
							:
							<div><T>Du redigerer</T>:&nbsp;{supplier["title"]}</div>
						}
						<div><T>Last edited:</T>&nbsp;<TStoReadable date={supplier["updatedTs"]} /></div>
					</Notification>
					<DashboardContainer>
						<div className="px-4">
							<div className="d-flex justify-content-between w-100">
								<RenderIf condition={isAdmin}>
									<VenueIsVisible
										supplier
										saveData={saveSupplier}
									/>
								</RenderIf>
								<div className="d-flex flex-grow-1 align-items-center" style={{ maxWidth: 280 }}>
									<PreviewCards
										data={supplier}
										disabled={supplier["imagesUrls"] === undefined}
									/>
									<a href={LL("/leverandoerer/" + supplier["urlSlug"])} rel="noreferrer">
										<div className="link_nostyle w-100 d-flex justify-content-center">
											<Kbutton
												disabled={isPreviewBtnDisabled}
												custom="py-2 k-btn-greybg"
												sm
											>
												<T>Forhåndsvis</T>
											</Kbutton>
										</div>
									</a>
								</div>
							</div>
						</div>
					</DashboardContainer>
					<Gallery
						supplier
						saveData={saveSupplier}
						uploadImages={uploadFiles}
					/>
					<TraksConsumer>
						<EditSupplierInfo isAdmin={isAdmin} />
					</TraksConsumer>
					<RenderIf condition={isAdmin}>
						<div>
							<EditVenueLocation />
						</div>
					</RenderIf>
					<EditVenueText />
					<RenderIf condition={isAdmin}>
						<div>
							<Settings supplier />
							<TraksConsumer>
								<ShareVenuePartner supplier />
							</TraksConsumer>
						</div>
						<Premium supplier />
						<div className="mt-3">
							<Advanced type={"venue"} MetaDesc={"Meta description here"} PageTitle={"Meta title here"}/>
						</div>
					</RenderIf>
					<Kbutton
						onClick={() => saveSupplier()}
						custom="px-5 py-2 mt-3 mb-5"
						prim
						md
					>
						<T>Opdater</T>
					</Kbutton>
				</div>
			</div>
		</div>
	);
};