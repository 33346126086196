import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { DashboardWrapper, EditMagazineContent } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import { MeContext } from "MeContext";
import React, { useContext, useEffect, useState } from "react";
import NoAccess from "c/admin/NoAccess";
import { APIGet, APIPost, APIDelete } from "API";
import { AlertModal } from "c/Reusables";
import { T } from "../traks";
import { Loading } from "c/Loading";
import { LL } from "Routes";

const EditMagazine = ({ urlParams }) => {
	const navigate = useNavigate();
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin");
	urlParams = useParams();

	const magId = urlParams.magId;
	const [mag, setMag] = useState(undefined);
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })
	const [showLoading, setShowLoading] = useState(false)

	const uploadImage = (files) => {
		setShowLoading(true)
		const filesInfo = files.map(({ file, ...f }) => f);
		APIPost("news/" + magId + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const file = files[0];
			const preSignedUrl = res.data.upload_urls[0];
			const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
			return axios.put(preSignedUrl, file.file, { headers }).then(res => {
				const newFileName = preSignedUrl.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk");
				saveMagazine({ imageUrl: newFileName })
				setMag({ ...mag, imageUrl: newFileName })
				setShowLoading(false)
				return newFileName;
			}).catch(err => console.log(err))
		}).catch(err => console.log(err))
	};

	useEffect(() => {
		setShowLoading(true)
		if (urlParams.magId) {
			APIGet("magazines/" + magId).then(res => {
				if (res.data) {
					if (!res.data)
						navigate(LL("/mymagazines"));
				}
				setMag(res.data.magazine);
				setShowLoading(false)
			}).catch(err => {
				setShowLoading(false)
				setAlertType({ type: "error", text: err.response.data })
				setShowAlert(true);
			})
		} else {
			APIPost("magazines", { "title": "new magazine" }).then(res => {
				setMag(res.data.new_magazine);
				window.history.replaceState(null, '', res.data.new_magazine.id);
				setShowLoading(false)
				window.location.reload(false);
			}).catch((err) => {
				setShowLoading(false)
				setAlertType({ type: "error", text: err.response.data })
				setShowAlert(true);
			})
		}
		// eslint-disable-next-line
	}, []);

	const saveMagazine = (newMagazine = null) => {
		setShowLoading(true)
		let body = !newMagazine ? mag : newMagazine;
		APIPost("magazines/" + magId, body).then(res => {
			setShowLoading(false)
			setAlertType({ type: "success", text: "Magazine saved" })
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false)
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	};

	const deleteMagazine = () => {
		setShowLoading(true)
		const resource = "magazines/" + magId
		APIDelete(resource).then(res => {
			setShowLoading(false)
			navigate(LL("/mymagazines/"));
		}).catch(err => {
			setShowLoading(false)
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	};

	if (!isAdmin) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	};

	if (!mag) {
		return (
			<DashboardWrapper index={14}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	};

	return (
		<DashboardWrapper index={14}>
			<h5 className="mb-3"><T>Rediger magasin</T></h5>
			<div>
				<Notification><T>Du redigerer</T>:&nbsp;{mag["title"]}</Notification>
				<EditMagazineContent
					uploadImage={uploadImage}
					deleteMagazine={deleteMagazine}
					mag={mag}
					setMag={setMag}
					saveMagazine={saveMagazine}
				/>
			</div>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</DashboardWrapper>
	);
};

export default EditMagazine;