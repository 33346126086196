import React, { useState } from "react";
import { Modal, ModalBody, Row, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Kbutton from '../KButton';
import { T } from "../../traks";
import { APIPost } from "API";
import { AlertModal } from "c/Reusables";
import { LL } from "Routes";

const VerifyCreateSlug = (props) => {

	const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState(false);
	const toggle = () => {
		props.setModalIsOpen(!modalOpen)
		setModalOpen(!modalOpen);
	};

	const closeIcon = <img
		className="btn p-1 rounded-circle"
		style={{ position: "absolute", right: 15, top: 15, backgroundColor: "white" }}
		onClick={() => toggle()}
		src="https://static.optimeet.dk/gfx/ico_close.svg" alt='icon to close'
	/>

	const [title, setTitle] = useState('');

	const submit = () => {
		if (props.item.link === "editvenue/") {
			APIPost("venues", {"title": title}).then(res => {
				navigate(LL("/editvenue/" + res.data.venue.id));
				setModalOpen(false);
				window.location.pathname.includes('editvenue') && window.location.reload();
			})
			.catch((error) => {
				setAlertType({ type: "error", text: error.response.data })
				setShowAlert(true);
			})
		}
		if (props.item.link === "editsupplier/") {
			APIPost("suppliers", {"title": title}).then(res => {
				navigate(LL("/editsupplier/" + res.data.supplier.id));
				setModalOpen(false);
				window.location.pathname.includes('editsupplier') && window.location.reload();
			})
			.catch((error) => {
				setAlertType({ type: "error", text: error.response.data })
				setShowAlert(true);
			})
		}
		if (props.item.link === "editnews/") {
			APIPost("news", {"title": title}).then(res => {
				navigate(LL("/editnews/" + res.data.new_news.urlSlug));
				setModalOpen(false);
				window.location.pathname.includes('editnews') && window.location.reload();
			})
			.catch((error) => {
				setAlertType({ type: "error", text: error.response.data })
				setShowAlert(true);
			})
		}
		if (props.item.link === "editbanners/") {
			APIPost("banner", {"title": title}).then(res => {
				navigate(LL("/editbanners/" + res.data.new_news.urlSlug));
				setModalOpen(false);
				window.location.pathname.includes('editbanners') && window.location.reload();
			})
			.catch((error) => {
				setAlertType({ type: "error", text: error.response.data })
				setShowAlert(true);
			})
		}
		if (props.item.link === "editlandingcategory/") {
			APIPost("landingpages", {"title": title, "pageType":"categories"}).then(res => {
				navigate(LL("/editlandingcategory/" + res.data.landingPage.id));
				setModalOpen(false);
				window.location.pathname.includes('editlandingcategory') && window.location.reload();
			})
			.catch((error) => {
				setAlertType({ type: "error", text: error.response.data })
				setShowAlert(true);
			})
		}
		if (props.item.link === "editlandingregion/") {
			APIPost("landingpages", {"title": title, "pageType":"regions"}).then(res => {
				navigate(LL("/editlandingregion/" + res.data.landingPage.id));
				setModalOpen(false);
				window.location.pathname.includes('editlandingregion') && window.location.reload();
			})
			.catch((error) => {
				setAlertType({ type: "error", text: error.response.data })
				setShowAlert(true);
			})
		}
	};

	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	return (
		<>
		    <AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<span className="p-0 m-0" onClick={() => setModalOpen(true)}>{props.children}</span>
			<Modal autoFocus={false} fade={true} style={{ maxWidth: 690 }} isOpen={modalOpen} centered>
				<ModalBody style={{ padding: 0 }} className="pb-3"> {closeIcon}
					<div className="w-100">
						<div className="px-3">
							<div className="px-3 pt-5">
								<span>Create&nbsp;{props.item.title}</span>
								<form id='inquiry' onSubmit={(e) => e.preventDefault()}>
									<Row className="pt-0 mx-1 pt-sm-4">
										<div className="lightgrey text-sm">{props.item.title}&nbsp;<T>Title</T></div>
											<Input
												autoFocus={true}
												type="kform"
												className="py-3 text-weight-reg"
												onChange={(e) => setTitle(e.target.value)}
												name="name"
												id="name"
												form="inquiry"
												value={title}
											/>
									</Row>
									<div className="d-flex align-items-center w-100 flex-row-reverse mt-3">
										<div>
											<Kbutton custom="btn my-3 my-lg-0" prim md onClick={() => submit()}><T>Create</T></Kbutton>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default VerifyCreateSlug;