import React, { useState } from "react";
import DashboardContainer from "../DashboardContainer";
import { ToggleSwitch } from "../../Reusables";
import { T } from "../../../traks";

const LandingToggleVisibility = ({ landingPage, setLandingPage }) => {

    const [hasChanged, setHasChanged] = useState(false);

    const handleToggle = () => {
        setLandingPage(prevState => ({
            ...prevState,
            published: !prevState.published
        }));
        setHasChanged(hasChanged => !hasChanged);
    };

  	return (
        <DashboardContainer>
            <div className="px-2 py-4 d-flex">
                <ToggleSwitch
                    isOn={landingPage.published}
                    handleToggle={handleToggle}
                />
                <span className="py-1 ms-2"><T>Landing synligt:</T> &nbsp;{landingPage.published ? <T>Ja</T> : <T>Nej</T>}</span>
            </div>
            {hasChanged && <div className="px-2 mb-4 pb-4" style={{ color:"red" }}><T>Tryk på Opdater for at gemme ændringerne</T></div>}
        </DashboardContainer>
  	);
};

export default LandingToggleVisibility;