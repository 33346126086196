import { useState, useEffect } from "react";

// Custom hook to detect window size and eliminate duplicate code and SEO issues
export function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
			});
		}
		
		window.addEventListener('resize', handleResize);
		
		// Call handler right away so state gets updated with initial window size
		handleResize();
		
		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
}