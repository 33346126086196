import React from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { DashboardWrapper, EditNewsContent } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import { MeContext } from "MeContext";
import { useContext, useEffect, useState } from "react";
import NoAccess from "c/admin/NoAccess";
import { APIGet, APIPost, APIDelete } from "API";
import { AlertModal, RenderIf } from "c/Reusables";
import { Loading } from "c/Loading";
import { T } from "../traks";
import { LL } from "Routes";

const EditNews = ({ urlParams }) => {
	const navigate = useNavigate();
	urlParams = useParams();
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin")
	const [showLoading, setShowLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })

	const newsId = urlParams.newsId;
	const [news, setNews] = useState(undefined);
	const [noNews, setNoNews] = useState(false)

	const uploadImage = (files) => {
		setShowLoading(true)
		const filesInfo = files.map(({ file, ...f }) => f);
		APIPost("news/" + newsId + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const file = files[0];
			const preSignedUrl = res.data.upload_urls[0];
			const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
			return axios.put(preSignedUrl, file.file, { headers }).then(res => {
				const newFileName = preSignedUrl.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk");
				saveNews({ imageUrl: newFileName })
				setNews({ ...news, imageUrl: newFileName })
				setShowLoading(false)
				setAlertType({ type: "success", text: "Image uploaded" })
				setShowAlert(true);
				return newFileName;
			}).catch(err => console.log(err))
		}).catch(err => console.log(err))
	};

	useEffect(() => {
		if (urlParams.newsId) {
			setShowLoading(true)
			APIGet("news/" + newsId).then(res => {
				if (res.data) {
					if (!res.data)
						navigate(LL("/mynews/"));
				}
				setShowLoading(false)
				setNews(res.data.news);
			}).catch(err => {
				setShowLoading(false)
				setNoNews(true)
				setAlertType({ type: "error", text: err.response.data })
				setShowAlert(true);
			})
		} else {
			setShowLoading(true)
			navigate(LL("/mynews/"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveNews = (newNews = null) => {
		setShowLoading(true)
		let body = !newNews ? news : newNews;
		APIPost("news/" + newsId, body).then(res => {
			setShowLoading(false)
			setAlertType({ type: "success", text: "News saved" })
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false)
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	}

	const deleteNews = () => {
		setShowLoading(true)
		const resource = "news/" + newsId
		APIDelete(resource).then(res => {
			setShowLoading(false)
			navigate(LL("/mynews/"));
		}).catch(err => {
			setShowLoading(false)
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	}

	if (!isAdmin) {
		return (
			<DashboardWrapper index={10}>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!newsId) {
		return (
			<DashboardWrapper index={10}>
				<span><T>No news found</T></span>
			</DashboardWrapper>
		)
	}

	if (!news) {
		return (
			<DashboardWrapper index={10}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
				<RenderIf condition={noNews}>
					<span><T>No news found</T></span>
				</RenderIf>
			</DashboardWrapper>
		)
	}

	return (
		<DashboardWrapper index={12}>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<h5 className="mb-3">Rediger indlæg</h5>
			<div>
				<Notification>Du redigerer: {news["title"]}</Notification>
				<EditNewsContent
					uploadImage={uploadImage}
					deleteNews={deleteNews}
					news={news}
					setNews={setNews}
					saveNews={saveNews}
				/>
			</div>
		</DashboardWrapper>
	);
}

export default EditNews;

