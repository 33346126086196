import React, { useState } from "react";

const Kbutton = (props) => {
	const btn = "k-btn";
	const prim = " k-btn-prim";
	const danger = " k-btn-danger"
	const sec = " k-btn-sec";
	const white = " k-btn-white";
	const whitebg = " k-btn-map";
	const blue = " k-btn-blue";
	const blank = " k-btn-blank";
	const lg = " k-btn-large px-4 text-lg"
	const md = " btn-width py-2"
	const sm = " px-3 py-1";
	const round = " k-btn-round btn";
	const grey = " k-btn-grey";
	const lightgrey = " k-btn-greybg";
	const iconbtn = " iconButton";
	const disabled = props.disabled;
	const noborder = " border-0";
	const iconOnly = " icon-only-btn";
	const textSm = " text-md"

	let className = btn;

	if (props.prim) {className += prim}
	if (props.danger) {className += danger}
	if (props.sec) {className += sec}
	if (props.blank) {className += blank}
	if (props.md) {className += md}
	if (props.sm) {className += sm}
	if (props.round) {className += round}
	if (props.grey) {className += grey}
	if (props.white) {className += white}
	if (props.whitebg) {className += whitebg}
	if (props.blue) {className += blue}
	if (props.custom) {className += " " + props.custom}
	if (props.iconbtn) {className += iconbtn}
	if (props.noborder) {className += noborder}
	if (props.lightgrey) {className += lightgrey}
	if (props.iconOnly) {className += iconOnly}
	if (props.lg) {className += lg}
	if (props.textSm) {className += textSm}

	const [copyHover, setCopyHover] = useState(false);

	const insideElement = (<>
		<span>
			{props.icon}
		</span>
			{props.children}
		<span className="btn-title">
			{props.title}
		</span>
		{copyHover && <span style={{color: '#A7925B'}} className="ps-2">Kopier</span>}
	</>);

	if (props.divButton) {
		return (
			<div onClick={props.onClick} className={className}>
				{insideElement}
			</div>
		);
	}

	return (
		<button disabled={disabled} onMouseOver={() => props.copyable && setCopyHover(true)} onMouseLeave={() => props.copyable && setCopyHover(false)} onClick={props.onClick} className={className}>
			{insideElement}
		</button>
	);
}

export default Kbutton