import React, { useState, useEffect, useContext } from "react";
import DashboardContainer from "../c/admin/DashboardContainer";
import DashboardContainerHeader from "../c/admin/DashboardContainerHeader";
import ListItemLarge from "c/admin/ListItemLarge";
import "../css/dashboard.css";
import { T, TraksConsumer } from "../traks";
import { APIGet } from "API";
import { MeContext } from './../MeContext';
import NoAccess from "c/admin/NoAccess";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import { Loading } from "c/Loading";
import { AlertModal } from "c/Reusables";

const MyNews = () => {
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin")
	const [showLoading, setShowLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
	const [alertType, setAlertType] = useState({ type: "", text: "" })
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	useEffect(() => {
		APIGet("news").then(res => {
			setData(res.data.news);
			setFilteredData(res.data.news);
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
			setShowLoading(false)
		});
	}, [])

	const searchPlaceholder = <T>Search</T>;

	if (!isAdmin) {
		return <NoAccess />
	}

	if (!data.length) {
		return (
			<DashboardWrapper>
				<Loading
					showLoading={showLoading}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	return (
		<DashboardWrapper index={12}>
			<h5 className="mb-3"><T>Nyheder</T></h5>
			<DashboardContainer>
				<TraksConsumer>
					<DashboardContainerHeader
						valueToSearch={"title"}
						data={data}
						searchPlaceholder={searchPlaceholder}
						setData={setFilteredData}
						search
					>
						<T>Alle nyheder</T>
					</DashboardContainerHeader>
				</TraksConsumer>
				<span>
					{filteredData.map((news, i) => (
						<ListItemLarge
							key={"news" + i}
							title={news.title}
							visible={true}
							image={news.imageUrl ? news.imageUrl : ''}
							id={news.urlSlug}
							shortDescription={news.shortDescription}
							goTo={("/editnews/")}
						/>
					))}
				</span>
			</DashboardContainer>
		</DashboardWrapper>
	);
};

export default MyNews;
