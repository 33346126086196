// To add routes, edit `src/routes.json`, and add component name to `const
// components` below (reason for src/routes.json is server-side rendering)

import React from 'react';
import { Routes, Route, Link as RRLink, useResolvedPath, createPath } from 'react-router-dom';
import routes from './routes.json';
import { languages, language_set, DEFAULT_LANGUAGE} from './languages';

import AboutUs from './p/AboutUs';
import Home from './p/Home';
import Region from './p/Region';
import Listing from './p/Listing';
import Suppliers from './p/Suppliers';
import Venue from './p/Venue';
import Dashboard from './p/Dashboard';
import MyVenues from './p/MyVenues';
import MySuppliers from './p/MySuppliers';
import EditVenue from './p/EditVenue';
import EditSupplier from './p/EditSupplier';
import EditCategory from './p/EditCategory';
import EditSupplierCategory from './p/EditSupplierCategory';
import EditFacility from './p/EditFacility';
import EditRegion from './p/EditRegion';
import EditCertificate from './p/EditCertificate';
import Partners from './p/Partners';
import Categories from './p/Categories';
import SupplierCategories from './p/SupplierCategories';
import Facilities from './p/Facilities';
import Certificates from './p/Certificates';
import Regions from './p/Regions';
import PrivacyPage from './p/PrivacyPage';
import CookiesPage from './p/CookiesPage';
import TermsOfUse from './p/TermsOfUse';
import ContactUs from 'p/ContactUs';
import BookingConfirmation from './p/BookingConfirmation';
import News from 'p/News';
import NewsPage from 'p/NewsPage';
import Events from 'p/Events';
import EventMagazine from 'p/EventMagazine';
import EventMagazineOrder from 'p/EventMagazineOrder';
import EditNews from 'p/EditNews';
import MyNews from 'p/MyNews';
import MyEvents from 'p/MyEvents';
import EditEvent from 'p/EditEvent';
import MyMagazines from 'p/MyMagazines';
import EditMagazine from 'p/EditMagazine';
import NewsLetterSignup from 'p/NewsletterSignup';
import PartnerPage from 'p/PartnerPage';
import MyBanners from 'p/MyBanners';
import EditBanner from 'p/EditBanner';
import HotelOfMonthAdmin from 'p/HotelOfMonthAdmin';
import NotFound from './p/NotFound';
import { setLanguage } from 'helpers/setLanguage';
import LandingCategories from 'p/LandingCategories';
import EditLandingRegion from 'p/EditLandingRegion';
import EditLandingCategory from 'p/EditLandingCategory';
import LandingLocations from 'p/LandingLocations';
import EditLandingCategories from 'p/EditLandingCategories';
import EditLandingRegions from 'p/EditLandingRegions';

const components = {
	AboutUs,
	Home,
	Region,
	Listing,
	Suppliers,
	Venue,
	Dashboard,
	MyVenues,
	MySuppliers,
	EditVenue,
	Partners,
	PartnerPage,
	Categories,
	SupplierCategories,
	Facilities,
	Certificates,
	Regions,
	EditSupplier,
	EditCategory,
	EditSupplierCategory,
	EditFacility,
	EditRegion,
	EditCertificate,
	PrivacyPage,
	CookiesPage,
	TermsOfUse,
	ContactUs,
	BookingConfirmation,
	News,
	NewsPage,
	Events,
	EventMagazine,
	EventMagazineOrder,
	EditNews,
	MyNews,
	MyEvents,
	EditEvent,
	MyMagazines,
	EditMagazine,
	NewsLetterSignup,
	MyBanners,
	EditBanner,
	HotelOfMonthAdmin,
	EditLandingCategories,
	EditLandingRegions,
	LandingCategories,
	EditLandingCategory,
	EditLandingRegion,
	LandingLocations,
	NotFound
};

const KRoutes = (props) => {
	const get_component = (r) => {
		let C = components[r.component];
		if (C === undefined) throw new Error("component defined in routes.json but does not exist locally: " + r.component + " for route \"" + r.route + "\"");
		return <C />;
	}
	let rs = [];
	for (const route of routes) {
		for (const language of languages) {
			const key = language + ':' + route.route;
			let path = '/' + language + route.route;
			if(language === DEFAULT_LANGUAGE){
				path = '/' + route.route
			}
			rs.push(<Route key={key} path={path} element={get_component(route)} />);
		}
	}
	rs.push(
		<Route
			key={"notfound"}
			path={"*"}
			element={<NotFound />}
		/>,
	);
	rs.push(
		<Route
			key={"notfound"}
			path={"*"}
			element={<NotFound />}
		/>,
	);
	return <Routes>{rs}</Routes>;
};

const get_path_array = () => {
	// react-router-dom has `useLocation`, which ostensibly does the same
	// as `window.location`, but has these weird React-hook requirements
	// that make them unusable outside of components, so here's some really
	// old fashioned JS for you!
	return window.location.pathname.split("/").slice(1);
}

const get_current_language = () => {
	const lang = setLanguage();
	return lang;
};

const LL = (path) => {
	let newPath = path
	const try_lang = window.location.pathname.split('/').slice(1)[0].toLowerCase();
	if (language_set[try_lang]){
		newPath = '/' + get_current_language() + path;
	}
  newPath = (newPath + "/").replace("//", "/")
	return newPath
}


const Link = (props) => {
	const path = LL(createPath(useResolvedPath(props.to)));
	const pprops = { ...props, to: undefined, children: undefined };
	return <RRLink {...pprops} to={path}>{props.children}</RRLink>;
};

const goto_language = (lang) => {
	if (lang === get_current_language()) return;
	let xs = get_path_array();
	xs[0] = lang;
	window.location.pathname = xs.join("/");
};

export {
	KRoutes,
	Link,
	LL,
	get_current_language,
	goto_language,
};
