import React, { useState, useEffect, useContext } from "react";
import { APIGet } from "../API";
import CreateButton from "c/CreateButton";
import Sidebar from "c/admin/Sidebar";
import { MeContext } from '../MeContext';
import NoAccess from "c/admin/NoAccess";
import { T, TraksConsumer } from "../traks";
import { AlertModal } from "c/Reusables";
import DashboardContainer from "../c/admin/DashboardContainer";
import DashboardContainerHeader from "../c/admin/DashboardContainerHeader";
import ListItem from "../c/admin/ListItem";
import { LL } from 'Routes';
import { RenderIf } from "../c/Reusables";

const EditLandingCategories = () => {

	const { me } = useContext(MeContext);

	return (
		<div>
			{me.user.roles.includes("admin") ?
				<div className="dashboard">
					<CreateButton />
					<Sidebar index={9} />
					<div className="dashboard-wrapper" style={{ maxWidth: 1140 }}>
						<h5 className="mb-3"><T>Templates til kategorier</T></h5>
                        <LandingCategoriesList />
					</div>
				</div>
			: <NoAccess />}
		</div>
	);
}

const LandingCategoriesList = () => {

    const [data, setData ] = useState(undefined);
    const [queriedData, setQueriedData] = useState(data);

    const url = "/editlandingcategory/";

    useEffect(() => {
        APIGet("landingpages?pageType=categories&show_hidden=true").then(res => {
            setData(res.data.landing_pages);
			setQueriedData(res.data.landing_pages);
        }).catch(err => {
            setAlertType({ type: "error", text: err.response.data });
            setShowAlert(true);
        });
        // eslint-disable-next-line
    }, [])

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });
    const searchPlaceholder = <T>Search</T>;

    return (
        <DashboardContainer>
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
            <RenderIf condition={data && queriedData}>
                <TraksConsumer>
                    <DashboardContainerHeader
                        valueToSearch={"title"}
                        data={data}
                        searchPlaceholder={searchPlaceholder}
                        setData={setQueriedData}
                        search
                    >
                        <T>Landing kategorier</T>
                    </DashboardContainerHeader>
                </TraksConsumer>
                {queriedData && queriedData.map((item, i) => (
                    <ListItem key={i} icon="category" title={item.title} edit url={LL(url + item.id)} />
                ))}
            </RenderIf>
        </DashboardContainer>
    );
};

export default EditLandingCategories;