import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

const KCheckbox= (props) => {
	const items = props.data;
	return (
		<div>
			{items && items.map((k, i) => CheckboxItem(props, k, i))}
		</div>
	);
}

const CheckboxItem = (props, k, i) => {

	const [checked, setChecked] = useState(false);
	const defaultVal = props.defaultVal;
	const showAll = props.showAll;
	
	useEffect(() => {
		setChecked(false);
		if (props.preCheckedBoxes !== undefined && props.preCheckedBoxes.includes(k.id)) {
			checked ? setChecked(false) : setChecked(true);
		}
		// eslint-disable-next-line
	}, [defaultVal]);

	const itemClick = (type) => {
		props.setVal(type);
		setChecked(!checked);
	}

	return (
		<div key={i} className="mt-2">
			{((!showAll && i < 7) || showAll) &&
				<div onClick={() => itemClick(k.id)} className="pe-5 d-flex hoverfxlight">
					{checked && <img alt="checkbox" style={{ backgroundColor: "#242624", padding:"4px", borderRadius:"5px", marginRight:"6px" }} width={"20px"} height={"20px"} src="https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_checkmark_light.svg" />}
					{!checked && <img style={{ backgroundColor: "#EDF2F3", borderBlockColor: "black", borderColor: "black", padding: "4px", borderRadius: "5px", marginRight: "6px" }} width={"20px"} height={"20px"} alt="" />}
					<span className="text-md text-weight-reg font-primary">{k.name}</span>
				</div>
			}
		</div>
	);
}

KCheckbox.propTypes = {
	direction: PropTypes.string,
};

export default KCheckbox;