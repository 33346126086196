import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MeContext } from '../MeContext';
import { APIGet, APIPost, APIDelete } from "API";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import CreateButton from "c/CreateButton";
import Sidebar from "c/admin/Sidebar";
import { EditGroup } from "c/admin/DashboardComponents";
import { AlertModal } from "c/Reusables";
import NoAccess from "c/admin/NoAccess";
import { T } from "../traks";
import { Loading } from "c/Loading";
import { LL } from "Routes";

const EditSupplierCategory = ({ urlParams }) => {

	const { me } = useContext(MeContext);
	const navigate = useNavigate();
	const isAdmin = me && me.user.roles.includes("admin");

	urlParams = useParams();
	
	const catId = urlParams.catId;
	const [cat, setCat] = useState(undefined);

	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	useEffect(() => {
		setShowLoading(true);
		if (urlParams.catId) {
			APIGet("suppliercategories/" + catId).then(res => {
				setShowLoading(false);
				if (res.data) {
					if (!res.data)
						navigate(LL("/suppliercategories/"));
				}
				setCat(res.data.suppliercategory);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		} else {
			APIPost("suppliercategories", { "name": "new supplier category", "key": "new-suppliercategory" }).then(res => {
				setCat(res.data.new_suppliercategory);
				window.history.replaceState(null, '', res.data.new_suppliercategory.id);
				setShowLoading(false);
				window.location.reload(false);
			}).catch((err) => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		}
		// eslint-disable-next-line
	}, []);

	const saveCategory = (newCategory = null) => {
		setShowLoading(true);
		let body = !newCategory ? cat : newCategory;
		APIPost("suppliercategories/" + catId, body).then(res => {
			setShowLoading(false);
			setAlertType({ type: "success", text: "Category saved" });
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	const deleteCategory = () => {
		setShowLoading(true);
		const resource = "suppliercategories/" + catId;
		APIDelete(resource).then(res => {
			setShowLoading(false);
			navigate(LL("/suppliercategories/"));
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	if (!isAdmin) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!cat) {
		return (
			<DashboardWrapper index={8}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}
	
	return (
		<div className="dashboard mb-5">
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<CreateButton />
			<Sidebar index={8} />
			<div className="dashboard-wrapper">
				<h5 className="mb-3"><T>Rediger supplier kategori</T></h5>
				<Notification><T>Du redigerer</T>:&nbsp;{cat.name}</Notification>
				<div className="d-flex w-100">
					<EditGroup
						values={cat}
						setValues={setCat}
						save={saveCategory}
						deleteItem={deleteCategory}
						classname="w-75"
					/>
				</div>
			</div>
			<Loading
				showLoading={showLoading}
				inFront
			/>
		</div>
	);
}

export default EditSupplierCategory;