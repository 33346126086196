import React, { useEffect, useState } from "react";
import Header from "../c/Header";
import Footer from "../c/Footer";
import SelectedLocationsRegion from '../c/SelectedLocationsRegion';
import MeetingLocations from '../c/MeetingLocations';
import FixedContactButton from "c/FixedContactButton";
import RegionLandingContact from "../c/RegionLandingContact";
import RegionMap from "../c/RegionMap";
import axios from "axios";

const Region = () => {

	const [regionLocCode, setRegionLocCode] = useState(undefined);
	const [regionName, setRegionName] = useState(undefined);
	const [locStats, setLocStats] = useState(undefined);

	useEffect(() => {
		const filterUrl = window.location.origin.includes("https://optimeet.dk") ? "https://static.optimeet.dk/stats/stats.json" : "https://static.optimeet.dk/stats/test-stats.json"
		if (!locStats)
			axios.get(filterUrl).then(res => {
				setLocStats(res.data.venues_to_locations);
				let url = window.location.href;
				let queryStringArr = url.split('?');
				const queryString = queryStringArr[queryStringArr.length-1].toString().toLowerCase();
				const foundObject = res.data.venues_to_locations.find(obj => obj.name.toLowerCase() === queryString);
				if (foundObject) {
					setRegionLocCode(foundObject.id);
				}
				switch (queryString) {
					case 'norge':
						setRegionName('Norway');
					break;
					case 'sverige':
						setRegionName('Sweden');
					break;
					case 'tyskland':
						setRegionName('Germany');
					break;
					default:
						setRegionLocCode("");
						setRegionName("Europe"); //redirect to Listing
					break;
				}
			}).catch(err => console.log(err))
		// eslint-disable-next-line
	}, [locStats])

	return (
		<div>
			<Header />
			<RegionLandingContact regionName={regionName} />
			<SelectedLocationsRegion regionLocCode={regionLocCode} regionName={regionName} />
			<RegionMap regionLocCode={regionLocCode} regionName={regionName}/>
			<div className="mb-5 d-none d-md-block">
				<MeetingLocations regionLocCode={regionLocCode} regionName={regionName} />
			</div>
			<Footer />
			<FixedContactButton />
		</div>
	);
}

export default Region;
