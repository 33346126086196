import React from "react";
import Header from "c/Header";
import Footer from "c/Footer";
import BookingNewsletters from "c/BookingNewsletters";
import { TraksConsumer } from "../traks";


const NewsLetterSignup = () => {

	return (
		<>
			<Header />
			<TraksConsumer>
				<BookingNewsletters />
			</TraksConsumer>
			<Footer />
		</>
	);
}

export default NewsLetterSignup;
