import React, { useState } from "react";
import { T } from "../../traks";
import { Input } from "reactstrap";

const DashboardContainerHeader = (props) => {

  	const border = props.border ? " border-1 border-bottom" : null;
  	const insertClasses = `d-flex align-items-center justify-content-between p-4 list-header ${border}`;
	const thumbClasses = `d-flex align-items-center justify-content-left p-4 list-header${border}`

	const [searchWord, setSearchWord] = useState('');
	const valueToSearch = props.valueToSearch;

	const data = props.data;

	const Query = (e) => {
		setSearchWord(e.target.value);
		props.setData(data.filter(item => item[valueToSearch].toLowerCase().includes(String(e.target.value.toLowerCase()))));
	};

	const thumb = props.thumb ? <span className="me-2">{props.thumb}</span> 
		: props.info ? <img className='mt-1 me-1' alt="thumb" width={22} height={22} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_info.svg"} />
		: props.admin && <img className='mt-1 me-1' alt="thumb" width={20} height={20} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_settings.svg"} />;

  	return (
      	<div className={props.thumb ? thumbClasses : insertClasses}>
        	{thumb}<div className="mt-1 d-flex justify-content-between w-100">{props.children}</div>
			{props.search &&
				<div className="d-flex w-50 justify-content-end">
					<div className="text-sm d-flex ms-2"/>
						<span className="me-2 w-50 mt-2">{props.filter}</span>
						<Input
							id="search-input"
							className="py-2 text-weight-reg search_bg_transparent w-100"
							type="text" 
							placeholder={props.render_static(<T>Search</T>)}
							value={searchWord}
							autoFocus={true}
							onChange={(e) => {Query(e)}}
						/>
				</div>
			}
      	</div>
  	);
};

export default DashboardContainerHeader;