import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoginModal from "./LoginModal";

const ModalOpenerListener = ( {urlParams = undefined} ) => {

	urlParams = useParams();
    const [openResetPSModal, setOpenResetPSModal] = useState(undefined);
	const [openSendNewPS, setOpenSendNewPS] = useState(undefined);
	const [sessionId, setSessionsId] = useState(undefined);

	useEffect(() => {
		function extractSessionIdFromUrl() {
			const searchParams = new URLSearchParams(window.location.search);
			const sessionId = searchParams.get("session_id");
			const resetPs = searchParams.get("resetps");
			sessionId && setOpenResetPSModal(true);
			resetPs && setOpenSendNewPS(true);
			return sessionId;
		}
		const sessionId = extractSessionIdFromUrl();
		setSessionsId(sessionId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hideDropdown = () => {
		setOpenResetPSModal(undefined);
	}

	return (
		<div>
			{openResetPSModal ?
				<LoginModal closeModal={hideDropdown} sessionid={sessionId} disableDropdown={(null)}>
					{ openResetPSModal && <span newpsstage={openResetPSModal} stage="CreateNewPassword"></span> }
				</LoginModal>
			: null}
			{openSendNewPS ?
				<LoginModal closeModal={hideDropdown} disableDropdown={(null)}>
					{ openSendNewPS && <span newpsstage={openSendNewPS} stage="ResetPassword"></span> }
				</LoginModal>
			: null}
		</div>
	);
}

export default ModalOpenerListener;