import React, { useState } from "react";
import { T } from "../traks";
import Kbutton from "./KButton";
import { Input } from "reactstrap";
import DashboardContainer from "./admin/DashboardContainer";
import DashboardContainerHeader from "./admin/DashboardContainerHeader";

const EditFAQ = ( { data = [], setData } ) => {

    const [editIndex, setEditIndex] = useState(null);
    const [newQuestion, setNewQuestion] = useState("");
    const [newAnswer, setNewAnswer] = useState("");
    const [isEditing, setIsEditing] = useState(false);
  
    const handleEdit = (index) => {
        setIsEditing(true);
        setEditIndex(index);
        setNewQuestion(data[index].question);
        setNewAnswer(data[index].answer);
    };
  
    const handleSave = () => {
        setIsEditing(false);
        const updatedData = [...data];
        updatedData[editIndex] = { question: newQuestion, answer: newAnswer };
        setData(updatedData);
        setEditIndex(null);
        setNewAnswer("");
        setNewQuestion("");
    };
  
    const handleDelete = (index) => {
        setIsEditing(false);
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
    };
  
    const handleAdd = () => {
        setIsEditing(false);
        const updatedData = [...data, { question: newQuestion, answer: newAnswer }];
        setData(updatedData);
        setNewQuestion("");
        setNewAnswer("");
    };

    return (
        <DashboardContainer>
            <DashboardContainerHeader thumb={<i style={{ fontSize: 20 }} className="bi bi-question"/>} border><T>FAQ</T></DashboardContainerHeader>
            <div className="pb-1 px-4">
                {data && data.map((item, index) => (
                    <div key={index}>
                        {index === editIndex ? (
                            <div>
                                <Input
                                    value={newQuestion}
                                    onChange={(e) => setNewQuestion(e.target.value)}
                                    placeholder="Spørgsmål"
                                    className="py-3 text-weight-reg input-light-blue"
                                />
                                <Input
                                    value={newAnswer}
                                    onChange={(e) => setNewAnswer(e.target.value)}
                                    placeholder="Svar"
                                    className="py-3 mt-3 text-weight-reg input-light-blue"
                                />
                                <Kbutton custom="my-2" md onClick={handleSave}><T>Gem</T></Kbutton>
                            </div>
                        ) : (
                            <div className="my-2">
                                <div className="my-2"><span className="fw-bold"><T>Question</T></span>: {item.question}</div>
                                <div className="my-2"><span className="fw-bold"><T>Answer</T></span>: {item.answer}</div>
                                <div className="my-3">
                                    <Kbutton prim sm custom={"me-2"} onClick={() => handleEdit(index)}><T>Rediger</T></Kbutton>
                                    <Kbutton prim sm onClick={() => handleDelete(index)}><T>Slet</T></Kbutton>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                {!isEditing && 
                    <div className="my-4">
                        <Input
                            value={newQuestion}
                            onChange={(e) => setNewQuestion(e.target.value)}
                            placeholder="Ny spårgsmål"
                            className="py-3 text-weight-reg input-light-blue"
                        />
                        <Input
                            value={newAnswer}
                            onChange={(e) => setNewAnswer(e.target.value)}
                            placeholder="Ny svar"
                            className="py-3 mt-3 text-weight-reg input-light-blue"
                        />
                        <Kbutton md custom={"mt-3"} prim onClick={handleAdd}><T>Tilføje</T></Kbutton>
                    </div>
                }
            </div>
        </DashboardContainer>
    );
};

export default EditFAQ;