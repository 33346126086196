import React from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { DashboardWrapper, EditEventContent } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import { MeContext } from "MeContext";
import { useContext, useEffect, useState } from "react";
import NoAccess from "c/admin/NoAccess";
import { APIGet, APIPost, APIDelete } from "API";
import { AlertModal } from "c/Reusables";
import { T } from "./../traks";
import { Loading } from "c/Loading";
import { LL } from "Routes";

const EditEvent = ({ urlParams }) => {
	const navigate = useNavigate();
	urlParams = useParams();
	const { me } = useContext(MeContext);
	const isAdmin = me && me.user.roles.includes("admin");
	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });
	const eventId = urlParams.eventId;
	const [event, setEvent] = useState(undefined);

	useEffect(() => {
		setShowLoading(true);
		if (eventId) {
			APIGet("events/" + eventId).then(res => {
				setShowLoading(false);
				if (res.data) {
					if (!res.data)
						navigate(LL("/myvenues/"));
				}
				setEvent(res.data.event);
			}).catch(err => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		} else {
			APIPost("events", { "title": "new event" }).then(res => {
				setShowLoading(false);
				setEvent(res.data.new_event);
				window.history.replaceState(null, '', res.data.new_event.id);
				window.location.reload(false);
			}).catch((err) => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveEvent = () => {
		setShowLoading(true)
		APIPost("events/" + eventId, event).then(res => {
			setShowLoading(false);
			setAlertType({ type: "success", text: "Event saved" });
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);
		})
	};

	const deleteEvent = () => {
		setShowLoading(true);
		const resource = "events/" + eventId;
		APIDelete(resource).then(res => {
			navigate(LL("/myevents/"));
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	};

	const uploadImage = (files) => {
		setShowLoading(true)
		const filesInfo = files.map(({ file, ...f }) => f);
		APIPost("events/" + eventId + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const file = files[0];
			const preSignedUrl = res.data.upload_urls[0];
			const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
			return axios.put(preSignedUrl, file.file, { headers }).then(res => {
				const newFileName = preSignedUrl.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk");
				saveEvent({ imageUrl: newFileName })
				setEvent({ ...event, imageUrl: newFileName })
				setShowLoading(false)
				setAlertType({ type: "success", text: "Image uploaded" })
				setShowAlert(true);
				return newFileName;
			}).catch(err => console.log(err))
		}).catch(err => console.log(err))
	};

	if (!isAdmin) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!event) {
		return (
			<DashboardWrapper index={13}>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}

	return (
		<DashboardWrapper index={13}>
			<h5 className="mb-3"><T>Rediger arrangement</T></h5>
			<div>
				<Notification><T>Du redigerer</T>:&nbsp;{event["title"]}</Notification>
				<EditEventContent
					uploadImage={uploadImage}
					saveEvent={saveEvent}
					event={event}
					setEvent={setEvent}
					deleteEvent={deleteEvent}
				/>
			</div>
			<Loading
				showLoading={showLoading}
				inFront
			/>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
		</DashboardWrapper>
	);
}

export default EditEvent;