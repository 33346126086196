import React, { useEffect, useState } from "react";
import { APIPost } from "API";
import { Col, Row } from "reactstrap";
import { PromotedCard } from './Cards';
import { T } from '../traks';

const SelectedLocations = () => {

	const [hotelOfMonthData, setHotelOfMonthData] = useState(undefined);

	useEffect(() => {
		const body = {query: {limit: 4, page: 0, venueOfTheMonth: true}};
		APIPost("search", body).then(res => {
			setHotelOfMonthData(res.data.result);
		}).catch(err => console.log(err));
		// eslint-disable-next-line
	}, []);

	return (
        <>
            {!!hotelOfMonthData && !!hotelOfMonthData.length &&
                <div className="container mt-5">
                    <h2 className="justify-content-start justify-content-lg-center d-flex mb-4"><T>Månedens hotel</T></h2>
                    <div>
                        <div className="container promoted-cards-wrapper px-0">
                            <Row className="scrolling-wrapper-flexbox scroll-smooth font-primary">
                                {hotelOfMonthData.map((v, i) => (
                                    <Col key={i} xs={12} sm={6} md={6} lg={3} xl={3} className="d-flex mb-0 mb-sm-4 mb-lg-0 justify-content-center promoted-card-container">
                                        <>
                                            <PromotedCard
                                                data={v} key={"promoted" + i}
                                            />
                                        </>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            }
        </>
	);
};

export default SelectedLocations;