import React from "react";
import DashboardContainer from "../DashboardContainer";
import { T } from "../../../traks";
import DashboardContainerHeader from "../DashboardContainerHeader";
import DropLoad from "../JacobsImageCrop";

const LandingImage = ({ pageContent, files, setFiles, deleteImage, handleUploadImage }) => {

  	return (
        <DashboardContainer>
            <DashboardContainerHeader thumb={<i style={{ fontSize: 20 }} className="bi bi-image"/>} border><T>Billede</T></DashboardContainerHeader>
            <DropLoad
                files={files}
                setFiles={setFiles}
                setDeleted={() => deleteImage()}
                uploadFiles={handleUploadImage}
                updateOrder={null}
                multiple={false}
                imagesUrls={pageContent.imagesUrls}
                canUpload={true}
            />
        </DashboardContainer>
  	);
};

export default LandingImage;