import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import VerifyCreateSlug from "../modals/VerifyCreateSlug";
import { LL } from 'Routes';

const DropDownButton = ({ direction, title, icon = undefined, data }) => {
	
  	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	function toggle() {
		if (modalIsOpen && !isHovered) {
			setDropdownOpen(false);
			setModalIsOpen(false);
		} else {
			setDropdownOpen(true);
			setModalIsOpen(true);
		}
	};
	const [isHovered, setIsHovered] = useState(false);

  	return (
		<div className="d-flex k-dropdown-button">
			<Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
				<DropdownToggle className="dropdown-button rounded-pill border-0 px-4 py-2">
					{icon}{title}
				</DropdownToggle>
				<DropdownMenu onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
					{data.map((item, i) => {
						return (
							<DropdownItem key={i}>
								{item.link === "editvenue/" || item.link === "editnews/" || item.link === "editsupplier/" || item.link === "editlandingregion/" || item.link === "editlandingcategory/" ? 
									<VerifyCreateSlug setModalIsOpen={setModalIsOpen} item={item}>							
										<span><img alt={"btnImg"} width={16} height={20} src={item.icon} className="me-2" />{item.title}</span>
									</VerifyCreateSlug>	
								:
									<a className="link_nostyle" href={LL("/" + item.link)}>
										<img alt={"btnImg"} width={16} height={20} src={item.icon} className="me-2" />{item.title}
									</a>
								}
							</DropdownItem>
						)
					})}
				</DropdownMenu>
			</Dropdown>
		</div>
  	);
}

DropDownButton.propTypes = {
  	direction: PropTypes.string,
};

export default DropDownButton;