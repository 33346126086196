import React from "react";

const SliderCategory = (props) => {

	const handleClick = () => {
		const searchFilters = {
			searchWord: '',
			location: '',
			type: props.urlId,
			price: '',
			facilities: '',
			certificates: '',
			page: 0,
		};
		const searchFiltersString = JSON.stringify(searchFilters);
		sessionStorage.setItem('OptimeetSearchFilters', searchFiltersString);
		window.location.href = ("/konferencesteder/kategorier/" + props.urlName);
	}

	return  (
		<span onClick={() => handleClick()} className="category p-3 mx-2 btn hover-effect">
			<div className="centerItem my-3 pt-3">{props.icon}</div>
			<div className="text-center text-md pt-3 onelinetext">{props.children}</div>
		</span>
	);
}

export default SliderCategory;