import React from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import FixedContactButton from "c/FixedContactButton";

const ContactUsInner = () => {
		
	return (
		<Container>
			<Row className="flex-column pt-5" style={{paddingBottom: "100px"}}>
				<Col className="pt-5">
					<h2>Hjælp til at finde et konferencested</h2>
					<ul className="p-0" style={{listStyle: "none"}}>
						<li>Konference Booking</li>
						<li>Flakhaven 1</li>
						<li>5000 Odense</li>
						<li>Tlf.: +45 33 97 43 43</li>
						<li>
							<a href="mailto: info@konference.dk">
							info@konference.dk
							</a>
						</li>
					</ul>
				</Col>
				<Col className="pt-5">
					<h2>Bliv Partner med dit møde- eller konferencested</h2>
					<p>Ønsker du at blive partner på Konference.dk og få synlighed overfor tusindevis af professionelle møde- og eventplanlæggere?</p>
					<ul className="p-0" style={{listStyle: "none"}}>
						<li>Kursuslex</li>
						<li>Bregnerødvej 132A</li>
						<li>3460 Birkerød</li>
						<li>Tlf.: +45 70 22 11 73</li>
						<li>
							<a href="mailto: salg@konference.dk">
								salg@konference.dk
							</a>
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
}

const ContactUs = () => {
	return (
		<div>
			<Header />
			<ContactUsInner />
			<Footer />
			<FixedContactButton />
		</div>
	);
}

export default ContactUs;
