import React, { useState, useEffect } from "react";
import { Collapse, Container } from "reactstrap";
import Kbutton from './KButton';
import { useLocation, useParams } from 'react-router-dom';
import { APIPost } from "API";
import Map from "./Map";
import { T, TraksConsumer } from "../traks";
import GetHelp from "./GetHelp";
import Footer from "./Footer";
import FixedContactButton from "./FixedContactButton";
import { VenueHeader, MapAddress, VenueGallery, VenueDescription, Bookings, VenueInquire, Information, CapacityFacilites } from "./SharedVenueComponent";
import { useWindowSize } from "./Hooks/useWindowSize";

const VenueComponent = () => {
	const urlParams = useParams();
	const location = useLocation();
	const preview = location["state"] && location.state["preview"];
	const urlSlug = urlParams.venueNameId
	const [venueData, setVenueData] = useState(undefined);

	const iconPhone = <img width={17} height={17} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_phone-0.svg" alt='iconPhone' />
	const iconMail = <img width={17} height={13} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_mail-0.svg" alt='iconMail' />
	const iconWebsite = <img width={17} height={13} className="mb-1 me-1" src="https://static.optimeet.dk/gfx/ico_link-0.svg" alt='iconWebsite' />	

	useEffect(() => {
		const body = { query: { urlSlug: urlSlug} }
		if(preview)
			body.query["show_hidden"] = true
		APIPost("search", body).then(res => {
			setVenueData(res.data.result[0]);
			window.scrollTo({ top: 0, behavior: 'instant' });
		}).catch(err => {
			console.log(err);
		})
		// eslint-disable-next-line
	}, []);

	const { width } = useWindowSize();
	const breakpoint = 1200;

	if (venueData !== undefined ) {
		return (
			<div className="container-fluid p-0 m-0"> 
				<div className="container">
					{width > breakpoint ?
					//DESKTOP
					<div>
						<VenueHeader data={venueData} />
							<VenueGallery data={venueData} />
							<Container>
								<div className="d-flex flex-column flex-lg-row pt-4">
									<VenueDescription data={venueData} />
									<TraksConsumer>
										<RightPanel data={venueData}/>
									</TraksConsumer>
								</div>
						</Container>
						{venueData.dollHouseUrl && <DollHouse data={venueData}/>}
						{venueData.geoLatLong !== undefined &&
						<div id="scroll_map" className="mt-2 find-location p-5">
							<div>
								<div className="mb-3">
									<h5><T>Find vej</T></h5>
								</div>
								<div className="d-flex">
									<Map data={venueData} customCss={"venuemap"} />
									<div className="d-flex flex-column w-100">
										<div className="ms-4 mb-4 w-100 find-location-text p-4 pt-2">
											<h5 className="mt-4">{venueData.title}</h5>
											<div>
												<div className="mt-3">
													{venueData.address_1}
												</div>
												<div>
													<span className="pe-1">
														{venueData.zipcode} -
													</span>
													<span>
														{venueData.city}
													</span>
												</div>
												<div className="mt-3">
													{venueData.address_2}
												</div>
											</div>
										</div>
										<div className="pt-3 d-flex flex-column ms-4 w-100">
											<Kbutton icon={iconPhone} copyable title={venueData.phoneNumber} onClick={() => navigator.clipboard.writeText(venueData.phoneNumber)} grey custom=" btn text-black py-2 px-3 me-2" />
											<Kbutton icon={iconMail} copyable title={venueData.email} onClick={() => navigator.clipboard.writeText(venueData.email)} grey custom=" btn text-black py-2 px-3 me-2 my-2" />
											<Kbutton icon={iconWebsite} onClick={()=> window.open(venueData.website, "_blank")} title={"Besøg hjemmeside"} grey custom=" btn text-black py-2 px-3 me-2" />
										</div>
									</div>
								</div>
							</div>
						</div>}
						<div className="pb-0 pb-xl-5 mb-0 mb-xl-5 d-none d-xl-block">
							<GetHelp />
						</div>
					</div>
						// DESKTOP END
						:
					<div>
						<VenueHeader data={venueData} />
						<VenueGallery data={venueData} />
						<VenueInquire data={venueData} />
						<VenueDescription data={venueData} />
						<Bookings data={venueData} />
						<Information data={venueData} />
						<EnvironmentCert data={venueData} />
						<TraksConsumer>
							<CapacityFacilites data={venueData} />
						</TraksConsumer>
						<MapAddress data={venueData} />
						</div>
					}
				</div>
				<Footer />
				<FixedContactButton />
			</div>
		);
	} else {
		return (
			<div className="w-100" style={{height: 1900}}></div>
		);
	}
}

const RightPanel = (props) => {

	return (
		<div className="ms-3">
			<VenueInquire data={props.data} />
			<Bookings data={props.data} />
			<Information data={props.data} />
			<EnvironmentCert data={props.data} />
			{/* <Profile data={props.data} />*/}
		</div>
	);
};

const EnvironmentCert = (props) => {

	const certs = props.data.certificates;
	return (
		<div>
			{!!props.data.certificates.length &&
				<div className="mt-3">
					<div className="venue_rightpanel_scale bg-white px-4 py-4">
						<div className="text-center text-md text-bold mt-1 mb-3"><T>Miljømærkninger</T></div>
						<span>
							{certs.map((cert, i) => (
								<img className="me-2 rounded-circle" width={60} height={60} key={i} src={cert.imageUrl} alt={cert.key} />
							))}
						</span>
					</div>
				</div>}
		</div>
	);
};

const DollHouse = (props) => {

	const height = window.innerWidth <= 768 ? "100%" : "624vh";
	const bg = window.innerWidth <= 768 ? "p-0 p-lg-4" : "p-0 p-lg-4 find-location";

	return (
		<div className="container px-0 py-5">
			<div className="pt-2 d-none d-lg-block">
				<Collapse className={bg} isOpen={true}>
					<div>
						<iframe
							title="3D venue"
							src={props.data.dollHouseUrl}
							width="100%"
							height={height}
							style={{borderRadius:"12px"}}
						/>
					</div>
				</Collapse>
			</div>
		</div>
	);
};

// const Profile = (props) => { //not in the first version, wip
// 	return (
// 		<div className="container mt-3">
// 			<div className="venue_rightpanel_scale shadow bg-white px-4 py-4 text-center">
// 				<div className="mt-2 opposites pb-1">
// 					<span>
// 						<img width={40} height={40} className="mb-1 me-2" src={props.data.iconUrl} alt='icoprofilethumb' />
// 						<div className="text-md text-weight-med py-1">{props.data.title}</div>
// 						<div className="text-md text-weight-med py-1">{props.data.locationsAmount}</div>
// 					</span>
// 					<span className="text-center text-md text-weight-med primarycolor pt-4 pb-3">Se alle priser</span>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

export default VenueComponent;