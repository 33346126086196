import React from "react";
import { T } from "../../traks";
import { VenueImg, RedigerBtn, TStoReadable } from "c/Reusables";
import { LL } from "Routes";

const ListItemVenueLarge = ({ title, address, image, visible, id, timestamp, priority, supplier = undefined, goTo }) => {

	const IsVisible = () => {
		return (
			<div>
				{visible ? <span className="primarycolor-light"><T>Synligt</T></span> : <span className="red_text"><T>Skjult</T></span>}
			</div> 
		);
	}

	return (
		<div className="border-1 border-top text-md list-item">
			<a className="link_nostyle" href={LL(goTo + id)}>
				<div className="py-3 px-4 d-flex align-items-center justify-content-between">
					<div style={{ width: "700px" }} className="d-flex align-items-center">
						<div className="me-3">
							{image ? <VenueImg supplier={supplier} card className="list-item-venue-img" img={image} alt="imagethumb" /> : <span className="px-5 py-1 me-3" style={{backgroundColor: "#F4EFE4", borderRadius: 7}}>No img</span>}
						</div>
						<div>
							<div>
								<span className="h6">{title}</span> 
							</div>
							<div className="mt-2">
								<i className="bi bi-geo-alt-fill"></i>
								<span>{address}</span>
							</div>
							<div className="mt-2">
								<span>{visible}</span>
							</div>
							<div>
								<span><IsVisible /></span>
							</div>
						</div>
					</div>
					<div>
						<T>Last edited:</T>&nbsp;<TStoReadable date={timestamp} />
						{priority > 1 && <div className="text-bold">Premium: {priority}</div>}
					</div>
					<RedigerBtn />
				</div>
			</a>
		</div>
	);
};

export default ListItemVenueLarge;