import React, { useEffect, useState, useContext } from "react";
import axios from "axios"
import { useParams } from "react-router-dom";
import "../../css/dashboard.css";
import { APIGet, APIPost } from "API";
import { T } from "../../traks";
import { MeContext } from '../../MeContext';
import { DataContext } from '../../DataContext';
import NoAccess from "c/admin/NoAccess";
import { AlertModal, RenderIf } from "c/Reusables";
import { Loading } from "c/Loading";
import { EditSupplier } from "./EditSupplier.js";
import { DashboardWrapper } from "../../c/admin/DashboardComponents";
import fetchImgsWithRetry from "../../c/functions/fetchImgsWithRetry";

export const EditSupplierPage = ({ urlParams }) => {
	urlParams = useParams();
	const { me } = useContext(MeContext);
	const Id = urlParams.supplierId;
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });
	const [showLoading, setShowLoading] = useState(false);
	const [data, setData] = useState(undefined);
	const [noPartner, setNoPartner] = useState(false);
	const [partners, setPartners] = useState([]);
	const [locations, setLocations] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		setShowLoading(true);
		APIGet("suppliers/" + Id).then(res => {
			const { supplier } = res.data;
			setData(supplier);
			setPartners(res.data.users);
			setLocations(res.data.locations);
			setCategories(res.data.suppliercategories);
			setShowLoading(false);
		}).catch(err => {
			setShowLoading(false);
			setNoPartner(true);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
		// eslint-disable-next-line
	}, []);


	const uploadFiles = (files) => {
		setShowLoading(true);
		const filesInfo = files.map(({ file, ...f }) => f)
		//get presigned urls for upload
		APIPost("suppliers/" + Id + "/files", { files: filesInfo, action: "get_upload_url" }).then(res => {
			const preSignedUrls = res.data.upload_urls;
			//upload each file to the upload folder
			const promises = preSignedUrls.map((url, index) => {
				const file = files[index];
				const headers = { "Content-Type": file.type, 'x-amz-acl': 'public-read' };
				return axios.put(url, file.file, { headers }).then(res => {
					const newFileName = url.split("?X-Amz")[0].replace("-konference-dk.s3.amazonaws.com", ".optimeet.dk");
					return newFileName
				}).catch(err => console.log(err))
			})
			// at this point we have the new temporary upload images
			Promise.allSettled(promises).then(res => {
				const newImagesUrls = res.map(el => el["value"]);
				const totalNewImageArr = (data["imagesUrls"] || []).concat(newImagesUrls);
				const resource = "suppliers/" + Id + "/images";
				APIPost(resource, { "imagesUrls": totalNewImageArr }).then(res => {
					fetchImgsWithRetry(totalNewImageArr.length, "suppliers/" + Id, "supplier", setData, setShowLoading);
				}).catch(err => console.log(err));
			}).catch(err => console.log(err));
		}).catch(err => console.log(err));
	};

	const saveSupplier = (newSupplier = null) => {
		if (!showLoading) {
			setShowLoading(true);
			let body = !newSupplier ? data : newSupplier;
			APIPost("suppliers/" + Id, body).then(res => {
				setData(res.data.edited_supplier);
				setShowLoading(false);
				setAlertType({ type: "success", text: "Supplier saved" });
				setShowAlert(true);
			}).catch(err => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		};
	};

	if (!me) {
		return (
			<DashboardWrapper index={7}>
				<NoAccess />
			</DashboardWrapper>
		)
	};

	if (!Id) {
		return (
			<DashboardWrapper index={7}>
				<span><T>No supplier found</T></span>
			</DashboardWrapper>
		)
	};

	if (data === undefined) {
		return (<>
			<DashboardWrapper index={7}>
				<Loading
					showLoading={showLoading}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
				<RenderIf condition={noPartner}>
					<span><T>No supplier found</T></span>
				</RenderIf>
			</DashboardWrapper>
		</>)
	};

	return (
		<DashboardWrapper index={7}>
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<Loading
				showLoading={showLoading}
				inFront
				overlay
			/>
			<DataContext.Provider value={{ data, setData, partners, setPartners, Id, locations, categories }}>
				<EditSupplier
					supplier={data}
					saveSupplier={saveSupplier}
					uploadFiles={uploadFiles}
				/>
			</DataContext.Provider>
		</DashboardWrapper>
	);
};