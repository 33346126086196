import React from "react";
import { useState } from "react";
import { APIPost } from "API";
import { Row, Col, Container, Input } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import { T } from "../traks";
import Kbutton from "c/KButton";
import { isValidEmail, AlertModal } from '../c/Reusables';
import KCheckbox from "c/KCheckbox";

const CheckSignUps = (props) => {

	const checkboxData = [
		{
			"name": "Jeg vil gerne modtage Optimeet News - gratis",
			"id": "0",
		},
	]

	return (
		<KCheckbox
			data={checkboxData}
			setVal={(clickData) => { props.setVal(clickData) }}
		/>
	);
}

const Form = () => {

	const [inquiryForm, setInquiryForm] = useState({
		'company': '',
		'title': '',
		'name': '',
		'lastName': '',
		'address': '',
		'city': '',
		'zipCode': '',
		'telephone': '',
		'email': '',
		'signupNews': false,
	});

	const canSubmit = inquiryForm.name && inquiryForm.lastName && inquiryForm.city && inquiryForm.address && inquiryForm.zipCode && inquiryForm.telephone && isValidEmail(inquiryForm.email) && inquiryForm.signupNews;

	const setCheckBox = () => {
		setInquiryForm({ ...inquiryForm, signupNews: !inquiryForm.signupNews });
	};

	const [sendSuccess, setSendSuccess] = useState(false);
	const [clicked, setClicked] = useState(false);

	function orderMagazine() {
		setClicked(true);
		if (canSubmit) {
			APIPost("order-magasin", inquiryForm).then(res => {
				setSendSuccess(true);
			}).catch(err => setAlertType({ type: "error", text: err.response.data }))
		}
	}

	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	return (
		<div className="w-100">
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<div className="px-3 pt-4">
			{!sendSuccess &&
				<form id='inquiry' onSubmit={(e) => e.preventDefault()}>
					<div className="mb-3">
						<div className="lightgrey text-sm"><T>Firma / Organisation</T></div>
						<Input
							autoFocus={true}
							type="kform"
							className="py-3 text-weight-reg"
							onChange={(e) => setInquiryForm({ ...inquiryForm, company: e.target.value })}
							name="company"
							id="company"
							form="inquiry"
							value={inquiryForm.company}
						/>
					</div>
					<div className="mb-3">
						<div className="lightgrey text-sm"><T>Titel</T></div>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							onChange={(e) => setInquiryForm({ ...inquiryForm, title: e.target.value })}
							name="company"
							id="company"
							form="inquiry"
							value={inquiryForm.title}
						/>
					</div>
					<div className="mb-3">
						<div className="lightgrey text-sm"><T>Fornavn</T></div>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							onChange={(e) => setInquiryForm({ ...inquiryForm, name: e.target.value })}
							name="name"
							id="name"
							form="inquiry"
							value={inquiryForm.name}
						/>
						{!inquiryForm.name && clicked && <div className="red_text"><i><T>Indsæt venligst navn</T></i></div>}
					</div>
					<div className="mb-3">
						<div className="lightgrey text-sm"><T>Efternavn</T></div>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							onChange={(e) => setInquiryForm({ ...inquiryForm, lastName: e.target.value })}
							name="name"
							id="name"
							form="inquiry"
							value={inquiryForm.lastName}
						/>
						{!inquiryForm.lastName && clicked && <div className="red_text"><i><T>Indsæt venligst navn</T></i></div>}
					</div>
					<div className="w-100 me-1 mb-3 mb-md-0">
						<div className="lightgrey text-sm"><T>Address</T></div>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							onChange={(e) => setInquiryForm({ ...inquiryForm, address: e.target.value })}
							name="address"
							id="address"
							form="inquiry"
							value={inquiryForm.address}
						/>
						{!inquiryForm.address && clicked && <div className="red_text"><i><T>Indsæt venligst addressen</T></i></div>}
					</div>
					<div className="mt-2 mb-3">
						<div className="d-flex flex-column flex-md-row">
							<div className="w-100 me-1 mb-3 mb-md-0">
								<div className="lightgrey text-sm"><T>By</T></div>
								<Input
									type="kform"
									className="py-3 text-weight-reg"
									onChange={(e) => setInquiryForm({ ...inquiryForm, city: e.target.value })}
									name="name"
									id="name"
									form="inquiry"
									value={inquiryForm.city}
								/>
							</div>
							<div className="w-100 ms-1">
								<div className="lightgrey text-sm"><T>Post. nr.</T></div>
								<Input
									type="kform"
									className="py-3 text-weight-reg"
									onChange={(e) => setInquiryForm({ ...inquiryForm, zipCode: e.target.value.replace(/\D/g, '') })}
									name="name"
									id="name"
									form="inquiry"
									value={inquiryForm.zipCode}
								/>
							</div>
						</div>
						{((!inquiryForm.city && inquiryForm.city.length && clicked) || (clicked && !inquiryForm.zipCode.length)) && <div className="red_text"><i><T>Indsæt venligst by og postnummer</T></i></div>}
					</div>
					<div>
						<div className="lightgrey text-sm"><T>TELEFON NR. *</T></div>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							placeholder={"+45"}
							onChange={(e) => setInquiryForm({ ...inquiryForm, telephone: e.target.value })}
							name="telefon"
							id="telefon"
							form="inquiry"
							value={inquiryForm.telephone}
						/>
						{!inquiryForm.telephone && clicked && <div className="red_text"><i><T>Indsæt venligst telefonnummer</T></i></div>}
					</div>
					<div>
						<div className="lightgrey text-sm mt-2"><T>EMAIL *</T></div>
						<Input
							type="kform"
							className="py-3 text-weight-reg"
							onChange={(e) => setInquiryForm({ ...inquiryForm, email: e.target.value })}
							name="email"
							id="email"
							form="inquiry"
							value={inquiryForm.email}
						/>
						{(!isValidEmail(inquiryForm.email) && clicked) && <div className="red_text"><i><T>Indtast venligst en gyldig email</T></i></div>}
					</div>
					<div className="mt-4 mb-4">
						<CheckSignUps setVal={setCheckBox} />
						{!inquiryForm.signupNews && clicked && <div className="red_text"><i><T>Accepter venligst</T></i></div>}
					</div>
					<div className="w-100 mb-3 mt-3">
						<Kbutton onClick={orderMagazine} custom=" my-3 my-lg-0" md prim><T>Bestil magasin</T></Kbutton>
					</div>
				</form>}
				{sendSuccess && <div className="py-2 k-btn-sec text-bold text-center"><T>Magasinbestilling gennemført!</T></div>}
			</div>
		</div>
	);
}

const EventMagazineOrderInner = () => {

	const text = (
		<div style={{maxWidth: 800}}>
			<p>Optimeet Magasinet er møde- og eventbranches talerør. Magasinets fornemmeste opgave er at informere og inspirere møde- og eventplanlæggere med gode idéer, de nyeste trends om møde- og eventplanlægning samt bringe nyheder, invitationer og navnestof fra leverandører i møde- og eventbranchen.</p>
			<p>Optimeet Magasinet udkommer 4 gange årligt og er gratis for møde- og eventplanlæggere.</p>
			<p>Optimeet News udkommer en gang om ugen giver dig mulighed for at få relevante nyheder fra møde- og eventbranchen bragt direkte og gratis i din indbakke. Det er også her, at du modtager spændende tilbud fra vores samarbejdspartner og får invitationer til spændende faglige arrangementer for møde- og eventplanlæggere.</p>
			<p>Nedenfor kan du nemt bestille Optimeet Magasinet eller Optimeet News.</p>
		</div>
	);

	return (
		<Container fluid className="bg-ulight">
			<Container className="pt-5">
				<Row className="pb-5 text-center text-md-start">
					<Col className="d-flex justify-content-center">
						<div>
							<h1 className="pb-3 text-center"><T>Bestil Optimeet Magasin</T></h1>
							<div className="text-center">{text}</div>
							<div className="mt-5 mb-4 d-flex justify-content-center">
								<div className="p-4 rounded bg-white w-100">
									<Form />
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

const EventMagazineOrder = () => {
	return (
		<div>
			<Header />
				<EventMagazineOrderInner />
			<Footer />
		</div>
	);
}

export default EventMagazineOrder;
