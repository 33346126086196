import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import Header from "../c/Header";
import Footer from "../c/Footer";
import { APIGet } from "API";
import { AlertModal } from "c/Reusables";
import EventList from "c/EventList";
import { Loading } from "c/Loading";
import FixedContactButton from "c/FixedContactButton";
import { T } from "../traks";
import { Banners } from "c/Banners";

const EventsInner = ({ banners, events }) => {

    return (
        <Container fluid className="bg-ulight">
            <Container className="pt-5">
                <Row className="pb-5">
                    <Col col={12} lg={9}>
                        <Banners data={banners} billBoard/>
                        <div className="mt-4">
                            <h1 className="pb-3"><T>Arrangementer</T></h1>
                            <div className="mb-3 mb-lg-5">
                                <h4>Arrangementer for dig der professionelt planlægger/booker møder, kurser, konferencer og events</h4>
                                <p>Her finder du en oversigt over alle de arrangementer som Optimeet er arrangør eller medarrangør af.
                                        Optimeet’s arrangementer er målrettet personer, som arbejder med at planlægge og arrangere møder, kurser, konferencer, events og lignende.
                                        Vær opmærksom på at nogle af arrangementerne har begrænsede deltagerpladser.</p>
                            </div>
                            <div className="mt-4">
                                <EventList eventList={events} />
                            </div>
                        </div>
                    </Col>
                    <Col>
						<Banners data={banners} className="d-none d-lg-block" skyScraper sticky/>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

const Events = () => {
	const [events, setEvents] = useState(undefined);
	const [banners, setBanners] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	const GetEvents = () => {
		APIGet("events").then((res) => {
			setEvents(res.data.events);
			setBanners(res.data.banners);
		}).catch(err => {
            setAlertType({ type: "error", text: err.response.data });
            setShowAlert(true);
            setShowLoading(false);
		})
	}
	
    useEffect(() => {
        setShowLoading(true);
        if (!events) {
            GetEvents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	
    if (!events) {
        return (<>
            <Loading
                showLoading={showLoading}
                inFront
            />
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
        </>)
    }

    return (
        <div>
            <Header />
            <EventsInner banners={banners} events={events} />
            <Footer />
            <FixedContactButton />
        </div>
    );
}

export default Events;
