import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { VenueCard } from "./Cards";
import { RenderIf } from "./Reusables";
import Kbutton from "./KButton";
import { T } from "../traks";

const LandingVenueCards = ({ venueData = undefined, title = undefined }) => {

	const moreThanFour = venueData.length > 4;
	const [showMore, setShowMore ] = useState(false);

	return (
		<RenderIf condition={venueData && venueData.length}>
			<div className="container mt-lg-5 pt-5">
				<h2 className="text-center mb-4">{title}</h2>
				<Row>
					{venueData && venueData.slice(0, showMore ? venueData.length : 4).map((v, i) => (
						<Col key={i} xs={12} sm={12} md={6} lg={4} xl={3}>
							<div className="d-flex justify-content-center py-2">
								<>
									<VenueCard
										border
										data={v}
										key={"location" + i}
									/>
								</>
							</div>
						</Col>
					))}
				</Row>
			</div>
			{moreThanFour && <div className="text-center mt-4"><Kbutton prim md onClick={() => setShowMore(!showMore)}>{!showMore ? <T>Vis flere</T> : <T>Vis mindre</T>} </Kbutton></div>}
		</RenderIf>
	);
};

export default LandingVenueCards;