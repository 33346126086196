import React from "react";
import { Container, Row, Col } from "reactstrap";

const MagazineGrid = ({ data }) => {
	return (
		<Container>
			<Row>
				{data.map((item, index) => (
					<Col md="4 d-flex justify-content-center" key={index}>
						<div className="my-2">
							<a href={item.link} target="_blank" rel="noreferrer">
								<img className="magazine_img" src={item.imageUrl} alt={item.title} width={227} height={300} />
							</a>
							<p className="my-3">{item.date}</p>
						</div>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default MagazineGrid