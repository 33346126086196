import React from "react";
import { T } from "../traks";

const NotFound = () => {

	return (
		<div>
			<NotFoundComponent />
		</div>
	);
}

const NotFoundComponent = () => {

	return (
		<div className="text-center pt-2 pb-5">
			<h1 className="my-5"><T>Siden kan ikke findes</T></h1>
			<div><T>Go tilbage til</T> <a href={("/")} className="fw-bold"><T>hjemmesiden</T></a> <T>eller søg</T> <a href={("/konferencesteder/")} className="fw-bold"><T>konferencesteder</T></a></div>
		</div>
	);
}

export default NotFound;