import React, { useEffect, useState } from "react";
import DashboardContainer from "../DashboardContainer";
import { T } from "../../../traks";
import DashboardContainerHeader from "../DashboardContainerHeader";
import { RenderIf } from "../../Reusables";
import ChoicesSelect from "../ChoicesSelect";
import { APIPost } from "../../../API";
import { Input } from "reactstrap";

const SetVenuesComponent = ({ pageContent, setPageContent, setAlertType, setShowAlert }) => {

	const [venues, setVenues] = useState(false);

	useEffect(() => {
		const allVenues = { query: { show_hidden: false }, };
		APIPost("search", allVenues).then(res => {
			setVenues(res.data.result);
		}).catch(err => {
			setAlertType({ type: "error", text: err.response.data })
			setShowAlert(true);

		});
		// eslint-disable-next-line
	}, [])

	return (
		<RenderIf condition={venues.length && pageContent}>
			<DashboardContainer>
				<DashboardContainerHeader thumb={<i style={{ fontSize: 20 }} className="bi bi-house"/>} border><T>Konferencesteder</T></DashboardContainerHeader>
				<div className="w-100 px-4 mb-4">
					<h5 className="my-3"><T>Venue kategori</T>:&nbsp;{pageContent.venues1Title && pageContent.venues1Title ? pageContent.venues1Title : ""}</h5>
					<div className="me-3 py-1 mb-3 w-50" style={{ flex: 5 }}>
						<div className="mb-2">
							<span><T>Titel</T>:</span>
						</div>
						<Input
							className="py-3 text-weight-reg input-light-blue"
							value={pageContent.venues1Title && pageContent.venues1Title ? pageContent.venues1Title : ""}
							onChange={(e) => setPageContent(prevState => ({
								...prevState,
								venues1Title: e.target.value
							}))}
						/>
					</div>
					<div style={{ backgroundColor: 'white', borderRadius: 10 }}>
						<ChoicesSelect venue sendSelected={(newvenues) => setPageContent({ ...pageContent, venues1: newvenues })} data={venues} venueData={pageContent.venues1} subtitle={<T>Vælg venues</T>} searchPlaceholder={("Søg på venues")} showSearchBar />
					</div>
					</div>
					</DashboardContainer>
					<DashboardContainer>
						<div className="w-100 px-4 py-2 mb-4">
							<h5 className="my-3"><T>Venue kategori</T>:&nbsp;{pageContent.venues2Title && pageContent.venues2Title ? pageContent.venues2Title : ""}</h5>
							<div className="me-3 py-1 mb-3 w-50" style={{ flex: 5 }}>
								<div className="mb-2">
									<span><T>Titel</T>:</span>
								</div>
								<Input
									className="py-3 text-weight-reg input-light-blue"
									value={pageContent.venues2Title && pageContent.venues2Title ? pageContent.venues2Title : ""}
									onChange={(e) => setPageContent(prevState => ({
										...prevState,
										venues2Title: e.target.value
									}))}
								/>
							</div>
							<div style={{ backgroundColor: 'white', borderRadius: 10 }}>
								<ChoicesSelect venue sendSelected={(newvenues) => setPageContent({ ...pageContent, venues2: newvenues })} data={venues} venueData={pageContent.venues2} subtitle={<T>Vælg venues</T>} searchPlaceholder={("Søg på venues")} showSearchBar />
							</div>
						</div>
					</DashboardContainer>
					<DashboardContainer>
						<div className="w-100 px-4 py-2 mb-4">
							<h5 className="my-3"><T>Venue kategori</T>:&nbsp;{pageContent.venues3Title && pageContent.venues3Title ? pageContent.venues3Title : ""}</h5>
							<div className="me-3 py-1 mb-3 w-50" style={{ flex: 5 }}>
								<div className="mb-2">
									<span><T>Titel</T>:</span>
								</div>
								<Input
									className="py-3 text-weight-reg input-light-blue"
									value={pageContent.venues3Title && pageContent.venues3Title ? pageContent.venues3Title : ""}
									onChange={(e) => setPageContent(prevState => ({
										...prevState,
										venues3Title: e.target.value
									}))}
								/>
							</div>
							<div style={{ backgroundColor: 'white', borderRadius: 10 }}>
								<ChoicesSelect venue sendSelected={(newvenues) => setPageContent({ ...pageContent, venues3: newvenues })} data={venues} venueData={pageContent.venues3} subtitle={<T>Vælg venues</T>} searchPlaceholder={("Søg på venues")} showSearchBar />
							</div>
						</div>
					</DashboardContainer>
		</RenderIf>
	);
};

export default SetVenuesComponent;