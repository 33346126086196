
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { T } from "../../traks";
import DropZoneIcon from "../../icons/dropzone-icon-0.svg"
import BinIcon from "../../icons/bin-icon-0.svg"
import { AlertModal, RenderIf } from "../../c/Reusables";
import DashboardContainer from "./DashboardContainer";
import { VenueImg } from 'c/Reusables';
import Kbutton from "c/KButton";

const FileList = ({ files, removeFile }) => {
	return (
		<div className="d-flex flex-column">
			{files.map((file, index) => (
				<div className="my-1 d-flex flex-row justify-content-between rounded px-2 primary-border-thick" key={index + "file-item"}>
					<div className="d-flex flex-row" style={{ flex: 2 }}>
						<div className="align-items-baseline" style={{ flex: 1, alignSelf: "center" }}>
							{file.name}
						</div>
					</div>
					<div className="text-end" style={{ flex: 1 }}>
						<div className="cursor_pointer" onClick={() => removeFile(file.id)}>
							<img width={50} height={50} src={BinIcon} className="border p-2 me-5 rounded-5 bg_red" alt="bin-icon" />
						</div>
					</div>
				</div>
			))}
		</div >
	);
};

const UploadButton = ({ uploadFiles, files }) => {
	return (
		<button onClick={() => uploadFiles(files)} className="mx-2 px-5 py-2 mb-3 k-btn k-btn-prim rounded-5"><T>Upload</T></button>
	)
}

const RejectedFileList = ({ files, removeFile }) => {
	return (
		<RenderIf condition={files.length > 0} >
			<div className="d-flex flex-column mb-5">
				{files.map((file, index) => (
					<>
						<div className="mt-3 d-flex flex-row justify-content-between rounded py-3 px-2 red-border-thick" key={index + "file-item"}>
							<div className="d-flex flex-row" style={{ flex: 2 }}>
								<div className="align-items-baseline" style={{ flex: 1, alignSelf: "center" }}>
									{file.name}
								</div>
							</div>
							<div className="text-end" style={{ flex: 1 }}>
								<div className="cursor_pointer" onClick={() => removeFile(file.id)}>
									<img src={BinIcon} className="border p-2 rounded-5 bg_red" alt="bin-icon" />
								</div>
							</div>
						</div>
						<div className="txt_red"><T>This document type is not supported. Please remove it and upload another.</T></div>
					</>
				))}
			</div >
		</RenderIf>
	);
};

const Dropzone = ({ onDrop, multiple }) => {
	const acceptedFiles = {
		"image/jpeg": [".jpg", ".jpeg"],
		"image/webp": [".webp"],
		"image/png": [".png"],
	}
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple, accept: acceptedFiles, onDrop });
	const uploadText = multiple ? <T>Upload billeder</T> : <T>Upload</T>;

	return (
		<div {...getRootProps()} className="my-3 dotted-border py-5 mx-auto dropzone image-drop-container">
			<input className="input-zone" {...getInputProps()} />
			<div className="text-center">
				{isDragActive ? (<>
					<div className="dropzone-content fw_500 mt-3">
						<T>Release to drop the files here</T>
					</div>
				</>) : (<>
					<div className="mt-5">
						<Kbutton icon={<img className="me-2" width={21} height={21} alt="dropImg" src={DropZoneIcon}></img>} lightgrey custom="px-2 py-2">{uploadText}</Kbutton>
					</div>
				</>)}
			</div>
		</div>
	);
}

const Viewer = (props) => {

	const [images, setImages] = useState(props.images);

	const handleFileDelete = (item) => {
		props.deleteImage(images.filter((e) => e !== item));
	}

	const sortImages = (images) => {
		props.updateOrder(images);
	}

	const [dragId, setDragId] = useState('');

	const [deleteVisible, setDeleteVisible] = useState(false);
	const toggleDelete = (value) => {
		setDeleteVisible(value);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDrag = (e) => {
		setDragId(e.currentTarget.id);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		const dragImageIndex = parseInt(dragId);
		const dropImageIndex = parseInt(e.currentTarget.id);
		const newPreviewImages = [...images];
		const [dragImage] = newPreviewImages.splice(dragImageIndex, 1);
		newPreviewImages.splice(dropImageIndex, 0, dragImage);
		setImages(newPreviewImages);
		sortImages(newPreviewImages);
	};

	useEffect(() => {
		setImages(props.images)
	}, [props.images])


	return (
		<div>
			{props.multiple ?
				<div>
				{!!images && !!images.length && !!images[0] && 
					<div className="text-center"
						style={{
							width: '100%',
							minHeight: '280px',
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'center',
							overflow: 'hidden',
							padding: '10px',
							borderRadius: '10px',
						}}>
						{!!images && (images.map((image, index) => (
							<div
								className="cursor_drag"
								draggable
								key={index}
								onDragOver={(e) => handleDragOver(e)}
								onDragStart={(e) => handleDrag(e)}
								onDrop={(e) => handleDrop(e)}
								id={index}
							>
								<div onMouseEnter={() => toggleDelete(true)} onMouseLeave={() => toggleDelete(false)} key={index} className="position-relative m-2">
									<div className="selected-img shadow pt-1">{index === 0 ? <img className="mb-1" width={22} height={22} src="https://static.optimeet.dk/gfx/ico_star-fill-0.svg" alt='iconstars' /> : index + 1}</div>
                                    {deleteVisible && props.deleteImage && !props.noDelete && <div style={{zIndex: 99}} className="delete-img w-100 position-absolute bg-white py-0 text-sm"><span className="cursor_pointer btn" onClick={() => handleFileDelete(image)}><T>Delete image</T></span></div>}
									<VenueImg
										supplier={props.supplier}
										card
										className="gallery_img shadow"
										width={250}
										height={200}
										key={index}
										img={image}
										alt="Dropped"
									/>
								</div>
							</div>
						)))}
					</div>
				}
				</div>
				:
				<div className="text-center">
					{!!images && !!images.length && !!images[0] && (images.map((image, index) => (
						<div onMouseEnter={() => toggleDelete(true)} onMouseLeave={() => toggleDelete(false)} key={index} className="position-relative m-2 pt-3">
							<VenueImg
								supplier={props.supplier}
								thumb
								className="gallery_img shadow"
								width={props.width}
								height={props.height}
								key={index}
								img={image}
								alt="DroppedThumb"
							/>
							{deleteVisible && !props.noDelete && <div style={{zIndex: 99}} className="delete-img w-100 position-absolute bg-white text-sm cursor_pointer" onClick={(e) => handleFileDelete(image)}><T>Delete image</T></div>}
						</div>
					)))}
				</div>}
		</div>
	)

}

const DropLoad = ({ files, setFiles, setDeleted = null, updateOrder = null, uploadFiles, multiple = true, imagesUrls, canUpload, minWidth = null, previewWidth = 235, previewHeight = 215, noDelete = undefined, supplier = undefined}) => {

	const [images, setImages] = useState(!multiple ? [imagesUrls] : imagesUrls);
	const [rejectedFiles, setRejectedFiles] = useState([]);
	const [filesDropped, setFilesDroped] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	const deleteImage = (imgs) => {
		setImages(imgs);
		if(setDeleted) {
            setDeleted(imgs);
        }
	}

	const handleUploadFiles = (e) => {
		uploadFiles(e);
		setFilesDroped(false);
	}

	const removeFile = (fileId) => {
		setFiles(files.filter(file => file.id !== fileId));
	}

	const removeRejectedFile = (fileId) => {
		setRejectedFiles(rejectedFiles.filter(file => file.id !== fileId));
	}

	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		// eslint-disable-next-line 
		acceptedFiles.map((file) => {
			let isValid = true;
			const reader = new FileReader();
			reader.onload = () => {
				const image = new Image();
				// @ts-ignore
				image.src = reader.result;
				image.onload = () => {
					if (minWidth && image.width < 1200) {
						isValid = false; // set isValid to false if the file is too small
					}
					if (isValid) {
						const fileId = (new Date()).valueOf();
						const newName = fileId.toString()
						const renamedFile = new File([file], newName)
						const fileItem = {
							id: fileId,
							name: file.name,
							type: file.type,
							file: renamedFile,
							size: file.size
						}
						setFiles((prevVal) => [...prevVal, fileItem]);
						setFilesDroped(true);
						return file;
					} else {
						setAlertType({ type: "error", text: `${file.name} was rejected, as minimum width is 1200px` })
						setShowAlert(true);
					}
				}
			}
			reader.readAsDataURL(file);
		});

		rejectedFiles.map(file => {
			const fileId = (new Date()).valueOf();
			const fileItem = {
				id: fileId,
				name: file.file.name,
				type: file.file.type,
				size: file.file.size
			}
			setRejectedFiles((prevVal) => ([...prevVal, fileItem]))
			return file
		})
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setImages(!multiple ? [imagesUrls] : imagesUrls)
	}, [imagesUrls, multiple])

	return (<>
		<DashboardContainer>
			<Viewer
				supplier={supplier}
				multiple={multiple}
				images={images}
				deleteImage={deleteImage}
				updateOrder={updateOrder}
				width={previewWidth}
				height={previewHeight}
				noDelete={noDelete}
			/>
			{canUpload &&
				<Dropzone
					multiple={multiple}
					onDrop={onDrop}
				/>
			}
			<RejectedFileList
				files={rejectedFiles}
				removeFile={removeRejectedFile}
			/>
			<FileList
				files={files}
				removeFile={removeFile}
			/>
			{filesDropped && !!files.length &&
				<UploadButton
					files={files}
					uploadFiles={handleUploadFiles}
				/>
			}
		</DashboardContainer>
		<AlertModal
			isShowing={showAlert}
			setIsShowing={setShowAlert}
			type={alertType.type}
			text={alertType.text}
		/>
	</>);
}
export default DropLoad;
