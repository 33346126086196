import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MeContext } from './../MeContext';
import { APIGet, APIPost, APIDelete } from "API";
import { DashboardWrapper } from "c/admin/DashboardComponents";
import Notification from "c/admin/Notification";
import CreateButton from "c/CreateButton";
import Sidebar from "c/admin/Sidebar";
import { EditGroup } from "c/admin/DashboardComponents";
import { AlertModal } from "c/Reusables";
import NoAccess from "c/admin/NoAccess";
import { T } from "../traks";
import { Loading } from "c/Loading";
import { LL } from "Routes";

const EditRegion = ({ urlParams }) => {

	const { me } = useContext(MeContext);
	const navigate = useNavigate();
	const isAdmin = me && me.user.roles.includes("admin");

	urlParams = useParams();
	
	const regId = urlParams.regId;
	const [reg, setReg] = useState(undefined);

	const [showLoading, setShowLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false);
	const [alertType, setAlertType] = useState({ type: "", text: "" });

	useEffect(() => {
		setShowLoading(true);
		if (urlParams.regId) {
			APIGet("locations/" + regId).then(res => {
				setShowLoading(false);
				if (res.data) {
					if (!res.data)
						navigate(LL("/regions/"));
				}
				setReg(res.data.location);
			}).catch(err => {
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		} else {
			APIPost("locations", { "name": "new region", "key": "new-region" }).then(res => {
				setReg(res.data.new_location);
				window.history.replaceState(null, '', res.data.new_location.id);
				setShowLoading(false);
				window.location.reload(false);
			}).catch((err) => {
				setShowLoading(false);
				setAlertType({ type: "error", text: err.response.data });
				setShowAlert(true);
			})
		}
		// eslint-disable-next-line
	}, []);

	const saveRegion = (newRegion = null) => {
		setShowLoading(true);
		let body = !newRegion ? reg : newRegion;
		APIPost("locations/" + regId, body).then(res => {
			setShowLoading(false);
			setAlertType({ type: "success", text: "Region saved" });
			setShowAlert(true);
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	const deleteRegion = () => {
		setShowLoading(true);
		const resource = "locations/" + regId;
		APIDelete(resource).then(res => {
			setShowLoading(false);
			navigate(LL("/regions/"));
		}).catch(err => {
			setShowLoading(false);
			setAlertType({ type: "error", text: err.response.data });
			setShowAlert(true);
		})
	}

	if (!isAdmin) {
		return (
			<DashboardWrapper>
				<NoAccess />
			</DashboardWrapper>
		)
	}

	if (!reg) {
		return (
			<DashboardWrapper>
				<Loading
					showLoading={true}
					inFront
				/>
				<AlertModal
					isShowing={showAlert}
					setIsShowing={setShowAlert}
					type={alertType.type}
					text={alertType.text}
				/>
			</DashboardWrapper>
		)
	}
	
	return (
		<div className="dashboard mb-5">
			<AlertModal
				isShowing={showAlert}
				setIsShowing={setShowAlert}
				type={alertType.type}
				text={alertType.text}
			/>
			<CreateButton />
			<Sidebar index={6} />
			<div className="dashboard-wrapper">
				<h5 className="mb-3"><T>Rediger region</T></h5>
				<Notification><T>Du redigerer</T>:&nbsp;{reg.name}</Notification>
				<div className="d-flex w-100">
					<EditGroup
						values={reg}
						setValues={setReg}
						save={saveRegion}
						deleteItem={deleteRegion}
						classname="w-75"
					/>
				</div>
			</div>
			<Loading
				showLoading={showLoading}
				inFront
			/>
		</div>
	);
}

export default EditRegion;