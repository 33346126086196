import React from "react";

const StatCard = (props) => {
	const theme1 = "box-1";
	const theme2 = "box-2";
	const theme3 = "box-3";

	const map = <i className="bi bi-map" style={{ fontSize: 70 }}></i>;
	const graph = <i className="bi bi-graph-up" style={{ fontSize: 70 }}></i>;
	const user = <i className="bi bi-person" style={{ fontSize: 70 }}></i>;

	const themeIcon = () => {
		if (props.icon === 1) {
			return map;
		}
		if (props.icon === 2) {
			return graph;
		}
		if (props.icon === 3) {
			return user;
		}
	}

  	const themeClass = () => {
		if (props.theme === 1) {
			return theme1;
		}
		if (props.theme === 2) {
			return theme2;
		}
		if (props.theme === 3) {
			return theme3;
		}
	}

	const iconWrapper = "opacity-25 text-white position-absolute top-50 translate-middle-y info-box-icon justify-center";

	return (
		<div className={["info-box p-4 position-relative " + themeClass()]}>
    		<div className={iconWrapper}>{themeIcon()}</div>
    		<div className="info-box-content position-relative">
    			<div className="text-light">
        			<span className="info-box-count" style={{ lineHeight: 1.5 }}>
            	{props.count}
          		</span>
        	</div>
        		<div className="text-light">
          			<span className="info-box-title">{props.children}</span>
        		</div>
      		</div>
    	</div>
	);
};

export default StatCard;