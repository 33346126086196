import React, { useEffect, useState } from "react";
import { APIPost } from "API";
import { Input } from "reactstrap";
import { T, TraksConsumer } from '../traks';
import Map from "./Map";
import { HorizontalSlider, RenderIf } from "./Reusables";
import KCheckbox from "./KCheckbox";
import LDropDown from "./LDropdown";
import axios from "axios";

const SelectedLocations = ({ regionLocCode, regionName }) => {

	const [promotedLocationsData, setPromotedLocationsData] = useState(undefined);

	const [filters, setFilters] = useState({
        "searchWord": "",
        "location": regionLocCode ? regionLocCode : "",
        "type": "",
        "certificates": [],
        "price_range": {
            "min": '',
            "max": ''
        },
        "facilities": [],
        "page": 0,
        "limit": 100,
    });

	let params = {
        limit: 500,
        page: 0,
        "listing_keys_only": true,
    };

	function handleQuery(query) {
        if (query.searchWord) params["query_string"] = query.searchWord;
        params["locationIds"] = [regionLocCode];
        if (query.facilities[0]) params["facilityIds"] = query.facilities;
        if (query.type.length) params["categoryIds"] = [query.type];
        if (query.certificates.length) params["certificateIds"] = query.certificates;
        if (query.price_range && typeof query.price_range.min === 'number') {
            params["price_range"] = query.price_range;
        }
        doQuery(params);
    }

	useEffect(() => {
        if (regionLocCode !== undefined) {
            handleQuery(filters);
        };
		// eslint-disable-next-line
	}, [filters, regionLocCode]);

	function doQuery(query) {
        const body = { query, includeBanners: false };
        APIPost("search", body).then(res => {
            setPromotedLocationsData(res.data.result);
        }).catch(err => {

        });
    }

	return (
		<div className="container my-5 pt-3 pb-5 d-block d-xl-flex">
			<RenderIf condition={promotedLocationsData !== undefined && filters !== undefined}>
				<div style={{ background: "#fff", overflowY: "auto" }} className="col-xl-3 mb-5 mb-xl-0">
					<div className="filter-container">
						<TraksConsumer>
							<Filtering filters={filters} setFilters={setFilters} />
						</TraksConsumer>
					</div>
				</div>
				<div className="col-xl-9">
					<Map data={promotedLocationsData} customCss={"regionmap"} loc={regionName} />
				</div>
			</RenderIf>
		</div>
	);
};

const Filtering = ({ filters, setFilters }) => {
	
    const [searchText, setSearchText] = useState('');
    useEffect(() => {
        const searchDelay = setTimeout(() => {
            if (searchText && searchText === originalSearch.current) {
                setFilters({ ...filters, searchWord: searchText, page: 0 });
            } else if (searchText === '') {
                filters.searchWord !== '' && setFilters({ ...filters, searchWord: '', page: 0 });
            }
        }, 700);
        return () => clearTimeout(searchDelay);
        // eslint-disable-next-line
    }, [searchText]);

	const originalSearch = React.useRef("");
	const handleSearchTextChange = (e) => {
		originalSearch.current = e.target.value.replace(/[^a-zA-Z0-9æøåÆØÅ\s]/g, '');
		setSearchText(e.target.value);
	};

	const [filtersData, setFiltersData] = useState(undefined);
    useEffect(() => {
	const filterUrl = window.location.origin.includes("https://optimeet.dk") ? "https://static.optimeet.dk/stats/filters.json" : "https://static.optimeet.dk/stats/test-filters.json"
        if (!filtersData)
            axios.get(filterUrl).then(res => {
                setFiltersData(res.data);
            }).catch(err => null)
        const handleBeforeUnload = () => {
            //sessionStorage.removeItem('OptimeetSearchFilters');
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div>{filtersData !== undefined &&
            <div className="me-4">
                <div className="pb-4">
                    <Input
                        type="kform"
                        className="py-2 text-weight-reg search_bg_transparent w-100"
                        placeholder="Søg på mødested"
                        onChange={handleSearchTextChange}
                        name="search"
                        id="search"
                        value={searchText}
                    />
                </div>
                <div className="text-lg pt-3 font-primary"><T>Mødesteds type</T></div>
                <div className="py-2">
                    <LDropDown
                        resettable={<T>Alle typer</T>}
                        data={filtersData.categories}
                        placeholder={"Alle typer"}
                        height={50}
                        setVal={(clickData) => {
                            setFilters({ ...filters, type: clickData, page: 0 });
                        }}
                    />
                </div>
                <div className="text-lg py-3 font-primary"><T>Prisniveau</T></div>
                <div className="pb-3">
                    <HorizontalSlider
                        min={0}
                        max={2500}
                        onChange={({ min, max }) => setFilters({ ...filters, price_range: { min: min, max: max } })}
                        unit=".kr"
                    />
                </div>
                <div className="text-lg pt-4 pb-2 font-primary"><T>Faciliteter og aktiviteter</T></div>
                <div style={{ overflow: 'scroll', height: '395px', overflowX: 'hidden', }}>
                    <KCheckbox
                        showAll={true}
                        data={filtersData.facilities}
                        setVal={(clickData) => {
                            if (filters.facilities.includes(clickData)) {
                                setFilters({ ...filters, facilities: filters.facilities.filter(i => i !== clickData), page: 0 });
                            } else {
                                setFilters({ ...filters, facilities: [...filters.facilities, clickData], page: 0 });
                            }
                        }}
                    />
                </div>
{/*                 <div className="text-lg pt-4 pb-2 font-primary"><T>Miljømærkninger</T></div>
                <div className="mb-3 mb-lg-0">
                    <KCheckbox
                        data={filtersData.certificates}
                        setVal={(clickData) => {
                            if (filters.certificates.includes(clickData)) {
                                setFilters({ ...filters, certificates: filters.certificates.filter(i => i !== clickData), page: 0 });
                            } else {
                                setFilters({ ...filters, certificates: [...filters.certificates, clickData], page: 0 });
                            }
                        }}
                    />
                </div> */}
            </div>
        }</div>
    );
}

export default SelectedLocations;
